import API from '../services/api.js';
import {paginationStore} from '../reflux/paginationStore';
import _ from 'lodash';
import conf from '../conf';

export default class CollectionServiceDelegate {
  static getCollectionData (collection, pageNumber, pageSize) {
    return API.getCollection(collection, pageNumber, pageSize)
      .then(CollectionServiceDelegate.onSuccess)
      .catch(CollectionServiceDelegate.onError);
  }

  static getCancellableCollectionData (collection, pageNumber, pageSize, spacing) {
    return API.getCollectionCancellable(collection, pageNumber, pageSize, spacing);
  }

  static cancelCollectionRequests () {
    API.cancelCollectionRequests();
  }

  static onSuccess (result) {
    return new Promise(
      function (resolve) {
        resolve(CollectionServiceDelegate.mapCollection(result));
      });
  }

  static onError (err) {
    console.log('CollectionServiceDelegate - onError', err);
    return new Promise(
      function (resolve, reject) {
        let error = {errorCode: err};
        reject(error);
      });
  }

  static filterOldLiveEvents (collectionObject) {
    if (conf.disableOldEvents) {
      const liveDashEventsDate = new Date(new Date() - 7776000000);
      _.forEach(collectionObject.items, (item) => {
        const isPastLive = !!(item.content_type === 'live_program' && item.status?.code === 'post');

        if (isPastLive) {
          const isOldEvent = (liveDashEventsDate > new Date(item.status.end_time));
          if (isOldEvent) {
            item.playable = false;
            item.isOldEvent = true;
          }
        }
      });
    }
  }

  static mapCollection (collectionObject) {
    CollectionServiceDelegate.filterOldLiveEvents(collectionObject);

    paginationStore.removeAllPagesByCollectionId(collectionObject.id);
    let pageNumber = Math.ceil((collectionObject.meta.offset + 1) / collectionObject.meta.limit);
    paginationStore.storePageByCollectionId(collectionObject.id, _.cloneDeep(collectionObject), pageNumber);

    return collectionObject;
  }
}
