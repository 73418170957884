import BasePlatform from './base.js';
import storage from 'local-storage-fallback';

export default class PS3 extends BasePlatform {
  constructor () {
    super();
    console.info('Instantiated PS3...');
  }

  exit () {
    if (window && window.close) {
      console.info('window close');
      window.close();
    }
  }

  localStorage () {
    return storage;
  }
}
