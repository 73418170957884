import BasePlatform from './base.js';

export default class PS4 extends BasePlatform {
  constructor () {
    super();
    console.info('Instantiated PS4...');
  }

  exit () {
    if (window && window.close) {
      console.info('window close');
      window.close();
    }
  }

  areCaptionsEnabled () {
    /* eslint-disable */
    return WM_videoPlayer.systemClosedCaptionSetting;
  }
}
