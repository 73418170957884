import Reflux from 'reflux';
import _ from 'lodash';

export const configDataActions = Reflux.createActions({
  'setConfigData': {}
});

export const configDataStore = Reflux.createStore({
  listenables: configDataActions,
  state: {
    configData: {
      colors: {},
      links: {}
    }
  },

  setConfigData: function (configData) {
    var validConfigData = true;

    _.forEach(['nav'], function (value) {
      // Check certain values are in the configuration response
      validConfigData = validConfigData && _.has(configData, value);
    });

    if (validConfigData) {
      this.state.configData = configData;
    } else {
      this.state.configData = false;
    }
    this.trigger(this.state);
  },

  getConstant: function (constantName) {
    return this.state.configData.hasOwnProperty(constantName) ? this.state.configData[constantName] : console.error('Invalid constant name.', constantName);
  },

  getLink: function (linkName) {
    return this.state.configData.icons.hasOwnProperty(linkName) ? this.state.configData.links[linkName] : console.error('Invalid link name.', linkName);
  },

  getColors: function () {
    return this.state.configData.colors;
  }

});
