import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import truncate from 'lodash/truncate';
import PlatformUtils from '../../utils/platform.js';
import apiImageUrl from '../../utils/api-image-url';
import BaseCard from '../card/base-card';
import CardProgressBar from '../card-progress-bar/card-progress-bar';
import CardThumbnail from '../card-thumbnail/card-thumbnail';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';
import { settingsStore } from '../../reflux/settingsStore';
import { Icon } from '../page-buttons/page-buttons';
import CardLabel from '../card-label/card-label';
import conf from '../../conf';

const SUPPORT_VIDEO_PREVIEWS = PlatformUtils.supportCardVideoPreviews();

export default class VideoCard extends Reflux.PureComponent.extend(BaseCard) {
  constructor (p) {
    super(p);

    this.updateDesc = true;
    this.ref = React.createRef();
    this.descriptionLength = PlatformUtils.isPS4 || PlatformUtils.isPS5 ? 34 : 64;
  }

  componentDidUpdate (prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    this.updateDesc = (this.props.id !== prevProps.id) || (!this.props.isPlaceholder && prevProps.isPlaceholder) || (this.props.short_description !== prevProps.short_description);
  }

  getCardImage () {
    return apiImageUrl.getImageByType(this.props.type, this.props.resources, this.props.id, !this.props.playable);
  }

  getThumbnail () {
    let src = this.getCardImage();
    return <CardThumbnail src={src}/>;
  }

  getTitle () {
    const heading = <h3 className="ellipsis">{this.props.title || this.props.headline}</h3>;
    const sub =
      this.props.subheading || this.props.subline ? (
        <h4 className="ellipsis">{this.props.subheading || this.props.subline}</h4>
      ) : null;

    return (
      <React.Fragment>
        {heading}
        {sub}
      </React.Fragment>
    );
  }

  setDescription () {
    if (this.updateDesc) {
      this.description = truncate(this.props.short_description, { 'length': this.descriptionLength });
    }
  }

  getDescription () {
    if ((PlatformUtils.isPS4 || PlatformUtils.isPS5) && conf.appNamespace === 'rbtv') return null;
    this.setDescription();
    if (!this.props.focused || !this.description) {
      return null;
    }

    return <p>{this.description}</p>;
  }

  readAria () {
    this.readText = this.props.title + ' : ' + this.props.subheading + ' : ' + this.props.short_description;
    ariaTTSServiceStore.readText(`${this.readText} : item`);
  }

  render () {
    const classNames = cx('card card--video', {
      'is-placeholder': this.props.isPlaceholder,
      'is-focused': this.props.focused,
      'offleft': this.props.offleft
    });

    if (this.props.isPlaceholder) {
      return (
        <div
          ref={this.ref}
          className={classNames}
        >
          <div className="card__inner">
            <div className="card__inner__thumb">
              <div className="image-wrapper image-wrapper--loading" />
            </div>
            <div className="card__inner__details" />
          </div>
        </div>
      );
    }

    let previewSrc;
    let canShowVideoPreview = this.props.focused && this.props.playable &&
      SUPPORT_VIDEO_PREVIEWS && settingsStore.state.settings.shouldShowVideoPreview;
    if (canShowVideoPreview) {
      previewSrc = apiImageUrl.getVideoPreviewURL(this.props.id, this.props.resources);
    }

    return (
      <div
        ref={this.ref}
        className={classNames}
        role="button"
      >
        <div className="card__inner">
          <div className="card__inner__thumb">
            {this.getThumbnail()}
            {canShowVideoPreview && previewSrc ? (
              <video src={previewSrc} autoPlay loop playsInline />
            ) : null}
          </div>
          <div className="card__inner__details">
            {this.props.progress && this.props.progress > 0 ? (
              <CardProgressBar
                focused={this.props.focused}
                progress={this.props.progress}
                isCurrentVOD={this.props.isCurrentVOD}
              />
            ) : null}
            {this.getTitle()}
            {this.getDescription()}
          </div>
        </div>
        {!(canShowVideoPreview && previewSrc) ? (
          <React.Fragment>
            <div className="fav-icon">
              <Icon
                iconName={
                  this.props.isFavorite ? 'star-dark' : 'star-dark-empty'
                }
                focused={this.props.focused}
                size={48}
              />
            </div>
            <CardLabel
              {...this.props}
              readAria={this.readText}
              renderPostEventMessage
            />
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
