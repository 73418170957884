import React from 'react';
import isEqual from 'lodash/isEqual';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';
import normalizeLabel from '../../utils/normalize-label';
import BaseAria from '../../components/aria-tts/base-aria';

export default class CardLabel extends BaseAria {
  constructor (p) {
    super(p);

    this.read = false;
    this.clear = this.clear.bind(this);
    this.handleProps = this.handleProps.bind(this);
  }

  componentDidMount () {
    super.componentDidMount();
    this.handleProps();
  }

  componentDidUpdate (prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    this.read = this.read && prevProps.focused && !this.props.focused ? false : this.read;
  }

  componentWillUnmount () {
    super.componentWillUnmount();
    this.clear();
  }

  clear () {
    clearTimeout(this.timeout);
  }

  handleProps () {
    this.clear();

    if (this.props.isLinear) {
      return;
    }

    const { type, eventStatus, label, retry } = normalizeLabel(this.props);

    if (retry !== undefined) {
      this.timeout = setTimeout(() => {
        this.handleProps();
      }, retry);
    }

    const state = {
      label,
      type,
      eventStatus
    };

    if (!isEqual(state, this.state)) {
      this.setState(state);
    }
  }

  readAria () {
    const { label } = this.state;
    if (!label || this.read) return;
    this.read = true;

    ariaTTSServiceStore.readText(this.props.readAria ? `${label} : ${this.props.readAria}` : label);
  }

  render () {
    const { label, type, eventStatus } = this.state;
    if (!label) {
      return null;
    }

    if (this.props.allowed && !this.props.allowed.includes(eventStatus || type)) {
      return null;
    }

    return (
      <div className='card-panel'>
        <span className={`card-label card-label--${eventStatus || type}`}>
          {label}
        </span>
      </div>
    );
  }
}
