export default function NVP2Obj (args) {
  let query = args;

  if (query.indexOf('?') !== -1) {
    query = args.split('?')[1];
  }

  console.log('NVP2Obj args = ', query);

  let paramsAsOBj = {};
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    paramsAsOBj[pair[0]] = decodeURIComponent(pair[1]);
  }
  console.log('params=', paramsAsOBj);
  return paramsAsOBj;
}
