import NVP2Obj from '../utils/nvp2Obj.js';

var deeplinkContent = null;
var deeplinkEventKey = 'Deeplink';

var deeplinkService = {
  getDeeplinkEventKey: function () {
    return deeplinkEventKey;
  },

  getDeeplinkContent: function () {
    return deeplinkContent;
  },

  setDeeplinkContent: function (data) {
    deeplinkContent = data;
    var deeplinkEventArgs = new CustomEvent(deeplinkEventKey, { detail: deeplinkContent });
    console.log('!!!!!!!!!!!!!!!!!!!!!! deeplinkEventArgs=', deeplinkEventArgs);
    document.dispatchEvent(deeplinkEventArgs);
  },

  clear () {
    deeplinkContent = null;
  },

  checkTizenData () {
    /* global tizen: true */
    let wasDeeplinked = false;
    var reqAppControl = tizen.application.getCurrentApplication().getRequestedAppControl();
    if (reqAppControl) {
      var data = reqAppControl.appControl.data;
      console.log('data=', data);
      // Uncomment these 2 lines to test in browser:
      // note this is a Servus TV ID
      // data = [{'key': 'PAYLOAD', 'value': ['{"values":"position=0&title=Messe%20aus%20Maria%20Taferl&contentId=AA-27KWTT6HN2111&deeplink=media-playback&source=samsung-featured"}']},
      //         {"key":"__K_RELOCATE_BELOW","value":["org.volt.firstscreen"]}];

      // data can also be
      // [{"key":"AppUseType","value":["Yes"]},{"key":"callerid","value":["org.volt.apps"]},{"key":"__K_RELOCATE_BELOW","value":["org.volt.firstscreen"]}]
      // we need to handle this and ignore it.

      // app data is always present on launch, so make sure it is not something we don't act on

      var payload;

      for (var i = 0; i < data.length; i++) {
        if (data[i].key && data[i].key.toString().toLowerCase() === 'payload') {
          payload = data[i];
        }
      }

      console.log('payload=' + JSON.stringify(payload));
      console.log('payload=', payload);
      if (payload) {
        console.log('payload exists');
        wasDeeplinked = true;
        var payloadValue = JSON.parse(payload.value);
        console.log('payloadValue=', payloadValue);
        // and it's "values" property is a name value pair string
        var actionData = payloadValue.values;

        console.log('actionData1=', actionData);

        // Samsung Search Feed Case
        // we know that the NVP key assetId is ONLY in the Samsung Search Feed
        if (actionData.search('assetId') !== -1) {
          console.log('found assetId in deeplink payload - so must be search feed');
          actionData = JSON.parse(actionData).assetId;
        }

        console.log('actionData2=', actionData);

        const deepLinkData = NVP2Obj(actionData);

        console.log('deepLinkData=', deepLinkData);
        // integrates with the xbox deeplinking code.
        // window.deepLinkData = deepLinkData;

        // const videoId = actionData.assetId || actionData.video_id;

        // var deeplink = {
        //   host: actionData.action_type ? actionData.action_type : 'media-playback',
        //   query: '?ContentID=' + (actionData.product_id ? actionData.product_id : videoId)
        // };
        deeplinkService.setDeeplinkContent(deepLinkData);
      } else {
        console.log('NOT DEEPLINK LAUNCH = No Payload');
      }
    }
    return wasDeeplinked;
  }
};

export default deeplinkService;
