import Reflux from 'reflux';

export const liveChannelsActions = Reflux.createActions({
  'reset': {},
  'updatePositionIndex': {}
});

export const liveChannelsStore = Reflux.createStore({
  listenables: liveChannelsActions,
  state: {
    focusPosition: null
  },

  getInitialState: function () {
    return this.state;
  },

  reset: function () {
    this.state = {
      focusPosition: null
    };
    this.trigger(this.state);
  },

  updatePositionIndex: function (index) {
    this.state.focusPosition = index;
    this.trigger(this.state);
  }
});
