import Config from '../config/analyticsConfig.js';
import conf from '../conf';
import PlatformUtils from '../utils/platform.js';
import _ from 'lodash';
import uuid from 'uuid/v4';
import { appStore } from './appStore';
import {
  videoAnalyticsStore as videoViewTrackingStore
} from './videoViewTrackingStore';
import { consumptionStore } from './consumptionStore';
import { userAccountStore } from './userAccountStore';
import { videoPlayerStore } from './videoPlayerStore';
import { playlistStore } from './playlistStore';
import { settingsStore } from './settingsStore';
import { homeStore } from './homeStore';
import { captionStore } from './captionStore';
import { assetMetadataStore } from './assetMetadataStore';
import Reflux from 'reflux';
import {configDataStore, configDataActions} from './configDataStore';
import ErrorReporter from '../utils/error-reporter';
import API from '../services/api.js';
/* global ga: true */

var googleAnalyticsScreenTrackingIDs;
var googleAnalyticsVideoTrackingIDs;

const isServus = conf.appNamespace === 'servustv';

export const googleAnalyticsActions = Reflux.createActions({

});

export const googleAnalyticsStore = Reflux.createStore({
  listenables: googleAnalyticsActions,

  init: function () {
    this.listenTo(configDataActions.setConfigData, this.initGA);

    // These are handled in the appkit store now to avoid duplicate events
    // this.listenTo(exitStore, this.handleExit);
    // this.listenTo(videoPlayerActions.videoEnd, this.handleEndOfVideo);
    // this.listenTo(captionActions.switchWebvttCaptions, this.handleCaptionSwitch);
  },

  state: {
    hitCounters: {},
    currentMaxHitCount: 0
  },

  getRsid: function () {
    if (conf.testBuild) {
      return 'redbullmediatvdev';
    } else {
      return Config.rsid;
    }
  },

  getSessionFields: function () {
    return {
      'dimension5': 'app', // Site Type
      'dimension10': 'unspecified', // Referral
      'dimension11': navigator.userAgent, // User Agent
      'dimension12': appStore.state.uid, // Client ID
      'dimension13': userAccountStore.state.isUserLoggedIn ? 'true' : 'false', // Logged In Status
      'dimension53': 'unspecified', // Internal Referrer
      'dimension55': userAccountStore.state.isUserLoggedIn ? userAccountStore.getUserId() : 'unspecified', // User ID
      'dimension56': 'unspecified', // Mobile ID
      'dimension126': 'unspecified' // Campaign ID
    };
  },

  handleExit: function () {
    googleAnalyticsStore.videoView('stopVideo');
  },

  padToFive: function (number) {
    number = Math.round(number);
    if (number <= 9999) { number = ('0000' + number).slice(-5); }
    return number;
  },

  incrementHitCounter: function (counterId) {
    this.state.hitCounters[counterId] = this.state.hitCounters[counterId] + 1;
    const hitCounterCounts = Object.keys(this.state.hitCounters).map((id) => {
      return this.state.hitCounters[id];
    });
    this.state.currentMaxHitCount = _.max(hitCounterCounts) || 0;

    let interval = _.find(this.state.beaconSettings, (beaconInterval) => {
      return this.state.currentMaxHitCount < beaconInterval.max_hit_count;
    });
    if (!interval) {
      let finalInterval = this.state.beaconSettings[this.state.beaconSettings.length - 1];
      // if this.state.currentMaxHitCount has surpassed the max_hit_count of the highest interval, use the highest interval
      interval = finalInterval;
    }

    if (interval.min_hit_count !== this.state.currentBeaconInterval.min_hit_count) {
      // If we've passed the previous threshold, update the stored interval info
      this.state.currentBeaconInterval = interval;
    }
  },

  getCurrentBeaconInterval: function (videoType) {
    return this.state.currentBeaconInterval.beacon_frequency[videoType];
  },

  getDurationSinceLastInterval: function () {
    // For CM2 and CM52
    return parseInt(videoViewTrackingStore.state.oneSecondTicks);
  },

  handleEndOfVideo: function () {
    googleAnalyticsStore.videoView('endVideo');
  },

  handleCaptionSwitch: function () {
    googleAnalyticsStore.videoView('subtitleSwitch');
  },

  initGA: function () {
    this.state.analyticsEventsDisabled = configDataStore.getConstant('analytics_events_disabled') || [];
    this.state.isGoogleAnalyticsEnabled = configDataStore.getConstant('google_analytics_enabled');

    if (!this.state.isGoogleAnalyticsEnabled) {
      console.info('Google Analytics Disabled');
      return;
    }
    console.info('Google Analytics Enabled');
    this.state.beaconSettings = configDataStore.getConstant('google_analytics_video_beacon_settings') || [];
    if (!this.state.beaconSettings || !this.state.beaconSettings.length) {
      ErrorReporter.captureMessage('GA', 'No beaconing intervals found');
    }
    this.state.currentBeaconInterval = this.state.beaconSettings[0];
    googleAnalyticsScreenTrackingIDs = configDataStore.getConstant('google_analytics_screen_tracking_ids');
    googleAnalyticsVideoTrackingIDs = configDataStore.getConstant('google_analytics_video_tracking_ids');
    if (!_.isArray(googleAnalyticsScreenTrackingIDs)) {
      googleAnalyticsScreenTrackingIDs = [googleAnalyticsScreenTrackingIDs];
    }
    if (!_.isArray(googleAnalyticsVideoTrackingIDs)) {
      googleAnalyticsVideoTrackingIDs = [googleAnalyticsVideoTrackingIDs];
    }

    if (!this.state.analyticsEventsDisabled.includes('screenTracker')) {
      _.forEach(googleAnalyticsScreenTrackingIDs, (id, idx) => {
        ga('create', id, 'auto', 'screenTracker' + idx, {
          clientId: appStore.state.uid
        });
        this.state.hitCounters[id] = 0;
        if (PlatformUtils.isTizen) {
          ga('screenTracker' + idx + '.set', 'checkProtocolTask', function () { /* nothing */ });
        }
      });
    }

    if (!this.state.analyticsEventsDisabled.includes('videoTracker')) {
      _.forEach(googleAnalyticsVideoTrackingIDs, (id, idx) => {
        ga('create', id, 'auto', 'videoTracker' + idx, {
          clientId: appStore.state.uid
        });
        this.state.hitCounters[id] = 0;
        if (PlatformUtils.isTizen) {
          ga('videoTracker' + idx + '.set', 'checkProtocolTask', function () { /* nothing */ });
        }
      });
    }

    //
    // Remove these listeners and handle them in the AppKit store
    //
    // this.listenTo(consumptionActions.updateContentObjectForEPG, (data) => {
    //   // Updating of the Contextual tab is considered a new screenView
    //   console.info('GA: new EPG contextual object', data);
    //   this.screenView('contextual', {id: data.id});
    // });

    // this.listenTo(consumptionActions.setCurrentAssetCompleted, (data) => {
    //   // Updating of the Contextual tab is considered a new screenView
    //   console.info('GA: new contextual object', data);
    //   this.screenView('contextual', {id: data.id, playlistPositionId: playlistStore.state.currentlyWatchingIndex});
    //
    //   this.state.previousVideoViewId = this.state.videoViewId;
    //   this.state.videoViewId = uuid();
    //   this.state.playType = videoViewTrackingStore.playType;
    //   let cps = consumptionStore.state.currentAssetObject || {};
    //   let title = playlistStore.isLinearPlaylist() ? 'linear' : cps.title;
    //   let id = playlistStore.isLinearPlaylist() ? 'linear' : cps.id;
    //   let params = {
    //     playerLoad: true,
    //     id: id,
    //     videoTitle: title,
    //     absolutePosition: 0,
    //     channel: cps && cps.status && cps.status.code === 'live' ? 'live' : playlistStore.isLinearPlaylist() ? 'linear' : 'vod',
    //     pageName: PlatformUtils.getDeviceTrackingPlatform() + '|' + id + '|' + title
    //   };
    //   this.videoView(params);
    // });
  },

  videoView: function (paramsOrStr, extraParams) {
    try {
      if (!this.state.isGoogleAnalyticsEnabled) {
        return;
      }
      if (this.state.analyticsEventsDisabled.includes('videoTracker')) return;

      let params = {};

      // TODO: Should probably just update the id for BORB when we receive it from the API
      let linearChannelId = playlistStore.getCurrentPlayingChannel() && playlistStore.getCurrentPlayingChannel().id;
      if (linearChannelId === 'linear-borb') {
        linearChannelId = 'borb';
      }

      if (_.isString(paramsOrStr)) {
        // If only a string is passed, set the string value as true in parameters object
        // and merge with params object from videoViewTrackingStore
        params[paramsOrStr] = true;
        params = _.extend(params, videoViewTrackingStore.state.paramsGA);
      } else if (_.isObject(paramsOrStr)) {
        params = paramsOrStr;
      } else {
        console.warn('Parameter is neither string nor object, returning');
        return;
      }
      if (extraParams && _.isObject(extraParams)) {
        _.extend(params, extraParams);
      }

      const captionsAreAvailableAndSelected = captionStore.state.hasCaptions && settingsStore.state.settings.shouldShowCC === true && !params.captionsTurnedOffFromMenu && captionStore.state.activeCaptionsLanguage && !captionStore.state.activeCaptionsIsDisabled;
      const resolved_locale = (
        assetMetadataStore.state.metadata &&
        assetMetadataStore.state.metadata.resolved_locale
      ) || (
        assetMetadataStore.state.metadataForAnalytics &&
        assetMetadataStore.state.metadataForAnalytics.resolved_locale
      );

      if (assetMetadataStore.state.metadataForAnalytics) assetMetadataStore.state.metadataForAnalytics = {};

      let videoViewHitFields = {
        'eventCategory': 'video', // Event Category (may be redefined later in this function)
        'appName': isServus ? 'ServusTV' : 'Red Bull TV', // Application name
        'appId': isServus ? 'com.redbull.ServusTV' : 'com.redbull.RBTV', // Application ID
        'appVersion': conf.version, // Application version
        'anonymizeIp': 1, // Anonymize IP
        'cid': appStore.state.uid, // Random sessionId
        'dataSource': 'app', // Data source
        'dimension1': isServus ? 'servus' : 'redbull.com', // Brand
        'dimension2': PlatformUtils.getModifiedDeviceLanguage(), // Language
        'dimension3': isServus ? 'at' : 'int', // Locale
        'dimension4': isServus ? 'servustv-app' : 'redbulltv-apps', // Property Name
        'dimension6': 'on-site', // Environment
        'dimension7': 'unspecified', // Page Template
        'dimension8': params.id || 'unspecified', // Asset ID
        'dimension9': 'unspecified', // Master ID RB3
        'dimension19': consumptionStore.state.currentAssetObject ? consumptionStore.state.currentAssetObject.title : 'unspecified', // On Page Title
        'dimension32': isServus ? 'Servus TV App Relaunch' : 'RBTV', // BC Account Name
        'dimension34': 'unspecified', // MB Asset ID(VIN)
        'dimension35': params.videoTitle || 'unspecified', // Video Name
        'dimension36': params.videoTitle || 'unspecified', // CMS Video Name
        'dimension37': isNaN(params.absolutePosition) ? '00000' : this.padToFive(params.absolutePosition), // Absolute Position in 10 or 60 sec increments
        'dimension38': 'unspecified', // Display URL
        'dimension39': 'unspecified', // Display Domain
        'dimension40': this.state.playType ? this.state.playType === 'auto' ? 'true' : 'false' : 'unspecified', // Video Autoplay
        'dimension41': params.id || 'unspecified', // Video ID
        'dimension42': PlatformUtils.getDeviceTrackingPlatform() || 'unspecified', // Player ID
        'dimension43': 'unspecified', // Reference ID
        'dimension44': 'unspecified', // Video Chapter
        'dimension45': 'unspecified', // Video Label
        'dimension46': 'unspecified', // Video Label Group
        'dimension47': 'unspecified', // Video Season
        'dimension48': resolved_locale || 'unspecified',
        'dimension49': 'unspecified', //  Video URL
        'dimension50': (params.channel === 'live' || params.channel === 'linear') ? 'true' : 'false', // Video Stream  <true if live or linear, false for VOD>
        'dimension54': 'unspecified',
        'dimension58': params.id, // Video Product ID  <video ID (AP #)>
        'dimension59': 'unspecified', // Video Asset ID
        'dimension60': 'unspecified',
        'dimension61': PlatformUtils.convertISO2CodeToISO1(videoPlayerStore.state.currentAudioLanguageCode) || 'unspecified', // Audio stream language
        'dimension62': captionsAreAvailableAndSelected ? PlatformUtils.convertISO2CodeToISO1(captionStore.state.activeCaptionsLanguage) : 'unspecified' || 'unspecified', // Video subtitles language (unspecified if subtitles are off)
        'dimension72': params.channel === 'linear' ? linearChannelId : 'unspecified', // Channel TODO: Get the name of playing Channel if user navigated through a Channel page
        'dimension83': isServus ? 'servustv' : 'RBTV', // Source
        'dimension84': 'unspecified', // Geo Location
        'dimension85': 'unspecified', // SEO Title <page title>
        'dimension112': 'unspecified', // Event Phase
        'dimension120': params.pageName, // Page Name <Page Name> in format "<device_platform>|<page id>|<page title/label>"
        'dimension121': params.listId || 'unspecified', // Collection ID
        'dimension122': 'unspecified', // Krux ID
        'dimension123': 'unspecified', // Google Home Cast
        'dimension124': PlatformUtils.getDeviceTrackingPlatform() || 'unspecified', // Device Platform
        'dimension125': PlatformUtils.isVIDAA || PlatformUtils.isHBBTV ? `${PlatformUtils.sharedPlatform.getBrand()} ${PlatformUtils.sharedPlatform.getOS()}` : 'unspecified', // Platform Subtype
        'dimension127': consumptionStore.state.currentAssetObject ? consumptionStore.state.currentAssetObject.id : 'unspecified', // Page ID <Page ID>
        'dimension128': params.channel || 'unspecified', // Video Stream Type
        'dimension129': this.state.videoViewId || 'unspecified', // Video Play ID <RBTV Video View ID> or (UUID4)
        'dimension130': this.state.currentPageViewId, // Page View ID  <RBTV Page View ID> (UUID4)
        'dimension131': playlistStore.state.currentlyWatchingIndex || 'unspecified', // Playlist Position <RBTV Playlist Position>
        'dimension132': homeStore.state.lastViewedPage || 'unspecified', // Video Collection Referral
        'dimension133': this.state.deeplinkSource || 'unspecified', // Deeplink
        'dimension134': conf.version, // Build Number
        'dimension136': 'fullscreen', // Mode of the video player
        'dimension137': this.getCurrentBeaconInterval(params.channel) || 'unspecified', // Current beaconing interval
        'dimension138': this.state.currentMaxHitCount + 1// Client internal hit counter
      };
      if (params.languageSwitch) {
        console.info('GA: languageSwitch!');
        videoViewHitFields['eventAction'] = 'languageSwitch';
        videoViewHitFields['eventLabel'] = PlatformUtils.convertISO2CodeToISO1(videoPlayerStore.state.currentAudioLanguageCode) || 'unspecified';
      } else if (params.languagePlay) {
        console.info('GA: languagePlay!');
        videoViewHitFields['eventAction'] = 'languagePlay';
        videoViewHitFields['eventLabel'] = PlatformUtils.convertISO2CodeToISO1(videoPlayerStore.state.currentAudioLanguageCode) || 'unspecified';
      } else if (params.subtitleSwitch) {
        console.info('GA: subtitleSwitch!');
        videoViewHitFields['eventAction'] = 'subtitleSwitch';
        videoViewHitFields['eventLabel'] = settingsStore.state.settings.shouldShowCC === true && !params.captionsTurnedOffFromMenu ? PlatformUtils.convertISO2CodeToISO1(captionStore.state.activeCaptionsLanguage) : 'unspecified';
      } else if (params.posBeacon) {
        console.info('GA: posBeacon!');
        videoViewHitFields['metric2'] = this.getDurationSinceLastInterval(); // Duration since last beacon
        videoViewHitFields['metric52'] = this.getDurationSinceLastInterval(); // Duplicate of CM2 with intent of retiring CM2 in the future
        videoViewHitFields['eventAction'] = 'pos';
      } else if (params.playInitial) {
        console.info('GA: playInitial!');
        videoViewHitFields['dimension37'] = '00000';
        videoViewHitFields['metric3'] = 1; // Video Plays, sent when first frame of video started playing
        if (params.channel === 'vod') {
          videoViewHitFields['metric4'] = params.mediaLength; // Video length in seconds when known, sent at player initialization (autoplay) or when user requested to play the video
        } else {
          videoViewHitFields['metric4'] = 0;
        }
        videoViewHitFields['eventAction'] = 'playInitial';
        videoViewHitFields['eventLabel'] = 'start';
      } else if (params.playerLoad) {
        console.info('GA: playerLoad!');
        videoViewHitFields['metric7'] = 1; // playerLoad, sent at player initialization (autoplay) or when user requested to play the video
        videoViewHitFields['eventAction'] = 'playerLoad';
      } else if (params.posRelative) {
        console.info('GA: posRelative!');
        videoViewHitFields['eventAction'] = 'posRelative';
        videoViewHitFields['eventLabel'] = params.posRelativeValue + '%';
      } else if (params.playVideo) {
        console.info('GA: playVideo!');
        videoViewHitFields['eventAction'] = 'play';
      } else if (params.pauseVideo) {
        console.info('GA: pauseVideo!');
        videoViewHitFields['eventAction'] = 'pause';
        videoViewHitFields['metric52'] = this.getDurationSinceLastInterval(); // CM52 partial duration beacon in seconds since
        // videoViewTrackingStore.resetTicksAfterPartialDuration();
      } else if (params.seekVideo) {
        console.info('GA: seekVideo!');
        videoViewHitFields['eventAction'] = 'seek';
      } else if (params.stopVideo) {
        if (!videoPlayerStore.state.isPlaying && playlistStore.isLinearPlaylist()) {
          return;
        }
        console.info('GA: stopVideo!');
        videoViewHitFields['eventAction'] = 'stop';
        videoViewHitFields['metric52'] = this.getDurationSinceLastInterval(); // CM52 partial duration beacon in seconds since
        // videoViewTrackingStore.resetTicksAfterPartialDuration();
      } else if (params.endVideo) {
        console.info('GA: endVideo!');
        videoViewHitFields['eventAction'] = 'eof';
        videoViewHitFields['metric52'] = this.getDurationSinceLastInterval(); // CM52 partial duration beacon in seconds since
        videoViewHitFields['dimension129'] = this.state.previousVideoViewId || 'unspecified'; // Video Play ID <RBTV Video View ID> or (UUID4)
        // videoViewTrackingStore.resetTicksAfterPartialDuration();
      } else if (params.addFavorite) {
        console.info('GA: addFavorite!');
        videoViewHitFields['eventCategory'] = 'favorites';
        videoViewHitFields['eventAction'] = 'onAddToFavorites';
        videoViewHitFields['eventLabel'] = extraParams.favoriteItem.id + '|' + extraParams.favoriteItem.title + '|' + extraParams.toggleType;
      } else if (params.removeFavorite) {
        console.info('GA: removeFavorite!');
        videoViewHitFields['eventCategory'] = 'favorites';
        videoViewHitFields['eventAction'] = 'onRemoveFromFavorites';
        videoViewHitFields['eventLabel'] = extraParams.favoriteItem.id + '|' + extraParams.favoriteItem.title + '|' + extraParams.toggleType;
      } else if (params.BackToLive || params.BackToStart || params['15secBackward'] || params['15secForward']) {
        console.info('GA: DVR Seek');
        videoViewHitFields['eventLabel'] = params.BackToLive ? 'BackToLive' : params.BackToStart ? 'BackToStart' : params['15secBackward'] ? '15secBackward' : '15secForward';
        videoViewHitFields['eventAction'] = 'seek';
        videoViewHitFields['metric52'] = this.getDurationSinceLastInterval(); // CM52 partial duration beacon in seconds since
      }

      if (videoViewHitFields['eventAction'] && this.state.analyticsEventsDisabled.includes(videoViewHitFields['eventAction'])) return;

      videoViewHitFields = _.extend(videoViewHitFields, this.getSessionFields());
      console.info('Analytics: Sending videoViews', videoViewHitFields);
      videoViewHitFields['hitType'] = 'event';

      // Add/removeFavorite events must be sent only to the screen tracking IDs
      const trackingIdsToUse = (params.addFavorite || params.removeFavorite) ? googleAnalyticsScreenTrackingIDs : googleAnalyticsVideoTrackingIDs;
      _.forEach(trackingIdsToUse, (id, idx) => {
        ga('videoTracker' + idx + '.send', videoViewHitFields);
        this.incrementHitCounter(id);
      });
    } catch (e) {
      console.error('GA videoView execution failed', e);
    }
  },

  screenView: function (pageid, params) {
    try {
      if (!this.state.isGoogleAnalyticsEnabled) {
        return;
      }

      if (this.state.analyticsEventsDisabled.includes('screenTracker')) return;

      if (!params) {
        params = {};
      }
      console.info('screenView', pageid, params);

      this.state.currentPageViewId = uuid();
      params.title = params.title ? params.title : consumptionStore.state.currentAssetObject ? consumptionStore.state.currentAssetObject.title : pageid;
      pageid = pageid || params.pageid;
      this.state.currentPageId = pageid;
      let screenViewHitFields = {
        'appName': isServus ? 'ServusTV' : 'Red Bull TV', // Application name
        'appId': isServus ? 'com.redbull.ServusTV' : 'com.redbull.RBTV', // Application ID
        'appVersion': conf.version, // Application version
        'anonymizeIp': 1, // Anonymize IP
        'cid': appStore.state.uid, // Random sessionId
        'dataSource': 'app', // Data source
        'dimension1': isServus ? 'servus' : 'redbull.com', // Brand
        'dimension2': PlatformUtils.getModifiedDeviceLanguage(), // Language
        'dimension3': isServus ? 'at' : 'int', // Locale
        'dimension4': isServus ? 'servustv-app' : 'redbulltv-apps', // Property Name
        'dimension6': 'on-site', // Environment
        'dimension7': 'unspecified', // Page Template
        'dimension8': pageid, // Asset ID
        'dimension9': 'unspecified', // Master ID RB3
        'dimension14': params.resultsLength || 'unspecified', // Number Results
        'dimension19': params.title, // On Page Title,
        'dimension21': 'unspecified', // Grouping Label Group
        'dimension22': 'unspecified', // Grouping Label
        'dimension23': 'unspecified', // Grouping season
        'dimension24': 'unspecified', // Grouping chapter
        'dimension54': 'unspecified', // Content Type
        'dimension60': 'unspecified', // Tracking version
        'dimension83': isServus ? 'servustv' : 'RBTV', // Source
        'dimension84': 'unspecified', // Geo Location
        'dimension85': params.title || 'unspecified', // SEO Title,
        'dimension120': PlatformUtils.getDeviceTrackingPlatform() + '|' + pageid + '|' + params.title, // Page Name "<device_platform>|<page id>|<page title/label>"
        'dimension121': params.collectionId || 'unspecified', // Collection ID
        'dimension122': 'unspecified', // Krux ID
        'dimension123': 'unspecified', // Google Home Cast
        'dimension124': PlatformUtils.getDeviceTrackingPlatform(), // Device Platform
        'dimension125': PlatformUtils.isVIDAA || PlatformUtils.isHBBTV ? `${PlatformUtils.sharedPlatform.getBrand()} ${PlatformUtils.sharedPlatform.getOS()}` : 'unspecified', // Platform Subtype
        'dimension127': pageid, // Page ID
        'dimension128': videoViewTrackingStore.state.videoStreamType || 'unspecified', // Video Stream Type
        'dimension130': this.state.currentPageViewId || 'unspecified', // Page View ID
        'dimension131': params.playlistPositionId || 'unspecified', // Playlist Position
        'dimension132': homeStore.state.lastViewedPage || 'unspecified', // Video Collection Referral
        'dimension133': this.state.deeplinkSource || 'unspecified', // Deeplink
        'dimension134': conf.version, // Build Number
        'dimension136': 'fullscreen', // Mode of the video player
        'dimension137': this.getCurrentBeaconInterval(videoViewTrackingStore.state.videoStreamType) || 'unspecified', // Current beaconing interval
        'dimension138': this.state.currentMaxHitCount + 1 // Client internal hit counter
      };

      screenViewHitFields = _.extend(screenViewHitFields, this.getSessionFields());

      if (params.searchTerm) {
        screenViewHitFields['dl'] = 'search/' + params.searchTerm;
      } else if (params.signInPromptView) {
        screenViewHitFields['cd'] = 'Optional Registration Prompt';
      } else if (params.signInPromptLater) {
        screenViewHitFields['eventCategory'] = 'Interaction';
        screenViewHitFields['eventAction'] = 'Optional Registration Later';
        screenViewHitFields['eventLabel'] = 'When a user selects the Maybe Later button';
      } else if (params.signInPromptSkip) {
        screenViewHitFields['eventCategory'] = 'Interaction';
        screenViewHitFields['eventAction'] = 'Optional Registration Skip';
        screenViewHitFields['eventLabel'] = 'When a user selects the Skip button';
      }

      if (screenViewHitFields['eventAction'] && this.state.analyticsEventsDisabled.includes(screenViewHitFields['eventAction'])) return;

      console.info('Analytics: Sending screenViews', screenViewHitFields);
      _.forEach(googleAnalyticsScreenTrackingIDs, (id, idx) => {
        ga('screenTracker' + idx + '.send', 'screenview', _.extend(screenViewHitFields, {'screenName': pageid}));
        this.incrementHitCounter(id);
      });
    } catch (e) {
      console.error('GA screenView execution failed', e);
    }
  },

  addImpressionBatch: function (impressionItemsToSend, action) {
    if (!this.state.isGoogleAnalyticsEnabled) {
      return;
    }

    if (this.state.analyticsEventsDisabled.includes('module_impression')) return;

    if (!impressionItemsToSend || !impressionItemsToSend.length) {
      return;
    }
    _.forEach(googleAnalyticsScreenTrackingIDs, (id, trackerIdx) => {
      _.forEach(_.chunk(impressionItemsToSend, 12), (items) => {
        const impressionViewHitFields = {
          v: 1, // Version.
          t: 'event', // ? Pageview hit type. screenview | event
          tid: id, // ? Tracking ID / Property ID. 'UA-XXXXXX-X', // Property ID
          cid: appStore.state.uid, // Random sessionId
          uid: appStore.state.uid, // User ID
          aip: '1', // Anonymize IP
          an: isServus ? 'ServusTV' : 'Red Bull TV', // Application name
          av: '1.0.0', // App Version
          aid: isServus ? 'com.redbull.ServusTV' : 'com.redbull.RBTV', // Application ID
          ds: 'app', // Data Source
          cd1: isServus ? 'servus' : 'redbull.com', // Brand
          cd2: PlatformUtils.getDeviceLanguage(), // Language
          cd3: isServus ? 'at' : 'int', // Locale
          cd4: isServus ? 'servustv-app' : 'redbulltv-apps', // Property Name
          cd5: 'app', // Site Type
          cd11: navigator.userAgent, // User Agent
          cd12: appStore.state.uid, // Client ID
          cd13: userAccountStore.state.isUserLoggedIn ? 'true' : 'false', // Logged In Status
          cd55: userAccountStore.state.isUserLoggedIn ? userAccountStore.getUserId() : 'unspecified', // User ID
          cd83: 'RBTV', // Source
          cd120: document.location.pathname, // Page Name
          cd124: PlatformUtils.getDeviceTrackingPlatform(), // Device-Platform
          cd125: PlatformUtils.isVIDAA || PlatformUtils.isHBBTV ? `${PlatformUtils.sharedPlatform.getBrand()} ${PlatformUtils.sharedPlatform.getOS()}` : 'unspecified', // Platform Subtype
          cd126: '', // Campaign ID
          cd127: consumptionStore.state.currentAssetObject ? consumptionStore.state.currentAssetObject.id : 'unspecified', // Page ID
          cd130: this.state.currentPageViewId, // Page View ID
          cd134: conf.version, // Build Number
          cd138: this.state.currentMaxHitCount + 1, // Client internal hit counter
          el: 'impression',
          promoa: action,
          ea: action === 'view' ? 'module_impression' : 'module_click',
          ec: 'module_tracking'
        };
        _.forEach(items, (impressionItem, batchIdx) => {
          const idxToUse = batchIdx + 1;
          impressionViewHitFields['promo' + idxToUse + 'id'] = impressionItem.id; // Product impression ID
          impressionViewHitFields['promo' + idxToUse + 'nm'] = impressionItem.title; // Product impression name
          impressionViewHitFields['promo' + idxToUse + 'cr'] = impressionItem.type; // Product impression creative
          impressionViewHitFields['promo' + idxToUse + 'ps'] = impressionItem.row + '.' + (impressionItem.index + 1); // Product impression position
        });
        console.info('Analytics: Sending impressions', impressionViewHitFields);
        this.incrementHitCounter(id);
        API.sendGAMeasurementProtocolHit(impressionViewHitFields);
      });
    });
  },

  optionalRegistrationPrompt (report) {
    this.screenView('Optional Registration Prompt', {
      title: 'Optional Registration Prompt',
      [report]: true
    });
  }
});
