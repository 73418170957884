import API from '../services/api.js';

var promise;
export default class configServiceDelegate {
  static getConfigData () {
    if (promise) return promise;
    else {
      promise = API.getConfigData()
        .catch(configServiceDelegate.onError);
    }

    promise.then(() => { promise = null; });

    return promise;
  }

  static onError (err) {
    return new Promise(
      function (resolve, reject) {
        let error = {errorCode: err};
        reject(error);
      });
  }
}
