import React from 'react';
import {videoPlayerStore} from '../../reflux/videoPlayerStore';
import Reflux from 'reflux';
import PlatformUtils from '../../utils/platform.js';
import conf from '../../conf';
import _ from 'lodash';

export default class LowFPSIntro extends Reflux.Component {
  constructor (props) {
    super(props);
    this.stores = [videoPlayerStore];
    this.state = {
      ready: false
    };
    // this.videoUrl = 'https://s3-us-west-2.amazonaws.com/rbtv-qaengineering/Rendition_Testing/ABR/150908/QA-VOD-ABR-SMART_TV.m3u8';
    // this.videoUrl = 'http://rbtvstage.pmd.redbull.com/media/hds/2635130824001/201502/2420/2635130824001_4080205980001_WASHING-MACHINE-03--1-.mp4';
    let splash = document.getElementById('splash-screen');
    if (splash) {
      setTimeout(() => {
        splash.parentNode && splash.parentNode.removeChild(splash);
        splash = null;
      }, 500);
    }

    if (this.props.fpsVersion === 'low') {
      let counter = 0, inc = 1;

      let elements = Array.prototype.slice.call(document.querySelectorAll('#loader__loadingdivs > div'));

      this.__timeout = setInterval(() => {
        if (!elements.length) elements = Array.prototype.slice.call(document.querySelectorAll('#loader__loadingdivs > div'));

        _.each(elements, (elm, idx) => {
          let cn = (idx === counter) ? 'active' : '';
          elm.className = cn;
        });

        counter += inc;

        if (counter === elements.length - 1) inc = -1;
        else if (counter === 0) inc = 1;
      }, 200);
    }

    var localStorage = PlatformUtils.sharedPlatform.localStorage();
    this.state.loadingSrc = `${window.resourcePath}/images/${conf.appNamespace}_splash_logo.png`;
    this.testAPIUrl = conf.testBuild ? localStorage.getItem('rbtv:testApiUrl') || conf.apiUrl : false;
    this.platform = PlatformUtils.getPlatformID();
  }

  componentWillUnmount () {
    super.componentWillUnmount();
    if (this.props.fpsVersion === 'low') {
      clearInterval(this.__timeout);
    }
  }

  getBuildType () {
    if (!conf.testBuild) {
      return null;
    }

    let inlineStyle = {
      color: conf.appNamespace === 'servustv' ? 'black' : 'white',
      textAlign: 'center',
      bottom: '0'
    };
    return (
      <h1 style={inlineStyle}>
        PLATFORM: {this.platform}, ENVIRONMENT: {this.testAPIUrl} ({conf.version})
        <br/>
        {window.location.href}
        <br/>
        {JSON.stringify(conf.buildParams)}
      </h1>
    );
  }

  render () {
    if (this.props.visible) {
      if (this.props.fpsVersion === 'high') {
        return (
          <div id="loader">
            {this.getBuildType()}
            <img src={this.state.loadingSrc} className='pulsing' />
          </div>
        );
      } else {
        return (
          <div id="loader">
            {this.getBuildType()}
            <img src={this.state.loadingSrc} />
            <div id="loader__loadingdivs">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }
}
