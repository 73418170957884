import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import _ from 'lodash';
import { getThumbnail, getPreview } from '../../../utils/api-image-url';
import PlatformUtils from '../../../utils/platform';
import { settingsStore } from '../../../reflux/settingsStore';

const SHOULD_SHOW_PREVIEW_HEADER = PlatformUtils.supportVideoPreviews();

export default class Background extends Reflux.Component {
  constructor (p) {
    super(p);
    this.state = {
      preview: {},
      poster: null,
      posterLoaded: false,
      previewLoaded: false,
      transparentMode: false
    };

    this.ref = React.createRef();

    this.previewError = this.previewError.bind(this);
    this.previewLoader = this.previewLoader.bind(this);
    this.updateData = this.updateData.bind(this);
    this.playPreview = this.playPreview.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount () {
    this.updateData();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.item.id !== this.props.item.id) {
      this.updateData();
    }
  }

  componentWillUnmount () {
    this.clear();
  }

  clear () {
    if (this.posterLoader) {
      this.posterLoader.onload = null;
    }

    if (this.ref?.current) {
      this.ref.current.removeEventListener('loadeddata', this.previewLoader);
      this.ref.current.removeEventListener('error', this.previewError);
    }
  }

  playPreview (src) {
    if (this.ref?.current) {
      this.ref.current.src = src || '';
      this.ref.current.load();
      this.ref.current.play();
    }
  }

  previewLoader () {
    this.setState({ previewLoaded: true });
  }

  previewError (e) {
    // fallback to source url
    if (this.ref.current?.src !== this.state.preview?.url) {
      this.playPreview(this.state.preview.url);
      this.ref.current.removeEventListener('error', this.previewError);
    }
  }

  updateData () {
    const { item } = this.props;

    this.clear();
    if (
      (item.isLive && (
        item.content_type === 'live_program' || (
          item.content_type === 'stop' && _.find(item.links || [], (item) => item.action === 'play')
        )
      )) ||
      item.isLinear
    ) {
      return this.setState({
        preview: {},
        poster: null,
        posterLoaded: false,
        previewLoaded: false,
        transparentMode: true
      });
    }

    const preview = SHOULD_SHOW_PREVIEW_HEADER &&
      settingsStore.state.settings.shouldShowVideoPreview &&
      getPreview(item.resources, item.id);
    const poster = getThumbnail(item.resources, item.id, 'pageBg');

    this.setState({
      preview,
      poster,
      posterLoaded: false,
      previewLoaded: false,
      transparentMode: false
    });

    if (this.ref?.current && preview?.src) {
      this.ref.current.pause();
      this.ref.current.currentTime = 0;
      this.ref.current.addEventListener('loadeddata', this.previewLoader);
      this.ref.current.addEventListener('error', this.previewError);
      // attempt to load cached video
      this.playPreview(preview.src);
    }

    if (poster) {
      this.posterLoader = new Image();
      this.posterLoader.src = poster;
      this.posterLoader.onload = () => {
        this.setState({ posterLoaded: true });
      };
    }
  }

  render () {
    const { poster, posterLoaded, preview, previewLoaded, transparentMode } = this.state;

    const classNames = cx(
      'home-featured-carousel-module__background',
      {
        'has-poster': !!poster,
        'poster-loaded': posterLoaded,
        'has-preview': !!preview?.src,
        'preview-loaded': previewLoaded,
        'transparent-mode': transparentMode
      }
    );

    if (transparentMode) {
      return (
        <div className={classNames} />
      );
    }

    return (
      <div className={classNames}>
        {poster && !previewLoaded && <img src={poster} />}
        {SHOULD_SHOW_PREVIEW_HEADER ? (
          <div className="home-featured-carousel-module__background__preview">
            <video ref={this.ref} preload="auto" autoPlay={true} loop={true} playsInline={true} />
          </div>
        ) : null}
      </div>
    );
  }
}
