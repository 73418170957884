import Reflux from 'reflux';
import PlatformUtils from '../utils/platform.js';

export const appActions = Reflux.createActions([
  'showBuffering',
  'hideBuffering'
]);

export const appStore = Reflux.createStore({
  listenables: appActions,

  state: {
    buffering: false,
    uid: PlatformUtils.sharedPlatform.uid(),
    inactivityTimeout: null,
    authToken: null,
    deeplinkAuthToken: null,

    // anonymouse session id vars (used for google analytics)
    lastRequestTime: new Date().getTime(),
    lastSessionTime: new Date().getTime(),
    sessionRefreshTime: 3.5 * 60 * 60 * 1000, // 3.5 hours
    sessionInactivityTime: 30 * 60 * 1000 // 30 min
  },

  getInitialState: function () {
    return this.state;
  },

  onShowBuffering: function () {
    this.state.buffering = true;
    this.trigger(this.state);
  },

  onHideBuffering: function () {
    this.state.buffering = false;
    this.trigger(this.state);
  },

  setAuthToken: function (authObj) {
    this.authToken = authObj.token;
    this.countryCode = authObj.country_code;
    this.sessionLocale = authObj.locale;
  },

  getCountryCode: function () {
    return this.countryCode;
  },

  getSessionLocale: function () {
    return this.sessionLocale;
  },

  setDeeplinkAuthToken: function (authObj) {
    this.deeplinkAuthToken = authObj.token;
  },

  getAuthToken: function () {
    if (this.authToken) {
      return this.authToken;
    } else {
      console.error('Auth token not set yet, start API request not done yet');
      return null;
    }
  },

  getDeeplinkAuthToken: function () {
    if (this.deeplinkAuthToken) {
      return this.deeplinkAuthToken;
    } else {
      return null;
    }
  }

});
