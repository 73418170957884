import React from 'react';
import {s} from '../../utils/screen-size';

export default class ProgressBar extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      headerLeft: s(184),
      headerWidth: 0
    };

    this.updateMeasurements = this.updateMeasurements.bind(this);
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (this.props.focused !== nextProps.focused || this.props.currentPosition !== nextProps.currentPosition || this.state.headerWidth !== nextState.headerWidth);
  }

  componentDidUpdate () {
    this.updateMeasurements();
  }

  componentDidMount () {
    this.updateMeasurements();
  }

  updateMeasurements () {
    try {
      const rect = this.props.headerRef.current.getBoundingClientRect();

      this.setState({
        headerLeft: rect.left,
        headerWidth: rect.width
      });
    } catch (_) {
      this.setState({
        headerLeft: s(184),
        headerWidth: 0
      });
    }
  }

  render () {
    const {currentPosition, totalLength} = this.props;

    if (totalLength < 2) {
      return null;
    }

    const showProgressBarAt = this.props.typeCard === 'FeaturedCard' ? 1 : 4;
    const shouldShowProgressBar = (( currentPosition >= showProgressBarAt && this.props.focused));

    if (!shouldShowProgressBar) {
      return (
        <div className='progress-bar'>
          <div className='progress-bar__bar' style={{'visibility': 'hidden'}} />
        </div>
      );
    }

    // Depends on page margin
    const totalBarWidth = s(1920) - (this.state.headerLeft); // Default home margin value for lists 184 - 24

    // Calculate progress bar width
    const textTitleWidth = this.state.headerWidth || s(450);
    const leftTitleMargin = s(16);
    const arrowsWidth = s(270);
    const leftProgressBarMargin = s(60);
    const rightProgressBarMargin = 0;
    const progressBarWidth = totalBarWidth - leftTitleMargin - textTitleWidth - leftProgressBarMargin - rightProgressBarMargin - arrowsWidth;

    // Calculate progress bar indicator width
    const w = progressBarWidth * (1 / totalLength);
    const indicatorWidthPx = Math.max(s(100), w); // minimum width of 100px

    const rightmost = progressBarWidth - indicatorWidthPx;
    const offset = ((rightmost / (totalLength - 1)) * currentPosition);

    const progressIndicatorStyles = {
      'left': offset + 'px',
      'width': indicatorWidthPx + 'px'
    };

    let barStyle = {width: progressBarWidth + 'px', left: textTitleWidth + 'px'};
    let barWidthStyle = {width: totalBarWidth};
    return (
      <div className='progress-bar progress-bar__fade_in' style={barWidthStyle}>
        <div style={barStyle} className='progress-bar__bar'>
          <div className='progress-bar__indicator' style={progressIndicatorStyles} />
        </div>
        <div className='progress-bar__text_arrow_container'>
          <div className='progress-bar__text_container'>
            <div className='progress-bar__text'><span className='bold-current'>{currentPosition + 1}</span> / {totalLength}</div>
          </div>
        </div>
      </div>
    );
  }
}
