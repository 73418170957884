import Reflux from 'reflux';

export const logsStoreActions = Reflux.createActions({
  'log': {},
  'consoleToRender': {}
});

export const logsStore = Reflux.createStore({
  listenables: logsStoreActions,

  state: {
    logs: [],
    logsToRender: []
  },

  consoleToRender: function () {
    console.log = function (msj) {
      if (msj === 'keyDown=50' || msj === 'keyDown=49') {
        return;
      }
      logsStore.log(msj, 'log');
    };
    console.warn = function (msj) {
      logsStore.log(msj, 'warn');
    };
    console.error = function (msj) {
      logsStore.log(msj, 'error');
    };
  },

  log: function (data, type) {
    var array = [...this.state.logsToRender];
    if (array.length > 1000) {
      array = array.slice(0, -1);
    }
    let obj = {
      text: data,
      type: type || 'log'
    };
    array.unshift(obj);
    this.state.logsToRender = array;
    this.trigger(this.state);
  }
});
