import React from 'react';
import Reflux from 'reflux';
import mix from 'react-mixin';
import cx from 'classnames';
import Switcher from '../../../utils/menu-switch.js';
import { listPageStore, listPageActions } from '../../../reflux/listPageStore';
import CardMenu from '../../../components/card-menu/card-menu';
import Background from './background';
import Featured from './featured';

const PAGE_ID = 'featuredCarouselModule';
const RAIL_ID = 'featuredCarouselModuleCardsRail';

@mix.decorate(Switcher)
export default class HomeFeaturedCarouselModule extends Reflux.Component {
  constructor (p) {
    super(p);

    this.state = {
      item: null
    };

    this.getInitialIndex = this.getInitialIndex.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  componentDidMount () {
    const initialIndex = this.getInitialIndex();
    this.onChange(initialIndex);
  }

  componentWillUnmount () {
    this.props.onChange(null);
  }

  getInitialIndex () {
    return listPageStore.getIndexForMenu(PAGE_ID, RAIL_ID);
  }

  onChange (idx) {
    const { collection } = this.props;
    const item = Array.isArray(collection.items) && collection.items[idx];
    if (!item) {
      return this.setState({ item: null });
    }

    this.setState({ item });
    this.props.onChange(item, collection);
    listPageActions.updateMenuIndex(PAGE_ID, RAIL_ID, idx);
  }

  onEnter (item, idx) {
    const { collection } = this.props;
    collection.selectedId = item.id;
    collection.positionIndex = idx;
    item.positionIndex = idx;
    this.props.onEnter(item, collection, true);
  }

  render () {
    const { item } = this.state;
    if (!item) {
      return null;
    }

    const initialIndex = this.getInitialIndex();

    return (
      <div className={cx('home-featured-carousel-module', { 'is-focused': this.props.focused })}>
        <Background item={item} />
        <Featured item={item} />
        <CardMenu
          type="featured-small"
          key={RAIL_ID}
          id={RAIL_ID}
          initialIndex={initialIndex}
          focused={this.props.focused}
          row={this.props.collection}
          menuItems={this.props.collection.items}
          onEnter={this.onEnter}
          onUp={this.props.onUp}
          onDown={this.props.onDown}
          onChange={this.onChange}
          noHeader
        />
      </div>
    );
  }
}
