/* globals tizen, webapis */

import BasePlatform from './base.js';
import ErrorReporter from '../../error-reporter';

/**
 * Samsung platform
 */
export default class SamsungPlatform extends BasePlatform {
  /**
     * this platform does not need a proxy.
     */
  constructor () {
    super();
    this.needsProxy = false;
    this.previousVolume = null;
    this.ls = (typeof (window) !== 'undefined') ? this.ls : null;
    this.deviceLanguage = 'en-us';
    try {
      tizen.systeminfo.getPropertyValue('LOCALE',
        systemInfoLocale => {
          const lng = (systemInfoLocale.language || '').trim().toLowerCase();
          if (lng) {
            console.log('Got system language:', lng);
            this.deviceLanguage = lng;
          }
        },
        err => {
          console.log('Failed to get system language', err);
        }
      );
    } catch (e) {
      console.error('Cannot use `tizen.systeminfo.getPropertyValue`', e);
    }

    if (webapis.bixby) {
      // Set connection to Bixby client - NOTE: this has a remove in EXIT call below
      try {
        webapis.bixby.initialize();
        console.info('Bixby initialized');
      } catch (e) {
        ErrorReporter.captureException(e, 'Bixby present but failed to init');
      }
    }
    try {
      // this.tizenVersion = tizen.systeminfo.getCapabilities().platformVersion;
      this.tizenVersion = webapis.productinfo.getVersion();
      console.log('TIZEN VERSION:', this.tizenVersion);
    } catch (error) {
      console.log('Tizen error code = ' + error.code);
    }
  }

  /**
     * samsung platform
     * @returns {string}
     */

  name () {
    return {
      name: 'Samsung', variation: 'Tizen'
    };
  }

  localStorage () {
    if (this.ls) return this.ls;
    else return super.localStorage();
  }
  /**
     * exit to menu
     * @params {boolean} [toMenu = false] - whether to exit to menu or TV.
     */
  exit (toMenu = false) {
    if (toMenu) {
      tizen.application.getCurrentApplication().hide();
    } else {
      tizen.application.getCurrentApplication().exit();
    }
    if (webapis.bixby) {
      try {
        webapis.bixby.deinitialize();
      } catch (e) {
        ErrorReporter.captureException(e, 'Bixby present but failed to set action listeners');
      }
    }
  }

  /**
     * Samsung initialization
     * @params {function} [callback] callback to be called once init is done.
     */
  init (callback) {
    console.info('Init Samsung Tizen platform');

    tizen.tvinputdevice.registerKey('0');
    tizen.tvinputdevice.registerKey('1');
    tizen.tvinputdevice.registerKey('2');
    tizen.tvinputdevice.registerKey('3');
    tizen.tvinputdevice.registerKey('4');
    tizen.tvinputdevice.registerKey('5');
    tizen.tvinputdevice.registerKey('6');
    tizen.tvinputdevice.registerKey('7');
    tizen.tvinputdevice.registerKey('8');
    tizen.tvinputdevice.registerKey('9');
    tizen.tvinputdevice.registerKey('MediaPlay');
    tizen.tvinputdevice.registerKey('MediaPause');
    tizen.tvinputdevice.registerKey('MediaFastForward');
    tizen.tvinputdevice.registerKey('MediaRewind');
    tizen.tvinputdevice.registerKey('MediaStop');

    document.addEventListener('visibilitychange', () => { // fired for both hidden and exit commands
      if (document.hidden) {
        if (this.previousVolume) this.setVolume(this.previousVolume);
      } else {
        // Behavior when application is resumed
      }
    });

    if (typeof (callback) === 'function') callback();
  }

  disableScreenSaver () {
    if (typeof (webapis) !== 'undefined') {
      console.info('On Samsung Tizen, disabling screensaver');
      webapis.appcommon.setScreenSaver(webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF);
    } else {
      console.error('Tizen TV webapis are not loaded can not disable screensaver');
    }
  }

  deviceId () {
    return webapis.productinfo.getDuid();
  }

  model () {
    return webapis.productinfo.getModel();
  }

  firmware () {
    try {
      return webapis.productinfo.getFirmware();
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  supportsHDR () {
    return webapis.avinfo.isHdrTvSupport();
  }

  getVolume () {
    return tizen.tvaudiocontrol.getVolume() / 100;
  }

  setVolume (value, restoreVolumeOnExit) {
    if (this.model().search('UBD') > -1) {
      console.warn('BDP - do not set volume');
      return;
    }

    var isTTSSupported = false;
    try {
      // throws an exception on older tizen device.
      isTTSSupported = webapis.tvinfo.getMenuValue(webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY);
    } catch (ex) {
    }

    // we don't want to adjust volume when reading TTS
    if (isTTSSupported) return;

    this.previousVolume = restoreVolumeOnExit || null;
    tizen.tvaudiocontrol.setVolume(value * 100);
  }

  getDeviceLanguage () {
    return this.deviceLanguage;
  }

  areCaptionsEnabled () {
    const captionsEnabled = webapis.tvinfo.getMenuValue(webapis.tvinfo.TvInfoMenuKey.CAPTION_ONOFF_KEY);
    return captionsEnabled === 1;
  }
}
