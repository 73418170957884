import Reflux from 'reflux';
import PlatformUtils from '../utils/platform.js';
import { configDataStore } from './configDataStore';

const localStorage = PlatformUtils.sharedPlatform.localStorage();

const DEFAULT_COOKIE_STATUS = true;
const COOKIE_CONSENT_KEY = 'rbtv:cookieConsent';

export const cookieConsentActions = Reflux.createActions({
  'setupCookieConsent': {},
  'toggleCookie': {},
  'acceptAll': {},
  'saveCookies': {}
});

export const cookieConsentStore = Reflux.createStore({
  listenables: cookieConsentActions,
  state: {
    hasNewCookie: false,
    cookieConsent: []
  },
  setupCookieConsent: function () {
    let hasNewCookie = true;
    let userCookieConsentPreferences = JSON.parse(localStorage.getItem(COOKIE_CONSENT_KEY)) || this.state.cookieConsent;
    let cookieConsent = configDataStore.getConstant('opt_ins');

    if (Array.isArray(userCookieConsentPreferences)) {
      let savedCookies = [];
      cookieConsent = cookieConsent.map(cookie => {
        try {
          const userCookie = userCookieConsentPreferences.find(userCookie => userCookie.key === cookie.key);
          const sameVersion = userCookie.version === cookie.version;
          if (sameVersion) {
            savedCookies.push(cookie);
          }

          cookie.selected = !!userCookie.selected;
        } catch (e) {
          cookie.selected = DEFAULT_COOKIE_STATUS;
        }
        return cookie;
      });

      hasNewCookie = savedCookies.length !== cookieConsent.length;
    }

    this.state.hasNewCookie = hasNewCookie;
    this.state.cookieConsent = cookieConsent;
  },
  toggleCookie: function (key) {
    this.state.cookieConsent = this.state.cookieConsent.map(cookie => {
      if (cookie.key === key) {
        cookie.selected = !cookie.selected;
      }

      return cookie;
    });
    this.saveCookies();
  },
  acceptAll: function () {
    this.state.cookieConsent = this.state.cookieConsent.map(cookie => ({ ...cookie, selected: true }));
    this.saveCookies();
  },
  saveCookies: function () {
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(this.state.cookieConsent));
  }
});
