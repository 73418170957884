import PlatformUtils from '../utils/platform';

function getXboxCaptionOptions () {
  var captionOptions = null;
  if (PlatformUtils.getPlatformID() === PlatformUtils.XBOXONE && typeof Windows !== 'undefined' && Windows.Xbox) {
    /* global Windows:true */
    var captionSettings = Windows.Xbox.System.ClosedCaptions.ClosedCaptionProperties;

    if (captionSettings !== null) {
      captionOptions = {
        textFont: fontStyleEnumToFont(captionSettings.fontStyle),
        textSize: getfontSizeFromEnum(captionSettings.fontSize),
        textColor: colorToRgbString(captionSettings.fontColor),
        textOpacity: getOpacityFromColor(captionSettings.fontColor),
        textEdgeStyle: getFontEffectsFromFontEffectsEnum(captionSettings.fontEdgeAttribute),
        backgroundColor: colorToRgbString(captionSettings.backgroundColor),
        backgroundOpacity: getOpacityFromColor(captionSettings.backgroundColor),
        windowColor: colorToRgbString(captionSettings.windowColor),
        windowOpacity: getOpacityFromColor(captionSettings.windowColor)
      };
    }
  }
  return captionOptions;
}

function fontStyleEnumToFont (enumValue) {
  switch (enumValue) {
    // Default
    case 0:
      return 'default';
      // Monospace Serif
    case 1:
      return 'monospace serif';
      // Proportional Serif
    case 2:
      return 'serif';
      // Monospace Sans Serif
    case 3:
      return 'monospace sans-serif';
      // Proportional Sans Serif
    case 4:
      return 'sans-serif';
      // Casual
    case 5:
      return 'casual';
      // Cursive
    case 6:
      return 'cursive';
      // SmallCaps
    case 7:
      return 'smallCaps';
  }
}

function getfontSizeFromEnum (enumValue) {
  switch (enumValue) {
    // 100%
    case 0:
      return 1.0;
      // 50%
    case 1:
      return 0.5;
      // 75%
    case 2:
      return 0.75;
      // 150%
    case 3:
      return 1.5;
      // 200%
    case 4:
      return 2.0;
  }
}

function getFontEffectsFromFontEffectsEnum (enumValue) {
  switch (enumValue) {
    // Default
    case 0:
      return 'none';
      // None
    case 1:
      return 'none';
      // Raised
    case 2:
      return 'raised';
      // Depressed
    case 3:
      return 'depressed';
      // Uniform
    case 4:
      return 'uniform';
      // Drop shadow
    case 5:
      return 'shadow';
  }
}

function getOpacityFromColor (color) {
  return color.a / 255;
}

function colorToRgbString (color) {
  return rgbToString(color.r, color.g, color.b);
}

function rgbToString (r, g, b) {
  return 'rgb(' + r + ',' + g + ',' + b + ')';
}

var xboxCaptionsConfig = {

  getCaptionValue: function () {
    return getXboxCaptionOptions();
  },
  // User this when xbox system fonts are added.
  getFontTable: function () {
    var table = null;
    if (PlatformUtils.getPlatformID() === PlatformUtils.XBOXONE) {
      table = {
        'default': 'default',
        'monospace serif': 'monospaceSerif',
        'proportional serif': 'proportionalSerif',
        'monospace sans-serif': 'monospaceSansSerif',
        'proportional sans-serif': 'proportionalSansSerif',
        'casual': 'casual',
        'cursive': 'cursive',
        'smallcaps': 'smallCaps'
      };
    }
    return table;
  }
};

export default xboxCaptionsConfig;
