import React from 'react';
import Reflux from 'reflux';
import _ from 'lodash';
import keymapper from './keymapper';
import PropTypes from 'prop-types';
import PlatformUtils from './platform';

/* A singleton that manages which React Component has the current focus */

// Private Navigation Singleton variables.

// Not sure if there's a better way to do this, but we need one global singleton
// even if for some reason we get multiple instances of this module included
// window - browser, global - node;
var universe = typeof (window) !== 'undefined' ? window : typeof (global) !== 'undefined' ? global : {};

/**
 * NavigationManager classs, should attempt to create a universal singleton
 *
 */
class NavigationManager {
  /**
     * Navigation Manger class
     * @returns {NavigationManager}
     */
  constructor () {
    /**
         * @type {Object}
         * @property {Object} _currentFocus - the currently focused menu object.
         * @property {Object} _menus - the hash of menu objects.
         * @property {boolean} _initialized - whether menu has been initialized and keys bound
         */

    this._enabled = true;
    this._menus = {};
    this.currentFocus = null;
    this._initialized = false;
    this._keymapper = keymapper();
    // Creating a very simple back pubsub.
    this.handlers = {};

    this._longPressTimer = null;
    this._longPressAdditionalKeyCount = 0;
    this._longPressLastKey = null;

    this._time = new Date(); // singleton test.

    if (!universe.__reactv_navigation) {
      universe.__reactv_navigation = this;
    }

    return universe.__reactv_navigation;
  }

  /**
     * sets focus to a menu
     * @param {Object|string} item - direct or named reference of a menu to receive focus.
     * @return {boolean} whether focus was moved.
     */
  setFocus (item) {
    var menu = item;
    if (typeof menu === 'string') menu = this.getMenu(item);
    if (menu && menu !== this.currentFocus) {
      this.currentFocus = menu;
      this.currentFocus.setState({focused: true});
      return true;
    } else if (!menu) {
      console.warn('tried to set focus to something invalid %s', item, menu);
    }
    return false;
  }

  /**
     *  when a component unmounts and its currently focused we no longer have a currentFocus
     */
  deFocus (menu) {
    if (this.currentFocus === menu) this.currentFocus = null;
  }

  /**
     * registers a menu with the navigation object. Turns on navigation objects depending on whether they are
     * mounted ornot.
     * @param {string} id - unique id of menu.
     * @param {Menu} menu - menu to be registered.
     * @return {Menu} - menu that was registered.
     */
  registerMenu (id, menu) {
    if (this._menus[id]) console.warn('registering a new menu with the same id as an old menu ' + id);
    this._menus[id] = menu;
    return menu;
  }

  /**
     * Unregisters a menu by id;
     * @param {string} id  - id of menu to be removed
     */
  unregisterMenu (id) {
    if (this.currentFocus === this._menus[id]) this.currentFocus = null;
    delete this._menus[id];
  }

  /**
     * get menu by menuid
     * @param {string} key - menuid of the menu to be returned.
     * @returns {Menu}
     */
  getMenu (key) {
    return this._menus[key];
  }

  /**
     * routes events to the currently active menu.
     * @param {string} evt  - event to send
     * @returns {boolean}   - whether we did anything with the event.
     */
  send (evt) {
    if (!evt || !this._enabled) return false;
    this.publish(evt);
    if (this.currentFocus && this.currentFocus['on' + evt]) {
      this.currentFocus['on' + evt]();
      return true;
    } else {
      return false;
    }
  }

  /**
     * clears all the menus;
     */

  reset () {
    this._menus = {};
  }

  /**
     * @returns {Object} dictionary of all the menus keyed by ID
     */

  menus () {
    return this._menus;
  }

  /**
     * disables navigation
     */
  disable () {
    this._enabled = false;
  }

  /**
     * enables navigation
     */
  enable () {
    this._enabled = true;
  }

  /**
     * Sets up our key bindings.
     */

  init () {
    let callSend = (eventName, e) => {
      var sent = this.send(eventName);
      if (sent || this.handlers[eventName]) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (!this._initialized) {
      let keyDownFunc = (e) => {
        console.log('keyDown=' + e.keyCode);
        var eventName = this._keymapper[e.keyCode];
        // We trap the Back event here in order to not have it repeat on XboxOne
        if (eventName === 'Enter' || eventName === 'Back') {
          if (!this._longPressTimer) {
            this._longPressTimer = setTimeout(() => {
              console.log('LONG PRESS', eventName);
              this._longPressAdditionalKeyCount++;
              // we only fire long press on the Enter.  No need for long press on Back ATM.
              // we dont actually get a keycode for this, so we hardcode the eventName here
              if (eventName === 'Enter') callSend('LongPress', e);
              if (eventName === 'Back') callSend(eventName, e);
            }, 1000);
          }
          // Leaving this debug in so we can see if we can use shorter delays on fast devices
          // The "KeyCount" will be higher on faster devices.  Perhaps we can drop this to 500ms.
          // console.log('this._longPressLastKey=',this._longPressLastKey, ' eventName=', eventName);
          // console.log('this._longPressAdditionalKeyCount=',this._longPressAdditionalKeyCount);
          if (this._longPressLastKey && this._longPressLastKey === eventName) {
            this._longPressAdditionalKeyCount++;
          } else {
            this._longPressAdditionalKeyCount = 0;
          }
          this._longPressLastKey = eventName;
          if (eventName === 'Enter') callSend('EnterPressed', e);
          e.preventDefault();
          e.stopPropagation();
        } else {
          callSend(eventName, e);
        }
      };

      let keyUpFunc = (e) => {
        // console.log('keyUp=' + e.keyCode);
        clearTimeout(this._longPressTimer);
        this._longPressTimer = null;
        this._longPressLastKey = null;
        var eventName = this._keymapper[e.keyCode];
        if (eventName === 'Enter' || eventName === 'Back') {
          eventName = this._longPressAdditionalKeyCount ? eventName + 'Up' : eventName;
        } else {
          eventName = eventName + 'Up';
        }

        callSend(eventName, e);
        // console.log('keyUp=' + e.keyCode, ' event=', eventName );
        this._longPressAdditionalKeyCount = 0;
      };

      if (PlatformUtils.isHBBTV && PlatformUtils.sharedPlatform.getBrand() === 'Samsung') {
        console.log('Keydown and keyup debounce is used');
        keyDownFunc = _.debounce(keyDownFunc, 50);
        keyUpFunc = _.debounce(keyUpFunc, 50);
      }

      document.addEventListener('keydown', keyDownFunc);
      document.addEventListener('keyup', keyUpFunc);
      this._initialized = true;
    }
  }

  /**
     * Simple pub sub model for back handling
     * @param {String} key - event to subscribe to
     * @param {function} func  - function to subscribe to back events.
     * @returns {function} - unsubscribe function.
     *
     * @example
     *
     * import Navigation from 'reactv-navigation'
     *
     * let binding = Nav.subscribeToKey('Back', () => {
     *  console.info('back called');
     *  binding.unsubscribe();
     * })
     *
     *
     */
  subscribeToKey (key, func) {
    // Find or create Queue
    if (!this.handlers.hasOwnProperty(key)) { this.handlers[key] = []; }
    let index = this.handlers[key].push(func) - 1;
    var that = this;
    return {
      index: index,
      unsubscribe: function () {
        delete that.handlers[key][index];
      }
    };
  }

  /**
     * Publish key event
     * @param {String} key - event to be published
     * TODO: should this be private? My Thoughts are not?
     */
  publish (key) {
    if (!this.handlers.hasOwnProperty(key)) return;
    // Defer until end of call stack (a bit more safe);
    setTimeout(() => {
      this.handlers[key].forEach((handler) => { handler(); });
    }, 0);
  }
}

export const Navigation = new NavigationManager();

// Start the listener when we're in the DOM
if (typeof (window) !== 'undefined') window.addEventListener('DOMContentLoaded', Navigation.init.bind(Navigation));
// If the document is already here just init. (happens with async script load)
if (typeof (document) !== 'undefined') Navigation.init();

/**
 * class Menu extends React.Component
 * Abstract class for subclassing provides core functionality but should not
 * be instatiated.
 */
export class Menu extends Reflux.Component {
  /**
     * @param {Object} props - React.Component props.
     */
  constructor (props) {
    super(props);
    var state = Object.assign({
      active: true
    }, this.state, {
      focused: typeof (this.props.focused) !== 'undefined' ? this.props.focused : false
    });
    /**
         * @type {Object}
         * @property {state}  state.active - whether current menu is active and can be focused on.
         * @property {number} state.focused - whether current menu is focused
         */
    this.state = state;
    return this;
  }

  /**
     * Sets focus on the menu. Note the navigation manager actually changes
     */
  focus () {
    Navigation.setFocus(this);
  }

  /**
     * default super class up handler looks for prop to call
     */
  onUp () {
    if (this.props.onUp) {
      this.props.onUp();
    }
  }
  /**
     * default super class down handler looks for prop to call
     */
  onDown () {
    if (this.props.onDown) {
      this.props.onDown();
    }
  }
  /**
     * default super class left handler looks for prop to call
     */
  onLeft () {
    if (this.props.onLeft) {
      this.props.onLeft();
    }
  }
  /**
     * default super class right handler looks for prop to call
     */
  onRight () {
    if (this.props.onRight) {
      this.props.onRight();
    }
  }
  /**
     * default super class enter handler looks for prop to call
     * @caption this will usually be subclassed and called via super
     */
  onEnter () {
    if (this.props.onEnter) {
      var args = Array.prototype.slice.call(arguments, 0);
      this.props.onEnter.apply(this, args);
    }
  }

  onBack () {
    if (this.props.onBack) {
      var args = Array.prototype.slice.call(arguments, 0);
      this.props.onBack.apply(this, args);
    }
  }

  onLongPress () {
    const args = Array.prototype.slice.call(arguments, 0);
    if (this.props.onLongPress) {
      this.props.onLongPress.apply(this, args);
    } else if (this.props.onEnter) {
      this.props.onEnter.apply(this, args);
    }
  }

  /**
     * default super class LeftTrigger handler looks for prop to call
     */
  onLeftTrigger () {
    if (this.props.onLeftTrigger) {
      this.props.onLeftTrigger();
    }
  }
  /**
     * default super class RightTrigger handler looks for prop to call
     */
  onRightTrigger () {
    if (this.props.onRightTrigger) {
      this.props.onRightTrigger();
    }
  }

  /**
     * registers menu when mounted and auto-sets focus if its not focused.
     * @caption make sure to call super.componentDidMount from sublcasess.
     */
  componentDidMount () {
    if (this.props.menuid) Navigation.registerMenu(this.props.menuid, this);
    if (this.props.focused) {
      Navigation.setFocus(this);
    }
  }

  /**
     * checks for focus, grabs it  if not currently focused.
     * @caption make sure to call super.componentDidUpdate from sublcasess.
     */
  componentDidUpdate (prevProps, prevState) {
    if (!prevProps || !prevState) {
      console.warn('Menu Component componentDidUpdate called but no prevProps' +
                            ' %s or prevState %s was passed. Did you call this via super.componentDidUpdate' +
                            ' and not pass in the prevProps or prevState?', prevProps, prevState);
    }

    if (prevProps && !prevProps.focused && this.props.focused) {
      Navigation.setFocus(this);
    }
  }

  /**
     * unregisters menu when unmounted and auto-sets focus if its not focused.
     * @caption make sure to call super.componentWillUnmount from sublcasess.
     */
  componentWillUnmount () {
    if (this.props.menuid) Navigation.unregisterMenu(this.props.menuid);
    Navigation.deFocus(this);
  }
}
/**
 * React propType validation
 */
Menu.propTypes = {
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  onLeft: PropTypes.func,
  onRight: PropTypes.func,
  onEnter: PropTypes.func
};

/**
 * Focusable class
 * Just a shorthand for handling state of focus components, trys to set focus on dom
 * elements.
 */
export class Focusable extends React.Component {
  /**
     * Focusable constructor returns Focusable
     * @params {Object} props - React.Component props
     */
  constructor (props) {
    super(props);
    this.state = {
      focused: typeof (this.props.focused) !== 'undefined' ? this.props.focused : false
    };
  }
  /**
     * sets the focus of the focusable
     */
  focus () {
    this.setState({focused: true});
    var node = this.getDOMNode();
    if (node && node.focus) node.focus();
  }
}
/**
 * button class, simple menu and focusable in one
 * @example
 * export default class TestButton extends Button {
 *
 *   render() {
 *       var cx = classnames({'focused': this.state.focused});
 *       return (
 *           <button {...this.props} className={cx} {...this.getFocusProps()}>{this.props.text}</button>
 *       )
 *   }
 * }
 *
 * class App extends React.Component {
 *      doSomething(selectedItem) {
 *          console.log('got selected item', selectedItem);
 *      }
 *      render() {
 *          return (
 *              <TestButton onEnter={this.doSomething} />
 *          )
 *      }
 *  }
 */

export class Button extends Menu {
  /**
     * @param {Object} props - React.Component props.
     */
  constructor (props) {
    super(props);
    /**
         * @type {Object}
         * @property {boolean} state.focused - whether the button is focused or not.
         */
    this.ref = React.createRef();
    this.state = {
      focused: typeof (this.props.focused) !== 'undefined' ? this.props.focused : false
    };
  }

  /**
     * focus props to be passed to child dom element
     * @return {Object} an object of properties to be to be passed to the children focusable elements.
     * @property {Function} onMouseOver     - onMouseOver function that handles the moueover events, updates index and sets focus
     * @property {Function} onClick         - onClick function that handles click events.
     * @property {string} menuid            - id of the menu.
     */
  getFocusProps () {
    var menu = this;
    return Object.assign({}, this.props, {
      onMouseOver: function () {
        if (!menu.state.focused) menu.focus();
      },
      onClick: function () {
        menu.onEnter();
      },
      menuid: menu.props.menuid
    });
  }

  /**
     * sets focus on button
     */
  focus () {
    super.focus();
    this.setState({focused: true});
    var node = this.ref.current;
    if (node && node.focus) node.focus();
  }
}
/**
 * Little focus utility for Parent components.
 * @type {{setFocus: Function, isFocused: Function}}
 */
export let FocusHandler = {

  setFocus: function (refName) {
    var setfocus = function setfocus () {
      this.setState({
        focused: refName
      });
    }.bind(this);
    return setfocus;
  },

  isFocused: function (refName) {
    return this.props.focused && this.state.focused === refName;
  }
};
