import BasePlatform from './base.js';

export default class Philips extends BasePlatform {
  constructor () {
    super();
    console.log('Instantiated Philips...');
  }

  exit () {
    if (window && window.close) {
      window.top.close();
    }
  }

  getVolume () {
    return document.getElementById('html5Player').volume;
  }

  setVolume (value, restoreVolumeOnExit) {
    document.getElementById('html5Player').volume = value;
  }
}
