import API from '../services/api.js';
import _ from 'lodash';
import {paginationStore} from '../reflux/paginationStore';
import conf from '../conf';

export default class PlaylistServiceDelegate {
  /**
   * Map AJAX object to Playlist component items object
   * @param playlistObject - API JSON result object Rebdbull-API-V2.0/playlist/{id}
   * @returns {Object}
   */

  static getPlaylistData (playlistID, cardID) {
    playlistID = playlistID.replace('/playlists/', '');
    return API.getPlaylist(playlistID, 1, null, cardID)
      .then(PlaylistServiceDelegate.onSuccess)
      .catch(PlaylistServiceDelegate.onError);
  }

  static onSuccess (result) {
    return new Promise(
      function (resolve, reject) {
        resolve(PlaylistServiceDelegate.mapPlaylist(result));
      });
  }

  static onError (err) {
    return new Promise(
      function (resolve, reject) {
        let error = {errorCode: err};
        reject(error);
      });
  }

  static filterOldLiveEvents (playlistObject) {
    if (conf.disableOldEvents) {
      const liveDashEventsDate = new Date(new Date() - 7776000000);
      _.forEach(playlistObject.items, (item) => {
        const isPastLive = !!(item.content_type === 'live_program' && item.status?.code === 'post');

        if (isPastLive) {
          const isOldEvent = (liveDashEventsDate > new Date(item.status.end_time));
          if (isOldEvent) {
            item.playable = false;
            item.isOldEvent = true;
          }
        }
      });
    }
  }

  static mapPlaylist (playlistObject) {
    PlaylistServiceDelegate.filterOldLiveEvents(playlistObject);

    paginationStore.removeAllPagesByPlaylistId(playlistObject.id);

    let totalLength = playlistObject.meta.total;
    let pageSize = playlistObject.meta.limit;

    let pageNumber = Math.ceil((playlistObject.meta.offset + 1) / playlistObject.meta.limit);
    paginationStore.storePageByPlaylistId(playlistObject.id, _.cloneDeep(playlistObject), pageNumber);
    let pageStartPosition = pageSize * (pageNumber - 1);

    let itemsCopy = playlistObject.items.slice();
    playlistObject.items = new Array(totalLength);
    _.fill(playlistObject.items, {id: null, type: null});

    var args = [pageStartPosition, pageSize].concat(itemsCopy);
    Array.prototype.splice.apply(playlistObject.items, args);

    return playlistObject;
  }
}
