import Reflux from 'reflux';
import api from '../services/api';
import PlatformUtils from '../utils/platform';
import {configDataStore} from './configDataStore';
import {ariaTTSServiceStore} from './ariaTTSServiceStore';
import _ from 'lodash';
import stringConstants from '../utils/stringConstants';
import conf from '../conf';

export const localizationActions = Reflux.createActions({
  'loadLocalizationStrings': {asyncResult: true},
  'cycleAppLanguage': {}
});

localizationActions.loadLocalizationStrings.listen(function (langCode, loadServusOverrides, callback) {
  const baseUrl = configDataStore.getConstant('localization_base_url');

  let promises = [api.getRaw(baseUrl + '/' + langCode + '.json')];

  if (loadServusOverrides) {
    promises.push(api.getRaw(baseUrl + '/servustv-complete/de-DE.json'));
  }

  Promise.all(promises)
    .then(this.completed.bind(null, langCode, callback))
    .catch(this.failed);
});

export const localizationStore = Reflux.createStore({
  listenables: localizationActions,

  init: function () {
    this.state = {
      localizationStrings: {},
      currentLangCode: 'en'
    };
  },

  onLoadLocalizationStringsCompleted (langCode, callback, langData) {
    const mainLangData = JSON.parse(langData[0]);
    const overridesLangData = (langData[1] && JSON.parse(langData[1])) || {};
    this.state.localizationStrings[langCode] = {...mainLangData, ...overridesLangData};
    if (_.isFunction(callback)) callback();
  },

  setupLocalizationMappings (callback) {
    this.checkIfDeviceLanguageIsSupported();
    localizationActions.loadLocalizationStrings(this.state.currentLangCode, conf.appNamespace === 'servustv', callback);
  },

  checkIfDeviceLanguageIsSupported () {
    if (conf.appNamespace === 'servustv') {
      this.state.currentLangCode = 'de-DE';
      return;
    }

    const localizationLanguages = configDataStore.getConstant('localization_languages');
    this.state.currentLangCode = 'en'; // Default to English as fallback
    let deviceLanguage = PlatformUtils.getDeviceLanguage();
    let deviceLanguageIsSupported = _.findIndex(localizationLanguages,
      (lang) => {
        return lang.code === deviceLanguage;
      });
    if (deviceLanguageIsSupported !== -1) {
      this.state.currentLangCode = deviceLanguage;
    } else {
      deviceLanguageIsSupported = _.findIndex(localizationLanguages,
        (lang) => {
          let splitDeviceLanguage = deviceLanguage.split('-')[0];
          let splitLangCode = lang.code.indexOf('-') !== -1 ? lang.code.split('-')[0] : lang.code;
          // Try again but split device language to first part (ie en-us -> en), and try against the full
          // lang code + the split lang code
          return ((lang.code === splitDeviceLanguage) || (splitDeviceLanguage === splitLangCode));
        });
      if (deviceLanguageIsSupported !== -1) {
        this.state.currentLangCode = localizationLanguages[deviceLanguageIsSupported].code;
      }
    }
    if (this.state.currentLangCode !== 'en') {
      ariaTTSServiceStore.setTTSVoice();
    }
  },

  setAppLanguage (langCode) {
    console.log('switching to', langCode);
    localizationActions.loadLocalizationStrings(langCode);
    localizationStore.state.currentLangCode = langCode;
    PlatformUtils.sharedPlatform.localStorage().setItem('rbtv:debug:testLanguage', langCode);
    this.trigger(this.state);
  },

  _GET (val, templateVal1, templateVal2) {
    try {
      if (!val) {
        return '';
      }
      const currentLangCode = this.state.currentLangCode;
      if (this.state.localizationStrings[currentLangCode] && this.state.localizationStrings[currentLangCode][val]) {
        let returnValue = this.state.localizationStrings[currentLangCode][val];
        if (templateVal1) {
          returnValue = returnValue.replace('%1$s', templateVal1);
          returnValue = returnValue.replace('%2$s', templateVal2);
        }
        return returnValue;
      } else {
        const value = stringConstants[val] || '';

        if (value === '') console.warn('Missing string constant', val, currentLangCode);
        return value;
      }
    } catch (e) {
      console.error('_GET', val, e);
      return '';
    }
  },

  _GET_LABEL (originalLabel) {
    try {
      // For labels like 'Play Film', transform to 'play_film' and check if it's one of the localization keys
      if (!originalLabel) {
        return '';
      }
      const currentLangCode = this.state.currentLangCode;
      let labelToKey = originalLabel.toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
      if (this.state.localizationStrings[currentLangCode] && this.state.localizationStrings[currentLangCode][labelToKey]) {
        return this.state.localizationStrings[currentLangCode][labelToKey];
      } else {
        return originalLabel;
      }
    } catch (e) {
      console.error('_GET_LABEL', originalLabel, e);
      return '';
    }
  }
});
