import Reflux from 'reflux';
import _ from 'lodash';

export const gridPageActions = Reflux.createActions({
  'reset': {sync: true},
  'updateGridState': {sync: true}
});

const defaultState = {
  currentGrid: 0,
  offset: 0,
  coordinates: {x: 0, y: 0},
  collectionStore: {}
};

export const gridPageStore = Reflux.createStore({
  listenables: gridPageActions,

  state: _.clone(defaultState),

  reset: function () {
    this.state = _.clone(defaultState);
    this.trigger(this.state);
  },

  updateGridState: function (newState) {
    this.state = _.clone(newState);
  }

});
