import BasePlatform from './base.js';

export default class SkyQ extends BasePlatform {
  constructor () {
    super();
    console.info('Instantiated SkyQ...');
  }

  exit () {
    if (window && window.close) {
      console.info('window close');
      window.close();
    }
  }

  getVolume () {
    console.info(`getVideo returns hard-coded volume of 1`);
    return 1; // html5 volume range is [0,1]
  }

  setVolume (value, restoreVolumeOnExit) {
    console.info(`setVolume to ${value} ignored`);
  }
}
