import Reflux from 'reflux';
import {imagePreload as ImagePreload} from '../services/image-preload.js';
import apiImageUrl from '../utils/api-image-url.js';
import api from '../services/api.js';
import conf from '../conf';
import EPGServiceDelegate from '../services/epgServiceDelegate.js';
import {configDataStore} from '../reflux/configDataStore';
import {consumptionStore, consumptionActions} from '../reflux/consumptionStore';
import {homeStore} from '../reflux/homeStore';
import {liveChannelsActions} from '../reflux/liveChannelsStore';
import {paginationStore} from '../reflux/paginationStore';
import PlatformUtils from '../utils/platform';
import _ from 'lodash';
import RefluxPromise from 'reflux-promise';

Reflux.use(RefluxPromise(window.Promise));
const isServus = conf.appNamespace === 'servustv';

export const epgActions = Reflux.createActions({
  'getInitialState': {},
  'loadEPG': {asyncResult: true},
  'loadLinearChannels': {asyncResult: true},
  'handleEPGChange': {},
  'handleEPGRefresh': {}
});

epgActions.loadEPG.listen(function (id) {
  return EPGServiceDelegate.loadEPG(id)
    .then(this.completed)
    .catch(this.failed);
});

export const epgStore = Reflux.createStore({
  listenables: epgActions,
  state: {
    channel: {},
    EPG: { meta: { limit: 8 } },
    cardList: [],
    allCards: [],
    currentlyPlaying: null,
    timeshifted: false,
    shiftNumber: 0,
    allImagesPreloaded: false,
    pagesRequested: {},
    initialLoadComplete: false,
    linearChannels: {items: []},
    currentLinearChannel: {},
    currentLinearChannelIndex: 0,
    showLinearChannelTitle: false
  },

  init: function () {
    this.switchLinearChannelTimeout = null;
    this.scheduleEPGRefresh = _.throttle(this.scheduleEPGRefresh, 30000, {leading: true, trailing: false});
  },

  loadEPGFailed: function (error) {
    console.error('EPG failed', error);
  },

  loadEPGCompleted: function (data) {
    if (!data ||
      (data.meta && data.meta.total === 0) ||
      (data.items && data.items.length === 0)) {
      console.error('EPG returned no data');
      epgStore.loadEPGFailed();
    }
    if (!this.state.initialLoadComplete) {
      this.setEPGData(data);

      this.state.shiftNumber = 0;
      this.state.allImagesPreloaded = false;
      this.state.initialLoadComplete = true;
    } else {
      let pageSize = data.meta.limit;
      let pageNumber = data.meta.offset / data.meta.limit;
      let start = (pageNumber) * pageSize;
      console.log('pageNumber=' + pageNumber + ' pageNumber - 1=' + (pageNumber) + ' pageSize=' + pageSize);
      let newItems = this.state.EPG.items.slice();
      data.items.forEach((item, idx) => {
        newItems[start + idx] = item;
      });
      this.state.allCards = this.state.EPG.items = newItems;
      this.trigger(this.state);
    }
  },

  loadLinearChannelsCompleted: function (linearChannelsData) {
    if (!linearChannelsData) {
      console.warn('No linear channels data');
      return;
    }

    if (PlatformUtils.isMultiLinearDisabled() && linearChannelsData.items && linearChannelsData.items.length) {
      linearChannelsData.items = linearChannelsData.items.filter(channel => channel.id === PlatformUtils.getDefaultLinearID());
    }

    this.state.linearChannels = linearChannelsData;

    // TODO: Find a better place to put this data, other than homeStore.
    // Only putting it directly in homeStore for now
    // because the homeStore is not able to import epgStore, startStore or consumptionStore without a circular import error
    homeStore.state.linearChannels = linearChannelsData;
    this._preloadLinearChannelImages();
  },

  setEPGData: function (data) {
    this.state.EPG.id = data.id;
    this.state.EPG.meta = data.meta || {};
    this.state.EPG.items = data.items || [];

    this.scheduleEPGRefresh();
    if (PlatformUtils.isMultiLinearDisabled()) {
      this.state.EPG.title = conf.appNamespace === 'servustv' ? 'ServusTV' : 'Red Bull TV';
      this.updateCurrentLinearChannel();
    }

    if (this.state.EPG && this.state.EPG.items) {
      let allTVCards = this.state.EPG.items || [];

      this.state.allCards = allTVCards;

      this.state.cardList = this.getCards();
      console.log('setEPGData - this.getTimeUntilNextVideo() = ' + this.getTimeUntilNextVideo());
      clearTimeout(this.state.epgTimeout);
      this.state.epgTimeout = window.setTimeout(this.handleEPGChange, (this.getTimeUntilNextVideo() + 250) > 2147483647 ? 2147483647 : this.getTimeUntilNextVideo() + 250);
      this.preloadSomeEPGImages();
    } else {
      clearTimeout(this.state.epgTimeout);
    }
  },

  preloadSomeEPGImages: function () {
    this._preloadEPGImages(0, 30);
  },

  preloadRemainingEPGImages: function () {
    if (this.state.allImagesPreloaded) {
      return;
    }
    this._preloadEPGImages((30 - this.state.shiftNumber));
    this.state.allImagesPreloaded = true;
  },

  _preloadEPGImages: function (begin, end) {
    if (!conf.fullPreload) {
      return;
    }
    let preloadImage = new ImagePreload();

    this.getCards().slice(begin, end).forEach((card, idx) => {
      // TV playlist cards
      preloadImage.preloadSource(apiImageUrl.getImageByType('schedule-item', card.resources, card.id));
    });
  },

  _preloadLinearChannelImages: function () {
    let preloadImage = new ImagePreload();
    if (!this.state.linearChannels.items) {
      return;
    }

    this.state.linearChannels.items.slice(0, 6).forEach((card, idx) => {
      // Preload images for the first 6 linear channel cards that show on Home
      preloadImage.preloadSource(apiImageUrl.getImageByType('linear-channel', card.resources, card.id));
    });
  },

  scheduleEPGRefresh: function () {
    if (this.state.epgRefreshTimeout) {
      clearTimeout(this.state.epgRefreshTimeout);
    }
    let ttl = 30000;
    console.log(` epg ttl in seconds = ${ttl / 1000}`);
    this.state.epgRefreshTimeout = window.setTimeout(epgActions.handleEPGRefresh, ttl);
  },

  handleEPGRefresh: function () {
    epgActions.loadEPG(this.state.EPG.id);
    this.scheduleEPGRefresh();
  },

  handleEPGChange: function () {
    // Remove first element, set another timeout
    this.state.cardList = this.getCards();
    this.state.shiftNumber = this.state.shiftNumber + 1;
    console.log('HANDLING CHANGE - ' + this.getTimeUntilNextVideo());
    this.state.epgTimeout = window.setTimeout(this.handleEPGChange, (this.getTimeUntilNextVideo() + 250) > 2147483647 ? 2147483647 : this.getTimeUntilNextVideo() + 250);
    this.trigger(this.state);
    if (this.state.cardList.length && consumptionStore.state.isLinear) {
      consumptionActions.updateContentObjectForEPG(this.getCurrentVideo());
    }
  },

  clearEPGTimeouts: function () {
    // We run two timeouts for the EPG:
    // 1) To refresh the data based on a TTL
    clearTimeout(this.state.epgRefreshTimeout);
    // 2) To handle removal of the first item of the list when that section of the EPG is finished
    clearTimeout(this.state.epgTimeout);
    // We need to clear both of these when the user switches away from linear to VOD
  },

  getTimeUntilNextVideo: function () {
    const currentVideo = this.getCurrentVideo();
    if (currentVideo) {
      let currentlyPlayingEnd = this.calculateDate(currentVideo.end_time);
      let currentTime = new Date();
      console.log('currentlyPlayingEnd - currentTime = ' + (currentlyPlayingEnd - currentTime));

      const nextVideoStartInterval = (currentlyPlayingEnd - currentTime);

      if (nextVideoStartInterval < 0) {
        console.warn('EPG data incorrect - current video in past.');
        return 1800000;
      }

      return nextVideoStartInterval;
    } else {
      console.warn('No EPG data');
      return 1800000;
    }
  },

  getCards: function () {
    if (!(this.state.allCards && this.state.allCards.length)) return [];
    let idx = this._firstRelevantCardIndex(this.state.allCards);
    return this.state.allCards.slice(idx);
  },

  getCurrentVideo: function () {
    if (!this.state.allCards) return null;
    if (!this.state.allCards.length) return null;

    let cards = this.state.allCards;
    let idx = this._firstRelevantCardIndex(cards);

    let currentProgram = cards[idx];

    if (!currentProgram) return null;
    return currentProgram;
  },

  _endTime: function (card) {
    if (!card) {
      return null;
    }
    if (!card._end_time_for_EPG) {
      card._end_time_for_EPG = +(this.calculateDate(card.end_time));
    }
    return card._end_time_for_EPG;
  },

  _firstRelevantCardIndex (cards) {
    let now = +(new Date()), i = 0;
    while (i < (cards.length - 2) && this._endTime(cards[i]) < now) {
      i++;
    }
    return i;
  },

  calculateDate: function (cardDate) {
    let epgDate = new Date(cardDate);
    if (this.state.timeshifted) {
      // TODO: Not sure if this offset is reliable - update as needed when we see real timeshifted EPG.
      epgDate = new Date(epgDate - (new Date().getTimezoneOffset()));
    }

    return epgDate;
  },

  getLinearStream: function () {
    return {
      id: 'linear',
      stream_url: api.getVideoUrl(configDataStore.getConstant('linear_stream_id'))
    };
  },

  switchLinearChannel: function (isRight) {
    console.log('epgStore.js - switchLinearChannel');
    if (!_.has(this.state, 'linearChannels.items')) {
      return;
    }
    let linearChannelsLength = this.state.linearChannels.items.length;
    let currentChannelIndex = this.state.currentLinearChannelIndex;
    let nextChannelIndex;
    if (isRight) {
      if (currentChannelIndex < (linearChannelsLength - 1)) {
        nextChannelIndex = currentChannelIndex + 1;
      } else {
        nextChannelIndex = 0;
      }
    } else if (!isRight) {
      if (currentChannelIndex > 0) {
        nextChannelIndex = currentChannelIndex - 1;
      } else {
        nextChannelIndex = linearChannelsLength - 1;
      }
    }
    this.state.currentLinearChannel = this.state.linearChannels.items[nextChannelIndex];
    this.state.currentLinearChannelIndex = nextChannelIndex;

    console.log('this.switchLinearChannelTimeout=', this.switchLinearChannelTimeout);

    if (this.switchLinearChannelTimeout) {
      clearTimeout(this.switchLinearChannelTimeout);
      this.switchLinearChannelTimeout = setTimeout(() => {
        consumptionActions.setAndPlayLinearChannel(this.state.currentLinearChannel.id);
        liveChannelsActions.updatePositionIndex(this.state.currentLinearChannelIndex);
        this.switchLinearChannelTimeout = null;
      }, 1000);
    } else {
      consumptionActions.setAndPlayLinearChannel(this.state.currentLinearChannel.id);
      liveChannelsActions.updatePositionIndex(this.state.currentLinearChannelIndex);
      this.switchLinearChannelTimeout = setTimeout(() => {
        this.switchLinearChannelTimeout = null;
      }, 1000);
    }
  },

  updateCurrentLinearChannel: function (id) {
    if (PlatformUtils.isMultiLinearDisabled()) {
      this.state.currentLinearChannelIndex = 0;
      this.state.currentLinearChannel = this.state.EPG;
      this.state.currentLinearChannel.id = PlatformUtils.getDefaultLinearID();
      return;
    }
    let currentLinearChannelIndex = _.findIndex(this.state.linearChannels.items, {id: id});
    if (currentLinearChannelIndex === -1) {
      const linearChannels = this.state.linearChannels;
      if (linearChannels && linearChannels.items && linearChannels.meta && (linearChannels.items.length < linearChannels.meta.total)) {
        paginationStore.loadRemainingCollectionPages(linearChannels);
        setTimeout(() => this.updateCurrentLinearChannel(id), 1000);
        return;
      } else {
        if (isServus) {
          // set default currentLinear because for Servus we don't have a default one in this.state.linearChannels (feature)
          if (this.state.servusUserIsGeoBlocked) {
            this.state.currentLinearChannel = {
              id: 'servus-fallback'
            };
            return;
          } else {
            this.state.currentLinearChannel = {
              id: PlatformUtils.getDefaultLinearID()
            };
            return;
          }
        }

        console.error('Couldn\'t find linear channel ' + id);
        currentLinearChannelIndex = 0;
      }
    }
    this.state.currentLinearChannelIndex = currentLinearChannelIndex;
    this.state.currentLinearChannel = this.state.linearChannels.items[currentLinearChannelIndex];
  }

});
