import Reflux from 'reflux';
import { videoPlayerActions } from './videoPlayerStore';
import { dialogActions } from './dialogStore';
import ProductServiceDelegate from '../services/productServiceDelegate.js';
import { configDataStore } from './configDataStore';
import { epgStore } from './epgStore';
import { videoTimeProxyStore } from './videoTimeProxyStore';
import ErrorReporter from '../utils/error-reporter';
import PlatformUtils from '../utils/platform';
import _ from 'lodash';
import { liveChannelsActions } from './liveChannelsStore';
import conf from '../conf.js';

export const consumptionActions = Reflux.createActions({
  'getHydratedProduct': { asyncResult: true },
  'showPlayerControls': {},
  'showFFwdRwdControls': {},
  'showContextualControls': {},
  'showLiveChannels': {},
  'hideContextualControls': {},
  'hidePlayerControls': {},
  'resetHiddenControls': {},
  'hideAllControls': {},
  'consumptionOnUp': {},
  'consumptionOnDown': {},
  'consumptionOnLeft': {},
  'consumptionOnRight': {},
  'consumptionOnEnter': {},
  'setCurrentAsset': {},
  'setCurrentAssetCompleted': {},
  'updateContentObjectForEPG': {},
  'toggleRecentlyWatchedPanel': {},
  'showSpinner': {},
  'reloadCurrentVideo': {},
  'showVideoErrorOverlay': {},
  'hideVideoErrorOverlay': {},
  'unplayableVideo': {},
  'forceLoadHome': {},
  'showEmbededHLSCaptions': {},
  'setAndPlayLinearChannel': {},
  'setIsChannelTitleVisible': {},
  'showLinearChannelsDrawer': {}
});

consumptionActions.getHydratedProduct.listen(function (assetID) {
  ProductServiceDelegate.getHydratedProductData(assetID)
    .then(this.completed)
    .catch(this.failed);
});

export const consumptionStore = Reflux.createStore({
  listenables: consumptionActions,

  init: function () {
    this.listenTo(videoTimeProxyStore, this.trackTimeUpdates);
    this.trackTimeUpdates = this.trackTimeUpdates.bind(this);
  },

  state: {
    isPlayerControlsVisible: false,
    isContextualControlsVisible: false,
    isRecentlyWatchedPanelVisible: false,
    nothingFocused: true,
    nothingFocusedDown: false,
    currentAssetID: '',
    currentAssetObject: {},
    isDeepLinkStart: false,
    isSpinnerVisible: false,
    backBinding: null,
    nextIsActive: false,
    inactivityTimeout: null,
    isChannelTitleVisible: true,
    assetIDToStopGALandingPage: null
  },

  getInitialState: function () {
    return this.state;
  },

  setIsChannelTitleVisible: function (isVisible) {
    if (isVisible) {
      this.state.isChannelTitleVisible = false;
      this.trigger(this.state);
    }
    this.state.isChannelTitleVisible = isVisible;
  },

  showPlayerControls: function () {
    this.state.isPlayerControlsVisible = true;
    this.state.isContextualControlsVisible = false;
    this.state.nothingFocused = false;
    this.setIsChannelTitleVisible(false);
    this.showEmbededHLSCaptions(false);
    this.trigger(this.state);
  },

  showContextualControls: function () {
    if (!this.state.isContextualControlsVisible && this.state.currentAssetObject) {
      this.state.isPlayerControlsVisible = false;
      this.state.isContextualControlsVisible = true;
      this.state.nothingFocused = false;
      this.showEmbededHLSCaptions(false);
      this.trigger(this.state);
    }
  },

  hideContextualControls: function () {
    if (this.state.isContextualControlsVisible) {
      this.state.isContextualControlsVisible = false;
      this.trigger(this.state);
      /* have to set this /after/ the trigger to prevent the hints from showing
               when we link through to a home page */
      this.state.nothingFocused = true;
      this.showEmbededHLSCaptions(true);
    }
  },

  resetHiddenControls: function () {
    // HACK: focus 'disappears' when playing from the recently-watched panel.
    //      this resets whatever state is out of whack
    this.state.nothingFocused = false;
    this.trigger(this.state);
    this.state.nothingFocused = true;
    this.trigger(this.state);
  },

  hideAllControls: function () {
    // Remove controls and set nothingFocused to true.
    if (this.state.isPlayerControlsVisible ||
        this.state.isContextualControlsVisible ||
        this.state.isRecentlyWatchedPanelVisible ||
        this.state.isLinearChannelsDrawerVisible) {
      this.state.isPlayerControlsVisible = false;
      this.state.isContextualControlsVisible = false;
      this.state.isRecentlyWatchedPanelVisible = false;
      this.state.isLinearChannelsDrawerVisible = false;
      this.state.nothingFocused = true;
      this.setIsChannelTitleVisible(true);
      this.showEmbededHLSCaptions(true);
      liveChannelsActions.updatePositionIndex(epgStore.state.currentLinearChannelIndex);
      this.trigger(this.state);
    }
  },

  hidePlayerControls: function () {
    if (this.state.isPlayerControlsVisible) {
      this.state.isPlayerControlsVisible = false;
      this.state.nothingFocused = true;
      this.showEmbededHLSCaptions(true);
      this.trigger(this.state);
    }
  },

  reloadCurrentVideo: function () {
    consumptionActions.hideVideoErrorOverlay();
    consumptionActions.showPlayerControls();
    this.state.videoError = false;
    this.state.currentSrc = 'clear';
    window.FORCE_REFRESH = true;
    videoPlayerActions.playNewStream(consumptionStore.state.currentAssetObject);
  },

  consumptionOnEnter: function () {
    if (this.state.videoError) {
      this.reloadCurrentVideo();
    } else {
      this.showPlayerControls();
    }
  },

  consumptionOnUp: function () {
    if (this.state.isPlayerControlsVisible === false) {
      this.showContextualControls();
    } else if (this.state.isPlayerControlsVisible === true) {
      this.hideAllControls();
    }
  },

  consumptionOnDown: function () {
    if (this.state.isPlayerControlsVisible === true) {
      this.hideAllControls();
    } else if (this.state.isContextualControlsVisible === true) {
      this.hideAllControls();
    } else if (this.state.isRecentlyWatchedPanelVisible === true) {
      this.hideAllControls();
    } else if (this.state.nothingFocused && consumptionStore.state.isLinear && !PlatformUtils.isMultiLinearDisabled()) {
      consumptionActions.showLinearChannelsDrawer();
    }
  },

  consumptionOnLeft: function () {
    if (this.state.isPlayerControlsVisible === false &&
      this.state.isContextualControlsVisible === false) {
      if (this.state.isLinear && !PlatformUtils.isMultiLinearDisabled()) {
        epgStore.switchLinearChannel(false);
        this.setIsChannelTitleVisible(true);
      } else {
        this.showPlayerControls();
      }
    }
  },

  consumptionOnRight: function () {
    if (this.state.isPlayerControlsVisible === false &&
      this.state.isContextualControlsVisible === false) {
      if (this.state.nextIsActive && this.playNextItemInPlaylist) {
        this.playNextItemInPlaylist();
        this.showWhatsNextHint(false);
      } else {
        if (this.state.isLinear && !PlatformUtils.isMultiLinearDisabled()) {
          epgStore.switchLinearChannel(true);
          this.setIsChannelTitleVisible(true);
        } else {
          this.showPlayerControls();
        }
      }
    }
  },

  setAndPlayLinearChannel: function (linearChannelId) {
    console.log('consumptionStore.js - setAndPlayLinearChannel', `linearChannelId=${linearChannelId}`);
    if (linearChannelId !== (epgStore.state.currentLinearChannel && epgStore.state.currentLinearChannel.id)) this.state.currentAssetObject = {};
    this.playLinearChannel(linearChannelId);
    this.trigger(this.state);
    this.setIsChannelTitleVisible(true);
  },

  setCurrentAsset: function (assetToPlay, resumePoint, isLinear) {
    console.log('consumption store setCurrentAsset: ', assetToPlay, isLinear);

    let wasError = this.state.videoError || window.FORCE_REFRESH;

    this.hideVideoErrorOverlay();

    if (!wasError) {
      if (this.state.currentAssetObject && (assetToPlay.id === this.state.currentAssetObject.id)) {
        if (!this.state.isLinear) {
          console.log('consumption store setCurrentAsset bailing early');
          return;
        }
      }
    }

    if (wasError) window.FORCE_REFRESH = true;

    this.state.currentAssetID = assetToPlay.id;
    this.state.currentAssetObject = assetToPlay;
    this.state.isLinear = (isLinear === 'undefined' ? false : isLinear) ||
      (assetToPlay.content_type === 'subchannel' || assetToPlay.content_type === 'video_channel');
    this.state.isLive = (assetToPlay.isLive === 'undefined' ? false : assetToPlay.isLive) ||
      (assetToPlay.content_type === 'live_program' || assetToPlay.content_type === 'live_recap');

    if (isLinear && _.has(epgStore.state, 'currentLinearChannel.id')) {
      PlatformUtils.sharedPlatform.localStorage().setItem('rbtv:last-linear-id', epgStore.state.currentLinearChannel.id);
    } else {
      PlatformUtils.sharedPlatform.localStorage().removeItem('rbtv:last-linear-id');
    }

    console.log('videoMetaData - this.state.currentAssetObject =', this.state.currentAssetObject);

    // We need to hydrate the product object to get all the collections that populate the Contextual tabs
    consumptionActions.getHydratedProduct(this.state.currentAssetID);
    consumptionActions.setCurrentAssetCompleted(this.state);
  },

  setCurrentAssetCompleted: function () {
    this.trigger(this.state);
  },

  updateContentObjectForEPG: function (data) {
    if (!data) {
      return;
    }
    this.state.currentAssetObject = data;
    consumptionActions.getHydratedProduct(data.id);
    consumptionActions.setCurrentAssetCompleted(this.state);
    this.trigger(this.state);
  },

  onGetHydratedProductCompleted: function (result) {
    // don't fire if we're attempting to play a linear stream,
    // as that can cause an infinite loop
    if (!result.playable && !this.state.isLinear) {
      console.error('onGetHydratedProductCompleted: unplayable video');
      consumptionActions.unplayableVideo(result);
    } else {
      this.state.currentAssetObject = result;
    }
  },

  onGetHydratedProductFailed: function (err) {
    if (conf.appNamespace === 'servustv' && err.errorCode === 403) {
      videoPlayerActions.playNewStream({
        id: 'servus-fallback',
        src: configDataStore.getConstant('linear_stream_fallback')
      }, null, true);
      return;
    }
    ErrorReporter.captureException(err, 'consumptionStore: onGetHydratedProductFailed:');
    consumptionActions.showSpinner(false);
    this.showVideoErrorOverlay('onGetProductFailed');

    this.trigger(this.state);
  },

  showSpinner: function (isVisible) {
    this.state.isSpinnerVisible = isVisible;
    dialogActions.hideBufferingScreen();
    this.trigger(this.state);
  },

  toggleRecentlyWatchedPanel: function () {
    if (this.state.isRecentlyWatchedPanelVisible) {
      this.closeRecentlyWatchedPanel();
    } else {
      this.openRecentlyWatchedPanel();
    }
  },

  trackTimeUpdates: function (timeState) {
    // ensure the video error overlay is hidden if the video resumes playing
    if (timeState.originator === 'lazyCurrentTime') {
      this.hideVideoErrorOverlay();
    }
  },

  showVideoErrorOverlay: function (debugMsg) {
    console.error('Video playback error. ' + debugMsg);
    videoPlayerActions.setIsStopped();

    consumptionActions.hideAllControls();
    window.FORCE_REFRESH = true;
    this.state.videoError = true;
    ErrorReporter.captureMessage('showVideoErrorOverlay', debugMsg);
    this.showEmbededHLSCaptions(false);
    this.trigger(this.state);
  },

  showLiveChannels: function () {
    if (!this.state.isContextualControlsVisible && this.state.currentAssetObject) {
      this.state.isPlayerControlsVisible = false;
      this.state.isContextualControlsVisible = true;
      this.state.nothingFocused = false;
      this.showEmbededHLSCaptions(false);
      this.trigger(this.state);
    }
  },

  hideVideoErrorOverlay: function () {
    if (this.state.videoError) {
      this.state.videoError = false;
      this.hideAllControls();
      this.showEmbededHLSCaptions(false);
      this.trigger(this.state);
    }
  },

  showWhatsNextHint: function (showHint, trigger = false) {
    this.state.nextIsActive = showHint;
    if (trigger) this.trigger(this.state);
  },

  showEmbededHLSCaptions: function (shouldShow) {
    /* global webapis: true */
    if (shouldShow) {
      if (PlatformUtils.isTizen) {
        webapis.tvinfo.registerInAppCaptionControl(false);
      }
    } else {
      if (PlatformUtils.isTizen) {
        webapis.tvinfo.registerInAppCaptionControl(true);
        webapis.tvinfo.showCaption(false);
      }
    }
  },

  openRecentlyWatchedPanel: function () {
    if (configDataStore.getConstant('recently_watched') && !this.state.isRecentlyWatchedPanelVisible) {
      this.state.isRecentlyWatchedPanelVisible = true;
      this.state.nothingFocused = false;
    }
    this.trigger(this.state);
  },

  closeRecentlyWatchedPanel: function () {
    if (this.state.isRecentlyWatchedPanelVisible) {
      this.hideAllControls();
    }
    this.trigger(this.state);
  },

  forceLoadHome: function () {
    this.state.FORCE_LOAD_HOME = true;
    this.trigger(this.state);
    this.state.FORCE_LOAD_HOME = false;
  },

  showLinearChannelsDrawer: function () {
    if (!this.state.isLinearChannelsDrawerVisible) {
      this.state.nothingFocused = false;
      this.state.isLinearChannelsDrawerVisible = true;
      this.trigger(this.state);
    }
  }
});
