import React from 'react';
import classnames from 'classnames';
import {playerControlsStore} from '../../reflux/playerControlsStore';
import {HorizontalMenu} from '../../utils/reactv-menus';
import {s} from '../../utils/screen-size';
import BaseCard from '../card/base-card';
import apiImageUrl from '../../utils/api-image-url.js';
import Reflux from 'reflux';
import {ariaTTSServiceStore} from '../../reflux/ariaTTSServiceStore';
import {localizationStore} from '../../reflux/localizationStore';
import {iTrackingActions} from '../../reflux/iTrackingStore';

export class PageButton extends Reflux.Component.extend(BaseCard) {
  constructor (p) {
    super(p);
    this.stores = [playerControlsStore];
  }

  componentDidMount () {
    iTrackingActions.addToBatch({
      row: this.props.iTrackingRow,
      index: this.props.index,
      title: `${document.location.pathname}:||:Button:||:${this.props.title || this.props.label}`,
      type: 'Button',
      id: this.props.id
    });
  }

  readAria () {
    ariaTTSServiceStore.readText(localizationStore._GET_LABEL(this.props.label) + ' : button' || 'Play' + ' : button');
  }

  render () {
    let label = localizationStore._GET_LABEL(this.props.label || 'Play');

    let focused = this.props.focused;

    let cx = classnames('page-button', {'is-focused': focused});
    return (
      <div className={cx}>
        <div className="page-button__innerWrapper" >
          <Icon className="page-button__icon" focused={focused && !this.props.noFocusStateForIcon} liveButton={this.props.liveButton} iconName={this.props.icon}/>
          <div className="page-button__label">{label}</div>
        </div>
      </div>
    );
  }
}

export class Icon extends React.PureComponent {
  render () {
    let sizing = s(this.props.size || 64);

    var inlineStyle = {
      width: sizing,
      height: sizing
    };

    var imgSrc = apiImageUrl.getIcon(this.props.iconName, this.props.focused, false, 64, 64);
    return (
      <img style={inlineStyle} className={this.props.className} src={imgSrc}></img>
    );
  }
}

export class PlayIcon extends React.PureComponent {
  render () {
    let sizing = s(64);

    var inlineStyle = {
      width: sizing,
      height: sizing
    };
    var imgSrc;
    if (this.props.liveButton) {
      imgSrc = apiImageUrl.getIcon('play', true, false, 64, 64);
    } else {
      imgSrc = apiImageUrl.getIcon('play', false, this.props.focused, 64, 64);
    }
    return (
      <img style={inlineStyle} className={this.props.className} src={imgSrc} ></img>
    );
  }
}

export class WhitePlayIcon extends React.Component {
  render () {
    let sizing = s(64);

    var inlineStyle = {
      width: sizing,
      height: sizing
    };
    var imgSrc = apiImageUrl.getIcon('currently-watching', true, false, 64, 64);
    return (
      <img style={inlineStyle} className={this.props.className} src={imgSrc}></img>
    );
  }
}

export class RedPlayIcon extends React.Component {
  render () {
    let sizing = s(55);

    var inlineStyle = {
      width: sizing,
      height: sizing
    };
    var imgSrc = apiImageUrl.getIcon('play', true, false, 64, 64);
    return (
      <img style={inlineStyle} className={this.props.className} src={imgSrc}></img>
    );
  }
}

export class CheckIcon extends React.Component {
  render () {
    let sizing = s(64);

    var inlineStyle = {
      width: sizing,
      height: sizing
    };
    var imgSrc = apiImageUrl.getIcon('check', true, false, 64, 64);
    return (
      <img style={inlineStyle} src={imgSrc}></img>
    );
  }
}

export default class PageButtons extends HorizontalMenu {
  constructor (props) {
    super(props);
    this.buttonOnEnter = this.buttonOnEnter.bind(this);
  }

  buttonOnEnter () {
    this.props.onEnter();
  }

  onEnter (e) {
    ariaTTSServiceStore.readText('', true, true);
    super.onEnter(e);
  }

  componentDidUpdate (prevProps, prevState) {
    if (super.componentDidUpdate) super.componentDidUpdate(prevProps, prevState);
    if (prevState && prevState.currentFocus !== this.state.currentFocus) {
      this.props.onChange && this.props.onChange(this.state.currentFocus);
    }
    if (this.props.focused !== prevProps.focused) {
      if (this.props.focused && this.props.onFocus) this.props.onFocus();
    }
  }

  componentWillUnmount () {
    iTrackingActions.sendBatchFor(this.props.iTrackingRow);
  }

  render () {
    if (!this.props.menuItems || !this.props.menuItems.length || this.props.hide) {
      return (
        <div className="list page-buttons">
          <div className="list__item"></div>
        </div>
      );
    }

    return (
      <div className="list page-buttons">
        {
          this.props.menuItems.map((button, idx) => {
            if ((button.action === 'play') || (button.action === 'playlist') || (button.action === 'view') || (button.action === 'play-linear-channel') || (button.action === 'favorite')) {
              return (
                <PageButton
                  key={idx}
                  label={localizationStore._GET_LABEL(button.label)}
                  focused={this.isFocused(idx)}
                  noFocusStateForIcon={button.noFocusStateForIcon}
                  onEnter={this.props.onEnter}
                  icon={button.icon}
                  liveButton={button.action === 'play' && this.props.isLive}
                  index={idx}
                  iTrackingRow={this.props.iTrackingRow}
                  onClickHandler={this.buttonOnEnter}/>
              );
            }
          })}
      </div>
    );
  }
}
