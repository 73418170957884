import React from 'react';
import Reflux from 'reflux';
import Home from './home';
import {startStore} from '../../reflux/startStore';
import qs from 'query-string';
import {dialogStore, dialogActions} from '../../reflux/dialogStore';
import _ from 'lodash';

export default class HomeWrapper extends Reflux.Component {
  constructor (props) {
    super(props);
    this.stores = [startStore, dialogStore];
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)) || (startStore.state.FORCE_UPDATE === true);
  }

  render () {
    if (this.state.status === 200) {
      return (
        <Home
          focused={this.state.isFirstPlayEventFinished && !dialogStore.overlayShowing()}
          route={'/Home'}
          {...this.props}
          params={this.props.match.params}
          locationQuery={qs.parse(this.props.location.search)}
          data={this.state.data}
          FORCE_UPDATE={startStore.state.FORCE_UPDATE}
        />
      );
    } else if (this.state.status) {
      dialogActions.showLoadFailedScreen();
      return null;
    } else {
      return null;
    }
  }
}
