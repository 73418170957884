import React from 'react';
import classNames from 'classnames';
import MenuItem from '../../components/menu-item/menu-item';
import {HorizontalMenu} from '../../utils/reactv-menus';
import PlatformUtils from '../../utils/platform.js';
import conf from '../../conf';
import _ from 'lodash';

export default class MainMenu extends HorizontalMenu {
  constructor (p) {
    super(p);
    this.props.menuItems.map((data, index) => {
      if (data.id === this.props.currentComponent.id) {
        this.state.currentFocus = index;
      }
    });
  }

  componentDidUpdate (prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps.currentComponent && this.props.currentComponent && (prevProps.currentComponent.id !== this.props.currentComponent.id)) {
      this.setState({
        currentFocus: _.findIndex(this.props.menuItems, (item) => {
          return item.id === this.props.currentComponent.id;
        })
      });
    }
  }

  parseSystemViews () {
    if (!this.props.menuItems || !this.props.menuItems.length) return;
    const needsBrighterMenu = conf.appNamespace === 'servustv' && PlatformUtils.isVIDAA;

    return this.props.menuItems.map((data, index) => {
      var itemClass = classNames('menu-item', 'menu-item-' + data.id, {
        'menu-item--active': this.isFocused(index),
        'menu-item--inactive': this.state.currentFocus === index && !this.isFocused(index),
        'menu-item__title--brighter': needsBrighterMenu
      });
      return (
        <MenuItem
          name={data.label}
          itemClass={itemClass}
          isActive={this.state.currentFocus === index}
          key={index}
          {...this.getFocusProps()}
          isFocused={this.isFocused(index)}
          needsBrighterMenu={needsBrighterMenu}
          onClickHandler={
            function () {
              this.setState({currentFocus: index});
              this.props.onEnter(data);
            }.bind(this)
          }
        />
      );
    });
  }

  render () {
    if (!this.props.show) return null;

    let views = this.parseSystemViews();
    let menuClass = classNames('menu', {
      'is-collapsed': this.props.collapse,
      'whiteTheme': this.props.whiteTheme
    });

    return (
      <div className='menu-wrapper'>
        <div className={menuClass}>
          <div className='menu-logo' />
          <div className='menu-items'>
            {views}
          </div>
        </div>
      </div>
    );
  }
}
