import React from 'react';
import cx from 'classnames';
import PlatformUtils from '../../utils/platform.js';
import {HorizontalMenu} from '../../utils/reactv-menus';
import ScheduleCard from '../schedule-card/schedule-card';
import {s} from '../../utils/screen-size.js';
import {consumptionStore} from '../../reflux/consumptionStore';
import {localizationStore} from '../../reflux/localizationStore';
import {paginationStore} from '../../reflux/paginationStore';
import {userAccountStore} from '../../reflux/userAccountStore';

const CARD_WIDTH = s(640);

// TODO: add isPlaceholder thing here
export default class ScheduleList extends HorizontalMenu {
  constructor (p) {
    super(p);
    this.stores = [paginationStore, userAccountStore];
  }

  componentDidUpdate (prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevState.currentFocus !== this.state.currentFocus) {
      this.props.onChange && this.props.onChange(this.state.currentFocus);

      this._paginationCheck();
    }

    let currentIndex = 0, currentOffset = 0;
    if (this.state.currentFocus > 0) {
      while (currentIndex < this.state.currentFocus) {
        currentOffset += CARD_WIDTH;
        currentIndex++;
      }
    }

    if (-currentOffset !== this.state.offset) {
      this.setState({
        offset: -currentOffset
      });
    }
  }

  _paginationCheck () {
    let {row} = this.props;

    if (!row || (row && row.items.length === row.meta.total)) {
      return;
    }

    let {limit: pageSize, total} = row.meta;
    let pageMax = Math.ceil(total / pageSize);
    let currentPage = Math.ceil((this.state.currentFocus + 1) / pageSize);

    if (currentPage + 1 > pageMax) return;

    let nextPageBegins = currentPage * pageSize;
    let overThreshold = ((nextPageBegins - (this.state.currentFocus + 1)) < (pageSize / 2));
    let id = row.id;

    if (!paginationStore.getPageByCollectionId(id, currentPage + 1) && overThreshold) {
      // If we're halfway to the next page and we don't already have it stored, hit the API
      paginationStore.loadCollectionPage(id, pageSize, currentPage + 1, row);
    }
  }

  renderDays () {
    let items = this.props.menuItems || [];
    return items.map((event, idx) => {
      event.isWatching = (event.id === consumptionStore.state.currentAssetID);
      event.isFavorite = userAccountStore.isCardInUserFavorites(event.id);

      return (
        <ScheduleCard
          {...event}
          key={idx}
          index={idx}
          focused={this.isFocused(idx)}
          collapsed={idx < this.state.currentFocus}
          onClickHandler={() => this.props.onEnter(event)}
        />
      );
    });
  }

  getStyles () {
    if (PlatformUtils.isPS4) {
      return { left: `${this.state.offset}px` };
    }

    return { transform: `translateX(${this.state.offset}px)` };
  }

  render () {
    let classNames = cx('schedule-list__list-wrapper', {
      'schedule-list__list-wrapper--active': this.props.focused
    });
    let style = this.getStyles();
    return (
      <div className='card-row schedule-list'>
        <div className='schedule-list__header'><h1>{localizationStore._GET_LABEL(this.props.row.label)}</h1></div>
        <div className='schedule-list__list'>
          <div className={classNames} style={style}>
            {this.renderDays()}
          </div>
        </div>
      </div>
    );
  }
}
