import Reflux from 'reflux';
import { playlistStore } from './playlistStore';
import { epgStore } from './epgStore';
import { consumptionStore } from './consumptionStore';
import { videoTimeProxyStore } from './videoTimeProxyStore';
import { appkitAnalyticsStore, analyticsVideoView } from './appkitAnalyticsStore';
import _ from 'lodash';
import PlatformUtils from '../utils/platform.js';

export const videoAnalyticsStore = Reflux.createStore({
  playType: 'auto',
  init: function () {
    if (this._initialized) return;
    this._initialized = true;
    this.listenTo(videoTimeProxyStore, this.captureVideoState);
    this.state = {
      totalTimeViewed: 0,
      skippedFirstUpdate: false,
      lastTimeStamp: null, // null means we haven't got an initial time step yet
      started: false,
      lastPercentageTracked: 0,
      currentSrc: '',
      currentPlayerState: null,
      lastMinuteTrackedGA: 0,
      lastCurrentTime: 0,
      linearTicks: -1,
      absolutePosition: 1,
      oneSecondTicks: 0,
      lastInterval: 0,
      metadata: {},
      initialCurrentTime: 0,
      volume: 1,
      playbackAudioData: {},
      playbackVideoData: {},
      playbackVideoQuality: {},
      currentAudioSegment: {},
      currentVideoSegment: {}
    };
  },

  setVolume (value) {
    this.state.volume = value;
  },

  setPlaybackAudioData: function (data) {
    this.state.playbackAudioData = data;
  },

  setPlaybackVideoData: function (data) {
    this.state.playbackVideoData = data;
  },

  setPlaybackVideoQuality: function (data) {
    this.state.playbackVideoQuality = data;
  },

  setCurrentAudioSegment: function (data) {
    this.state.currentAudioSegment = data;
  },

  setCurrentVideoSegment: function (data) {
    this.state.currentVideoSegment = data;
  },

  setPlayType: function (playType) {
    this.playType = playType;
  },

  setEventView: function (bool) {
    this.eventView = bool;
  },

  captureVideoState: function (state) {
    if (state.originator === 'oneSecondCurrentTime' || state.originator === 'lazyCurrentTime') {
      var currentPlaylistMetadata = this.getMediaMetadata();
      if (!consumptionStore.state.currentAssetObject) {
        console.warn('missing video data from consumption store');
        return;
      }

      var chosenTimer = (consumptionStore.state.isLinear || currentPlaylistMetadata.assetStatusCode === 'live') ? this.state.linearTicks : state.data.currentTime;

      if (state.data.currentSrc !== this.state.currentSrc) {
        this.state.currentSrc = state.data.currentSrc;
        this.state.metadata = currentPlaylistMetadata;
        this.state.initialCurrentTime = state.data.currentTime;
        this.state.newVideoSelected = true;
      } else if ((Math.floor(chosenTimer) === Math.floor(this.state.lastTimeStamp)) && (this.state.lastTimeStamp !== -1)) {
        // skip sub-second time updates
        return;
      }

      if (state.data.isPlaying) {
        // use the first non-zero time stamp to handle live streams
        if (this.state.lastTimeStamp === null && chosenTimer !== 0) {
          if (!this.state.skippedFirstUpdate) {
            /*
             when a linear stream has been playing, and we switch to a VOD,
             the first time update we receive is from where we left off in the previous stream
             instead of the start of the current stream, so we just skip it.
             */
            console.log(`skipping bogus time stamp: ${chosenTimer}`);
            this.state.skippedFirstUpdate = true;
            return;
          }

          this.state.lastTimeStamp = chosenTimer;
          this.state.totalTimeViewed = chosenTimer;
          console.log(`setting initial time stamp: ${this.state.lastTimeStamp}`);
        }

        let assetType;
        if (currentPlaylistMetadata.assetStatusCode === 'live') {
          assetType = 'live';
        } else {
          assetType = 'vod';
        }

        // if we have been tracking time, and the time step has jumped more than 5 seconds
        // take it as an indication that the user ff'd or rw'd
        if (assetType !== 'live' && !consumptionStore.state.isLinear && this.state.lastTimeStamp !== null && Math.abs(this.state.lastTimeStamp - state.data.currentTime) > 5) {
          // reset the last timestep (so we can maintain an accurate totalTimeViewed)
          console.log('tracking: jump in time detected, resetting lastTimeStamp');
          this.state.lastTimeStamp = null;
          this.state.oneSecondTicks = 0;
          this.state.lastCurrentTime = state.data.currentTime;
          this.state.totalTimeViewed = state.data.currentTime;
        }

        if (// we have previous time step
          // and time is moving forward
          chosenTimer > this.state.lastTimeStamp) {
          if (playlistStore.isLinearPlaylist() && !epgStore.getCurrentVideo()) {
            // Can't track if there's no EPG data
            return;
          }

          if (this.state.lastTimeStamp) this.state.totalTimeViewed += (chosenTimer - this.state.lastTimeStamp);

          let name = (playlistStore.isLinearPlaylist()) ? 'linear' : currentPlaylistMetadata.videoId;
          let videoTitle = (playlistStore.isLinearPlaylist()) ? 'linear' : currentPlaylistMetadata.videoName;
          let length = isNaN(Math.ceil(state.data.duration)) ? 0 : Math.ceil(state.data.duration) / 1000 | 0;
          let listId = currentPlaylistMetadata.listID;
          let pageName = PlatformUtils.getDeviceTrackingPlatform() + '|' + consumptionStore.state.currentAssetObject.id + '|' + consumptionStore.state.currentAssetObject.title;

          // see spreadsheet at: https://jira.redbullmediahouse.com/browse/RBTV-1963
          if (assetType === 'live' || currentPlaylistMetadata.assetType === 'live_program' || !length || playlistStore.isLinearPlaylist()) {
            // video heartbeat

            // media length is -1 for live and linear streams; duration for live replays.
            let mediaLength = currentPlaylistMetadata.isLiveReplay ? length : -1;
            let channel = this.getAssetChannel(currentPlaylistMetadata);

            this.state.paramsGA = {
              id: name,
              mediaLength,
              channel,
              pageName,
              videoTitle,
              absolutePosition: this.state.totalTimeViewed - (PlatformUtils.isPS4 || PlatformUtils.isPS5 ? this.state.initialCurrentTime : 0)
            };
            this.trackBeaconIntervals(state, this.state.paramsGA);
          } else {
            this.state.paramsGA = {
              id: name,
              mediaLength: length,
              channel: assetType,
              listId,
              pageName,
              videoTitle,
              absolutePosition: this.state.totalTimeViewed
            };
            this.trackBeaconIntervals(state, this.state.paramsGA);
            this.trackRelativePercentage(state, this.state.paramsGA);
          }

          if (this.state.newVideoSelected) {
            this.state.newVideoSelected = false;
            let paramsToSend = _.assign({}, this.state.paramsGA);
            paramsToSend.playInitial = true;
            analyticsVideoView(paramsToSend);
          }
          this.state.lastTimeStamp = chosenTimer;
          this.state.currentPlayerState = state.data;
        }
      }
      if (consumptionStore.state.isLinear || currentPlaylistMetadata.assetStatusCode === 'live') {
        // due to performance related issues, we look at the number of ticks the video player has actually ticked
        // and add that value to the linear ticks count

        // Calculate # of actual video time ticks since last pass
        // TODO: lastCurrentTime is already set in trackBeaconInterval, so this comparison always ends up in a 0, and then falls to the 1
        // This current code only works if we only get here roughly once per second. Need to fix this
        var interval = (state.data.currentTime - this.state.lastCurrentTime) || 1;
        // Store last time stamp so we dont fire too often if it doesn't tick a whole number
        this.state.lastTimeStamp = this.state.linearTicks;
        // add the number of actual ticks to our local linear time count
        this.state.linearTicks += interval;
        // store the current video ticks so we ca calculate interval on next pass
        this.state.lastCurrentTime = state.data.currentTime;
      }

      this.trigger(this.state);
    }
  },
  getAssetChannel: function (metadata) {
    let code = metadata.assetStatusCode;
    if (code === 'live') {
      return 'live';
    } else if (playlistStore.isLinearPlaylist()) {
      return 'linear';
    } else {
      return 'vod';
    }
  },

  resetTicksAfterPartialDuration: function () {
    this.state.oneSecondTicks = 0;
  },

  trackRelativePercentage: function (state, params) {
    let pct = (state.data.currentTime / (state.data.duration / 1000)) * 100;
    let pctInt = parseInt(pct, 10);
    if ((pctInt % 10) === 0) {
      if (pctInt !== 100 && pctInt !== 0 && (pctInt !== this.state.lastPercentageTracked)) {
        this.state.lastPercentageTracked = pctInt;
        let paramsToSend = _.assign({}, params);
        paramsToSend.posRelative = true;
        paramsToSend.posRelativeValue = pctInt;
        analyticsVideoView(paramsToSend);
      }
    }
  },

  trackBeaconIntervals: function (state, params) {
    if (!appkitAnalyticsStore.state.isGoogleAnalyticsEnabled) {
      return;
    }
    // Calculate # of actual video time ticks since last pass
    if (this.state.lastCurrentTime === 0) {
      // This is to avoid lastInterval getting broken if a user seeks immediately after video start
      this.state.lastInterval = this.state.totalTimeViewed;
    } else {
      this.state.lastInterval = (state.data.currentTime - this.state.lastCurrentTime) || 1;
    }

    // lastInterval shouldn't differ from the expected interval by more than a few seconds
    if (this.state.lastInterval > 9) {
      // Reset the interval to what we expect it to be - 1 second
      this.state.lastInterval = 1;
    }

    this.state.lastCurrentTime = state.data.currentTime;
    let currentInterval = appkitAnalyticsStore.getCurrentBeaconInterval(params.channel);

    this.state.oneSecondTicks += this.state.lastInterval;

    if (!currentInterval) {
      return;
    }
    if (this.state.oneSecondTicks >= currentInterval) {
      let paramsToSend = _.assign({}, params);
      paramsToSend.posBeacon = true;
      analyticsVideoView(paramsToSend);
      this.state.oneSecondTicks -= currentInterval; // start at whatever we were over the interval for next tick
    }
  },

  _getEpgObject: function () {
    return epgStore.getCurrentVideo();
  },

  _getPlaylistObject: function () {
    if (playlistStore.state.playlistData.items) {
      return (_.filter(playlistStore.state.playlistData.items,
        function (videoObject) {
          return consumptionStore.state.currentAssetID === videoObject.id;
        }))[0];
    } else {
      return null;
    }
  },

  getMediaMetadata: function () {
    try {
      var currentVideoObject = {};
      var isLinear = playlistStore.isLinearPlaylist();

      let assetTitle;

      if (isLinear) {
        // LINEAR
        const currentLinearChannel = playlistStore.getCurrentPlayingChannel();
        let linearChannelId = currentLinearChannel && currentLinearChannel.id || null;
        assetTitle = (linearChannelId === 'linear-borb') ? 'borb' : linearChannelId;
      } else {
        currentVideoObject = this._getPlaylistObject();
        if (!currentVideoObject) return {};
        assetTitle = currentVideoObject.title;
      }

      var videoId = currentVideoObject && currentVideoObject.id;
      var streamType = currentVideoObject && currentVideoObject.type;

      var videoName = currentVideoObject.title || null;
      var videoSubtitle = (currentVideoObject.subline || currentVideoObject.subheading) || null;
      var assetType = currentVideoObject.content_type || null;
      var assetStatusCode = currentVideoObject.status ? currentVideoObject.status.code || null : null;
      currentVideoObject.assetStatusCode = assetStatusCode;
      var isLiveReplay = isLinear ? '' : currentVideoObject.status && currentVideoObject.status.code === 'post';
      var listID = isLinear ? '' : playlistStore.state.id;
      this.state.videoStreamType = this.getAssetChannel(currentVideoObject);

      return {
        videoId: videoId,
        streamType: streamType,
        videoName,
        videoSubtitle: videoSubtitle,
        assetType: assetType,
        assetStatusCode: assetStatusCode,
        isLiveReplay: isLiveReplay,
        listID: listID,
      };
    } catch (ex) {
      console.error('error extracting tracking information: ', ex);
      return {};
    }
  }
});
