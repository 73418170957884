import _ from 'lodash';

let cardSizes = {
  // This is the width/height used to make the Image API request. We often resize later with CSS.
  'featured': {width: 980, height: 430, orientation: ['rbtv_display_art_landscape', 'rbtv_background_landscape'], additionalParams: ['f_jpg', 'c_fill']},
  'featured-small': {width: 312, height: 176, orientation: ['rbtv_display_art_landscape', 'rbtv_background_landscape'], additionalParams: ['f_jpg', 'c_fill']},
  'live': {width: 440, height: 574, orientation: ['rbtv_display_art_portrait', 'rbtv_background_portrait', 'rbtv_cover_art_portrait'], additionalParams: ['f_jpg']},
  'universal': {width: 330, height: 448, orientation: ['rbtv_display_art_portrait', 'rbtv_background_portrait', 'rbtv_cover_art_portrait'], additionalParams: ['f_jpg']},
  'page': {width: 330, height: 448, orientation: ['rbtv_cover_art_portrait', 'rbtv_display_art_portrait', 'rbtv_background_portrait'], additionalParams: ['f_jpg']},
  'stop': {width: 330, height: 448, orientation: ['rbtv_display_art_portrait', 'rbtv_background_portrait'], additionalParams: ['f_jpg']},
  'video': {width: 480, height: 270, orientation: ['rbtv_display_art_landscape', 'rbtv_background_landscape', 'rbtv_cover_art_landscape'], additionalParams: ['f_jpg', 'c_fill']},
  'format': {width: 200, height: 200, orientation: []}, // use an icon instead of the provided image for format cards
  'playlist': {width: 128, height: 128, orientation: ['rbtv_display_art_square'], additionalParams: ['f_jpg']},
  'schedule-item': {width: 128, height: 128, orientation: ['rbtv_display_art_square'], additionalParams: ['f_jpg']},
  'subchannel': {width: 392, height: 272, orientation: ['rbtv_display_art_landscape'], additionalParams: ['f_jpg', 'c_fill']},
  'linear-channel': {width: 292, height: 182, orientation: ['rbtv_background_landscape'], additionalParams: ['f_jpg', 'c_fill']},
  'featuredTitle': {width: 540, height: 90, orientation: ['rbtv_title_treatment_landscape'], additionalParams: ['f_png', 'e_trim', 'c_fit']},
  'pageBg': {width: 1920, height: 1080, orientation: ['rbtv_background_landscape', 'rbtv_display_art_landscape', 'rbtv_background_banner'], additionalParams: ['f_jpg', 'c_fill']},
  'pageBgPs3': {width: 960, height: 540, orientation: ['rbtv_background_landscape', 'rbtv_background_banner'], additionalParams: ['f_jpg', 'c_fill', 'q_92']},
  'pageTitle': {width: 1400, height: null, orientation: ['rbtv_title_treatment_landscape'], additionalParams: ['f_png', 'e_trim', 'c_fit']},
  'sponsorBanner': {width: 450, height: null, orientation: ['rbtv_logo_landscape'], additionalParams: ['f_png', 'e_trim']},
  'sponsorTitle': {width: 200, height: null, orientation: ['rbtv_logo_landscape'], additionalParams: ['f_png', 'e_trim']}
};

export function lookupCardSize (type) {
  if (_.includes(['show', 'page', 'film', 'about', 'event'], type)) {
    return cardSizes['page'];
  } else if (_.includes(['video', 'episode', 'stage', 'event_stream', 'clip', 'event_program', 'live_program', 'live_recap'], type)) {
    return cardSizes['video'];
  }

  return cardSizes[type];
}
