let listeners = [];

window.accessfunction = function (payload) {
  let json = JSON.parse(payload);
  listeners.forEach(fn => {
    fn(json, payload);
  });
};

export function addWebMAFListener (fn) {
  listeners.push(fn);
}

export function removeWebMAFListener (fn) {
  listeners = listeners.filter(it => it !== fn);
}
