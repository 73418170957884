import filter from 'lodash/filter';
import has from 'lodash/has';
import some from 'lodash/some';
import isNull from 'lodash/isNull';
import { s } from '../utils/screen-size';
import conf from '../conf';

const isServus = conf.appNamespace === 'servustv';

let scheduleCardSizes = {
  'xl': 650,
  'large': 650,
  'medium': 650,
  'small': 503
};

export function getScheduleCardWidthForSize (size) {
  size = scheduleCardSizes[size] || scheduleCardSizes['small'];
  return s(size);
}

export default function cardLookup (type, contentType) {
  switch (type) {
    case 'live-servus':
      return 'LiveCard';
    case 'view-more':
      return 'ViewMoreButton';
    case 'mixed':
    case 'stop':
    case 'universal':
      return 'UniversalCard';
    case 'schedule':
      return 'VideoCard';
    case 'featured':
      return 'FeaturedCard';
    case 'featured-small':
      return 'FeaturedCardSmall';
    case 'playlist':
    case 'schedule-item':
      return 'PlaylistCard';
    case 'video':
    case 'videos':
      if (contentType === 'linear-channel') return pageCardLookup(contentType);
      return 'VideoCard';
    case 'page':
    case 'pages':
      return pageCardLookup(contentType);
    case 'linear-channel':
      return 'LinearChannelCard';
    default:
      return null;
  }
}

export function pageCardLookup (contentType) {
  switch (contentType) {
    case 'format':
      return 'FormatCard';
    case 'channel':
    case 'category':
    case 'subchannel':
      return 'ChannelCard';
    default:
      return 'PageCard';
  }
}

export function filterCollections (collections) {
  return filter(collections, function (collection) {
    return has(collection, 'items') && some(collection.items, (item) => {
      let cardType = cardLookup(item.type, item.content_type);
      return !(isNull(cardType));
    });
  });
}

export function lookupCardHorizontalOffset (card) {
  switch (card) {
    case 'FeaturedCard' : return s(980);
    case 'FeaturedCardSmall' : return s(344);
    case 'PageCard' : return s(330);
    case 'UniversalCard' : return s(330);
    case 'VideoCard' : return s(480);
    case 'ChannelCard' : return s(392);
    case 'LinearChannelCard' : return s(292);
    case 'PlaylistCard' :
      if (isServus) {
        return s(684);
      } else {
        return s(480);
      }
    case 'LiveCard' : return s(440);
  }
}

export function lookupCardScrollingValues (card) {
  switch (card) {
    case 'FeaturedCard': return -1;
    case 'FeaturedCardSmall': return 4;
    case 'PageCard': return 4;
    case 'UniversalCard': return 4;
    case 'VideoCard': return 2;
    case 'ChannelCard': return 4;
    case 'LinearChannelCard': return 5;
    case 'PlaylistCard': return 1;
    case 'LiveCard': return 1;
    default: return 1;
  }
}

export function lookupCardHorizontalVisibleItems (cardList) {
  var countList = [];
  Object.keys(cardList).forEach(function (cardId) {
    var cardType = cardList[cardId].constructor.name;
    switch (cardType) {
      case 'FeaturedCard':
        countList.push(4);
        break;
      case 'FeaturedCardSmall':
        countList.push(4);
        break;
      case 'PageCard':
        countList.push(4);
        break;
      case 'UniversalCard':
        countList.push(4);
        break;
      case 'VideoCard':
        countList.push(3);
        break;
      case 'ChannelCard':
        countList.push(4);
        break;
      case 'LinearChannelCard':
        countList.push(5);
        break;
      case 'PlaylistCard':
        countList.push(1);
        break;
      case 'LiveCard':
        countList.push(4);
        break;
      default:
        countList.push(1);
        break;
    }
  }, this);
  return countList.sort((a, b) =>
    countList.filter(v => v === a).length -
        countList.filter(v => v === b).length
  ).pop();
}

export function getTypeForRow ({ rowType = '', itemType = '', items = [], rowTypeUIOverride = '' }) {
  if (rowTypeUIOverride) {
    return cardLookup(rowTypeUIOverride);
  }

  if (rowType !== 'generic') {
    return cardLookup(rowType);
  }

  if (itemType === 'mixed') {
    return 'UniversalCard';
  }

  if (!items || !items.length) return 'NoItemType';

  return cardLookup(items[0].type, items[0].content_type);
}
