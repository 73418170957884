import React from 'react';
import ChannelCard from '../channel-card/channel-card';

export default class LinearChannelCard extends React.Component {
  render () {
    return (
      <ChannelCard
        isLinearChannelCard
        {...this.props}
      />
    );
  }
}
