import getPlatform from './reactv-platforms/index.js';
import {exitActions} from '../reflux/exitStore';
import ErrorReporter from '../utils/error-reporter';
import conf from '../conf';
import spoofed from './spoofed-platform';
import {configDataStore} from '../reflux/configDataStore';
import _ from 'lodash';

const isHTML5 = conf.platform === 'html5';
const isTizen = conf.platform === 'tizen';
const isVIDAA = conf.platform === 'vidaa';
const isOpera = conf.platform === 'opera';
const isPS5 = conf.platform === 'ps5';
const isPS4 = conf.platform === 'ps4';
const isPS3 = conf.platform === 'ps3';
const isXboxOne = conf.platform === 'xboxone';
const isSkyQ = conf.platform === 'skyq';
const isComcast = conf.platform === 'comcast';
const isMetrological = conf.platform === 'metrological';
const isPhilips = conf.platform === 'philips';
const isVestel = conf.platform === 'vestel';
const isMagenta = conf.platform === 'magenta';
const isHBBTV = conf.platform === 'hbbtv';

function isValidLanguageCode (langCode) {
  const langRegex = /^(aa|ab|ae|af|ak|am|an|ar|as|av|ay|az|az|ba|be|bg|bh|bi|bm|bn|bo|br|bs|ca|ce|ch|co|cr|cs|cu|cv|cy|da|de|dv|dz|ee|el|en|eo|es|et|eu|fa|ff|fi|fj|fo|fr|fy|ga|gd|gl|gn|gu|gv|ha|he|hi|ho|hr|ht|hu|hy|hz|ia|id|ie|ig|ii|ik|io|is|it|iu|ja|jv|ka|kg|ki|kj|kk|kl|km|kn|ko|kr|ks|ku|kv|kw|ky|la|lb|lg|li|ln|lo|lt|lu|lv|mg|mh|mi|mk|ml|mn|mr|ms|mt|my|na|nb|nd|ne|ng|nl|nn|no|nr|nv|ny|oc|oj|om|or|os|pa|pi|pl|ps|pt|qu|rm|rn|ro|ru|rw|sa|sc|sd|se|sg|si|sk|sl|sm|sn|so|sq|sr|ss|st|su|sv|sw|ta|te|tg|th|ti|tk|tl|tn|to|tr|ts|tt|tw|ty|ug|uk|ur|uz|ve|vi|vo|wa|wo|xh|yi|yo|za|zh|zu)$/i;
  return langCode.match(langRegex);
}

function videoCanPlayType () {
  try {
    return !!document.createElement('video').canPlayType;
  } catch (e) {
    return false;
  }
}

const forcedPlatform = spoofed || undefined;

var PlatformUtils = {

  // All components should access reactv-platforms via this sharedPlatform variable
  sharedPlatform: getPlatform(forcedPlatform),
  APP_FALLBACK_LANGUAGE: conf.appNamespace === 'servustv' ? 'ger' : 'eng',
  OPERA: 'opera',
  VIDAA: 'vidaa',
  TIZEN: 'tizen',
  NETCAST: 'netcast',
  HTML5: 'html5',
  XBOXONE: 'xboxone',
  SKYQ: 'skyq',
  PS5: 'ps5',
  PS4: 'ps4',
  PS3: 'ps3',
  COMCAST: 'comcast',
  METROLOGICAL: 'metrological',
  VESTEL: 'vestel',
  MAGENTA: 'magenta',
  PHILIPS: 'philips',
  HBBTV: 'hbbtv',
  FIRMWARE: '2',
  isHTML5,
  isTizen: (spoofed === 'tizen') || (!spoofed && isTizen),
  isOpera: (spoofed === 'opera') || (!spoofed && isOpera),
  isVIDAA: (spoofed === 'vidaa') || (!spoofed && isVIDAA),
  isComcast: (spoofed === 'comcast') || (!spoofed && isComcast),
  isMetrological: (spoofed === 'metrological') || (!spoofed && isMetrological),
  isPhilips: (spoofed === 'philips') || (!spoofed && isPhilips),
  isVestel: (spoofed === 'vestel') || (!spoofed && isVestel),
  isMagenta: (spoofed === 'magenta') || (!spoofed && isMagenta),
  isHBBTV: (spoofed === 'hbbtv') || (!spoofed && isHBBTV),
  isPS5: (spoofed === 'ps5') || (!spoofed && isPS5),
  isPS4: (spoofed === 'ps4') || (!spoofed && isPS4),
  isPS3: (spoofed === 'ps3') || (!spoofed && isPS3),
  isXboxOne: (spoofed === 'xboxone') || (!spoofed && isXboxOne),
  isSkyQ: (spoofed === 'skyq') || (!spoofed && isSkyQ),
  supportHTML5Video: videoCanPlayType(),

  init: function (callback) {
    this.sharedPlatform.init(callback);
  },

  exit: function (resume) {
    exitActions.exitApp();
    this.sharedPlatform.exit(resume);
  },

  exitAndClearLocalStorage: function (resume) {
    exitActions.exitApp();
    let storage = this.sharedPlatform.localStorage();
    if (storage && storage.clear) {
      storage.clear();
    }
    this.sharedPlatform.exit(resume);
  },

  getDeviceLanguage: function () {
    let deviceLanguage;
    if (conf.testBuild && this.sharedPlatform.localStorage().getItem('rbtv:debug:testLanguage')) {
      deviceLanguage = this.sharedPlatform.localStorage().getItem('rbtv:debug:testLanguage');
    } else {
      deviceLanguage = this.sharedPlatform.getDeviceLanguage();
    }
    return deviceLanguage && deviceLanguage.replace('_', '-');
  },

  getModifiedDeviceLanguage: function () {
    let deviceLanguage;
    if (conf.testBuild && this.sharedPlatform.localStorage().getItem('rbtv:debug:testLanguage')) {
      deviceLanguage = this.sharedPlatform.localStorage().getItem('rbtv:debug:testLanguage');
    } else {
      deviceLanguage = this.sharedPlatform.getDeviceLanguage();
      deviceLanguage = deviceLanguage && deviceLanguage.replace('_', '-');
    }

    if (deviceLanguage && deviceLanguage.indexOf('-') !== -1) {
      deviceLanguage = deviceLanguage.split('-')[0];
    }

    deviceLanguage = (deviceLanguage || '').trim().toLowerCase();

    return isValidLanguageCode(deviceLanguage) ? deviceLanguage : this.APP_FALLBACK_LANGUAGE; // Default to English if navigator.language is invalid
  },

  getPlatformID: function () {
    return conf.platform;
  },

  getDeviceTrackingPlatform: function () {
    if (conf.deviceTrackingID) {
      return conf.deviceTrackingID;
    }
    var pf = 'unknowntv';
    switch (this.getPlatformID()) {
      case this.TIZEN:
        pf = 'samsungtv';
        break;
      case this.OPERA:
        pf = 'operatv';
        break;
      case this.VIDAA:
        pf = 'vidaatv';
        break;
      case this.COMCAST:
        pf = 'comcasttv';
        break;
      case this.METROLOGICAL:
        pf = 'metrological';
        break;
      case this.PHILIPS:
        pf = 'philips';
        break;
      case this.VESTEL:
        pf = 'vestel';
        break;
      case this.MAGENTA:
        pf = 'magenta';
        break;
      case this.HTML5:
        pf = 'html5tv';
        break;
      case this.XBOXONE:
        pf = 'xboxone';
        break;
      case this.SKYQ:
        pf = 'skyq';
        break;
      case this.PS5:
        pf = 'playstation5';
        break;
      case this.PS4:
        pf = 'playstation4';
        break;
      case this.PS3:
        pf = 'playstation3';
        break;
      case this.HBBTV:
        pf = 'hbbtv';
        break;
    }
    return pf;
  },

  getModel: function () {
    if (this.isTizen) {
      var model;
      try {
        model = window.webapis.productinfo.getModel();
      } catch (e) {
        ErrorReporter.captureException(e, 'ERROR GETTING MODEL');
      }
      return model;
    } else {
      return this.getPlatformID();
    }
  },

  platformFPS: function (platform) {
    switch (platform) {
      case PlatformUtils.OPERA:
        return 'low';
      case PlatformUtils.VIDAA:
        return 'low';
      case PlatformUtils.COMCAST:
        return 'high';
      case PlatformUtils.METROLOGICAL:
        return 'high';
      case PlatformUtils.TIZEN:
        return 'high';
      case PlatformUtils.SKYQ:
        return 'low';
      case PlatformUtils.HTML5:
        return 'high';
      case PlatformUtils.XBOXONE:
        return 'high';
      case PlatformUtils.PS5:
        return 'high';
      case PlatformUtils.PS4:
        return 'high';
      case PlatformUtils.PS3:
        return 'low';
      case PlatformUtils.HBBTV:
        return 'high';
      default:
        return 'low';
    }
  },

  supportVideoPreviews: function () {
    let bool;
    const platform = conf.platform;
    switch (platform) {
      case PlatformUtils.TIZEN:
        bool = PlatformUtils.sharedPlatform?.tizenVersion > 2;
        break;
      case PlatformUtils.HTML5:
      case PlatformUtils.MAGENTA:
      // case PlatformUtils.XBOXONE: // TODO: disabled due to RBMN-37390, needs to be investigated
      case PlatformUtils.PS4:
      case PlatformUtils.PS5:
        bool = true;
        break;
      default:
        bool = false;
    }

    return bool ? PlatformUtils.supportHTML5Video : false;
  },

  supportCardVideoPreviews: function () {
    if (!conf.enableCardVideoPreview) {
      return false;
    }

    return PlatformUtils.supportVideoPreviews();
  },

  isPlatformHighFPS: function () {
    return this.platformFPS(this.getPlatformID()) === 'high';
  },

  convertISO2CodeToISO1: function (languageCode) {
    if (!languageCode) {
      return null;
    }
    // Convert ISO-639-2T into ISO-639-1
    // Device usually reports language codes in ISO-639-1, we receive Caption and Audio language codes in a mixture of ISO-639-2B/2T
    const appLanguageConfig = configDataStore.getConstant('languages') || [];
    const appLanguage = _.find(appLanguageConfig, (langItem) => { return (langItem['iso-639-2B'] === languageCode || langItem['iso-639-2T'] === languageCode); });
    if (appLanguage) {
      return appLanguage['iso-639-1'];
    } else {
      console.warn('Unable to convert language code: ', languageCode);
      return languageCode;
    }
  },

  doesNotSupportDASH: function () {
    return (PlatformUtils.isHTML5 || PlatformUtils.isXboxOne || PlatformUtils.isSkyQ);
  },

  getDefaultLinearID () {
    return conf.appNamespace === 'servustv' ? 'stv-linear' : 'linear-borb';
  },

  supportsWebSockets: function () {
    return !(PlatformUtils.isPS4 || PlatformUtils.isXboxOne || PlatformUtils.isPhilips);
  },

  needsCustomPlaybackForGoogleIMA: function () {
    return (PlatformUtils.isPS4 || PlatformUtils.isOpera);
  },

  isMultiLinearDisabled: function () {
    if (PlatformUtils.isVIDAA && conf.disableMultiLinear) {
      const uA = navigator.userAgent;
      if (uA.includes('Odin') && (uA.includes('9602') || uA.includes('72671'))) {
        return false;
      } else {
        return true;
      }
    } else {
      return conf.disableMultiLinear;
    }
  },

  getVideoStyle: function () {
    if (PlatformUtils.isPS4 || PlatformUtils.isPS3 || PlatformUtils.isHBBTV) return {};

    return { background: conf.appNamespace === 'servustv' ? 'rgba(225, 226, 228, 0.7)' : 'rgba(18, 18, 18, 0.7)' };
  }

};

export default PlatformUtils;
