import conf from '../conf';
Object.defineProperty(exports, '__esModule', {
  value: true
});
exports['default'] = keymapper;

function keymapper () {
  const defaultMapping = {
    36: 'Return',
    38: 'Up',
    40: 'Down',
    37: 'Left',
    39: 'Right',
    13: 'Enter',
    65: 'A',
    66: 'B',
    67: 'C',
    68: 'D',
    8: 'Back',
    27: 'Back',
    179: 'Play',
    227: 'FastForward',
    228: 'Rewind',
    461: 'Back', // Vestel
    // Debug
    48: '0',
    49: '1',
    50: '2',
    51: '3',
    52: '4',
    53: '5',
    54: '6',
    55: '7',
    56: '8',
    57: '9',
    80: 'P',
    81: 'Q',
    87: 'W',
    86: 'V'
  };

  const magentaMapping = {
    36: 'Return',
    38: 'Up',
    40: 'Down',
    37: 'Left',
    39: 'Right',
    13: 'Enter',
    463: 'PlayPause',
    417: 'FastForward',
    412: 'Rewind',
    413: 'Stop',
    461: 'Back',
    8: 'Back',
    // Debug
    57: '9'
  };

  const tizenMapping = {
    38: 'Up',
    40: 'Down',
    37: 'Left',
    39: 'Right',
    13: 'Enter',
    403: 'A',
    404: 'B',
    405: 'C',
    406: 'D',
    10009: 'Back',
    415: 'Play',
    417: 'FastForward',
    412: 'Rewind',
    413: 'Stop',
    10252: 'PlayPause',
    19: 'Pause',
    // Debug
    57: '9'
  };

  const windowsMapping = {
    196: 'Back',
    38: 'Up',
    211: 'Up',
    203: 'Up',
    138: 'Up',
    40: 'Down',
    212: 'Down',
    204: 'Down',
    139: 'Down',
    37: 'Left',
    214: 'Left',
    205: 'Left',
    140: 'Left',
    39: 'Right',
    213: 'Right',
    206: 'Right',
    141: 'Right',
    142: 'Enter',
    195: 'Enter',
    13: 'Enter',
    8: 'Back',
    199: 'Next',
    200: 'Previous',
    201: 'LeftTrigger',
    202: 'RightTrigger',
    413: 'Stop',
    417: 'FastForward',
    412: 'Rewind',
    415: 'Play',
    19: 'Pause'
  };

  const operaMapping = {
    [window.VK_UP || 38]: 'Up',
    [window.VK_DOWN || 40]: 'Down',
    [window.VK_LEFT || 37]: 'Left',
    [window.VK_RIGHT || 39]: 'Right',
    [window.VK_ENTER || 13]: 'Enter',
    [window.VK_BACK_SPACE || 8]: 'Back',
    [window.VK_BACK || 8]: 'Back',
    [window.VK_BACK_SPACE || 27]: 'Back',
    27: 'Back',
    [window.VK_BACK || 27]: 'Back',
    [window.VK_PLAY || 81]: 'Play',
    [window.VK_PLAY_PAUSE || 463]: 'PlayPause',
    [window.VK_PAUSE || 10]: 'Pause',
    [window.VK_FAST_FWD || 73]: 'FastForward',
    [window.VK_REWIND || 89]: 'Rewind',
    [window.VK_STOP || 82]: 'Stop',
    57: '9'
  };

  const vidaaMapping = {
    [window.VK_UP || 38]: 'Up',
    [window.VK_DOWN || 40]: 'Down',
    [window.VK_LEFT || 37]: 'Left',
    [window.VK_RIGHT || 39]: 'Right',
    [window.VK_ENTER || 13]: 'Enter',
    [window.VK_BACK_SPACE || 8]: 'Back',
    [window.VK_BACK || 8]: 'Back',
    [window.VK_PLAY || 415]: 'Play',
    [window.VK_PAUSE || 19]: 'Pause',
    [window.VK_PLAY_PAUSE || 463]: 'PlayPause',
    [window.VK_FAST_FWD || 417]: 'FastForward',
    [window.VK_REWIND || 412]: 'Rewind',
    [window.VK_STOP || 413]: 'Stop',
    57: '9'
  };

  const vestelMapping = {
    [window.VK_UP || 38]: 'Up',
    [window.VK_DOWN || 40]: 'Down',
    [window.VK_LEFT || 37]: 'Left',
    [window.VK_RIGHT || 39]: 'Right',
    [window.VK_ENTER || 13]: 'Enter',
    461: 'Back',
    27: 'Back',
    8: 'Back',
    [window.VK_PLAY || 415]: 'Play',
    [window.VK_PAUSE || 19]: 'Pause',
    [window.VK_PLAY_PAUSE || 463]: 'PlayPause',
    [window.VK_FAST_FWD || 417]: 'FastForward',
    [window.VK_REWIND || 412]: 'Rewind',
    [window.VK_STOP || 413]: 'Stop',
    57: '9'
  };

  const skyMapping = {
    37: 'Left',
    39: 'Right',
    38: 'Up',
    40: 'Down',
    13: 'Enter',
    27: 'Back',
    179: 'PlayPause',
    228: 'FastForward',
    227: 'Rewind',
    57: 'V'
  };

  const playstationMapping = {
    8: 'Back', // Circle
    27: 'Back', // Circle on PS5
    38: 'Up',
    37: 'Left',
    39: 'Right',
    40: 'Down',
    13: 'Enter', // Cross
    116: 'PreviousVideo', // L1
    117: 'NextVideo', // R1
    118: 'Rewind', // L2
    119: 'FastForward', // R2

    // BD Remote & CEC
    127: 'Rewind',
    133: 'Rewind', // PS5
    126: 'FastForward',
    134: 'FastForward', // PS5
    128: 'Play',
    122: 'PreviousVideo',
    123: 'NextVideo',
    129: 'Stop',
    125: 'SlowRewind',
    124: 'SlowFastForward',
    130: 'Pause',
    217: 'PlayPause' // PS5
  };

  const hbbtvMapping = {
    36: 'Return',
    38: 'Up',
    40: 'Down',
    37: 'Left',
    39: 'Right',
    13: 'Enter',
    65: 'A',
    66: 'B',
    67: 'C',
    68: 'D',
    8: 'Back',
    12: 'Back', // Ocilion Setup Box
    27: 'Back',
    179: 'Play',
    227: 'FastForward',
    228: 'Rewind',
    461: 'Back', // Vestel
    [window.VK_PLAY_PAUSE || 32]: 'PlayPause',
    [window.VK_PLAY || 80 || 415]: 'Play',
    [window.VK_PAUSE || 72 || 19]: 'Pause'
  };

  if (window.swapPlaystationButtonsForJapan) {
    // swap cross and circle buttons
    playstationMapping[8] = 'Enter'; // Circle
    playstationMapping[13] = 'Back'; // Cross
  }

  let mapping = defaultMapping;
  if (conf.platform === 'tizen') {
    mapping = tizenMapping;
  } else if (conf.platform === 'vidaa' || conf.platform === 'philips') {
    mapping = vidaaMapping;
  } else if (conf.platform === 'vestel') {
    mapping = vestelMapping;
  } else if (conf.platform === 'hbbtv') {
    mapping = hbbtvMapping;
  } else if (conf.platform === 'opera') {
    mapping = operaMapping;
  } else if (conf.platform === 'skyq') {
    mapping = skyMapping;
  } else if (conf.platform === 'ps5' || conf.platform === 'ps4' || conf.platform === 'ps3') {
    mapping = playstationMapping;
  } else if (conf.platform === 'xboxone') {
    mapping = windowsMapping;
  } else if (conf.platform === 'magenta') {
    mapping = magentaMapping;
  } else {
    mapping = defaultMapping;
  }

  return mapping;
}

module.exports = exports['default'];
