import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import {playerControlsStore} from '../../reflux/playerControlsStore';

export default class CardProgressBar extends Reflux.Component {
  constructor (props) {
    super(props);
    this.stores = [playerControlsStore];
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (this.props.focused !== nextProps.focused) || (this.props.progress !== nextProps.progress);
  }

  render () {
    if (this.props.progress === 0) {
      return null;
    }

    const progressBarStyles = { 'width': this.props.progress + '%' };

    return (
      <div className={cx('card-progress-bar')}>
        <div className={cx('card-progress-bar__inner', {'is-focused': (this.props.focused)})} style={progressBarStyles} />
      </div>
    );
  }
}
