import React from 'react';
import Reflux from 'reflux';
import {consumptionStore} from '../../reflux/consumptionStore';
import {localizationStore} from '../../reflux/localizationStore';
import conf from '../../conf';
import {playlistStore} from '../../reflux/playlistStore';
import PageButtons from '../../components/page-buttons/page-buttons';
import Switcher from '../../utils/menu-switch.js';
import mix from 'react-mixin';
import _ from 'lodash';
import CardMenu from '../../components/card-menu/card-menu';
import {fechaFormat} from '../../utils/date-formatter.js';
import {epgStore} from '../../reflux/epgStore';

@mix.decorate(Switcher)
export default class HomeContextualModule extends Reflux.Component {
  constructor (p) {
    super(p);
    this.stores = [consumptionStore];

    this.state = {
      focused: 'watchNowBtn',
      heroListIdx: 0
    };

    this.setFocusOnFullHeroCards = this.setFocusOnFullHeroCards.bind(this);
    this.setFocusOnPlayButton = this.setFocusOnPlayButton.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  setFocusOnFullHeroCards () {
    if (this.props.collection?.items?.length) {
      this.setState({ focused: 'homeContextualModule-events' });
    }
  }

  setFocusOnPlayButton () {
    this.setState({focused: 'watchNowBtn'});
  }

  onEnter (item, idx) {
    const { collection } = this.props;
    collection.selectedId = item.id;
    collection.positionIndex = idx;
    item.positionIndex = idx;
    this.props.onEnter(item, collection, true);
  }

  render () {
    const isServus = conf.appNamespace === 'servustv';

    const {currentAssetObject} = consumptionStore.state;

    let nowPlayingText = localizationStore._GET('now_playing');

    if (playlistStore.isLinearPlaylist() && playlistStore.getCurrentPlayingChannel() &&
      playlistStore.getCurrentPlayingChannel().title !== undefined) {
      nowPlayingText = localizationStore._GET('now_playing_on_channel', playlistStore.getCurrentPlayingChannel().title);
    }

    let epgStartTime = null;
    if (isServus) {
      const epgCards = epgStore.getCards();
      epgStartTime = epgCards && epgCards.length && epgCards[0].start_time;
    }
    const startTime = epgStartTime && fechaFormat(new Date(epgStartTime), 'HH:mm');

    return (
      <div className="homeContextualModuleWrapper">
        <div className="homeContextualModule" style={{opacity: this.state.heroListIdx ? '0' : '1'}}>
          <div className="homeContextualModule-heading"><div><span className="pulse"/><span>{isServus ? ` JETZT LIVE BEI SERVUS TV${startTime ? ' | seit ' + startTime : ''}` : ` ${nowPlayingText}`}</span></div></div>
          <div className="homeContextualModule-title">{_.has(currentAssetObject, 'title') ? currentAssetObject.title : localizationStore._GET('red_bull_tv')}</div>
          <div className="homeContextualModule-description">{_.has(currentAssetObject, 'short_description') ? _.truncate(currentAssetObject.short_description, {
            'length': 250,
            'separator': ' '
          }) : ''}</div>
          <PageButtons
            focused={this.props.focused && this.isFocused('watchNowBtn')}
            isLive={true}
            menuItems={[{label: (playlistStore.isLive() || playlistStore.isLinearPlaylist()) ? localizationStore._GET('watch_live') : localizationStore._GET('cont'), icon: 'play', action: 'play'}]}
            onEnter={this.props.onPlayButtonEnter}
            onDown={this.props.onDown}
            onRight={this.setFocusOnFullHeroCards}
            onUp={this.props.onUp}
            iTrackingRow={1}
          />
        </div>
        {this.props.collection?.items?.length
          ? (<div className="homeContextualModule-events">
            <CardMenu
              id={this.props.collection.id}
              onEnter={this.onEnter}
              noHeader={true}
              focused={this.props.focused && this.isFocused('homeContextualModule-events')}
              row={this.props.collection}
              menuItems={this.props.collection.items}
              onLeft={this.setFocusOnPlayButton}
              onUp={this.props.onUp}
              onDown={this.props.onDown}
              onChange={(idx) => { this.setState({heroListIdx: idx}); }}
              type='live-servus'
              additionalOffsetIdx={2}
            />
          </div>) : null
        }
      </div>
    );
  }
}
