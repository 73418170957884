import React, {Suspense} from 'react';
import { default as cx } from 'classnames';
import PlatformUtils from '../../utils/platform.js';
import Reflux from 'reflux';
import MainMenu from '../../components/menu/menu';
import deeplinkService from '../../services/deeplinkService.js';
import {Navigation as Nav} from '../../utils/reactv-navigation';
import {homeStore, homeActions} from '../../reflux/homeStore';
import {videoPlayerActions, videoPlayerStore} from '../../reflux/videoPlayerStore';
import {consumptionStore, consumptionActions} from '../../reflux/consumptionStore';
import {playlistStore, playlistActions} from '../../reflux/playlistStore';
import {configDataStore} from '../../reflux/configDataStore';
import {filterCollections} from '../../config/cardLookup.js';
import {dialogStore, dialogActions} from '../../reflux/dialogStore';
import {startStore, startActions} from '../../reflux/startStore';
import {hintsActions} from '../../reflux/hintsStore';
import {historyActions, historyStore} from '../../reflux/historyStore';
import {listPageActions} from '../../reflux/listPageStore';
import {epgStore} from '../../reflux/epgStore';
import {gridPageActions} from '../../reflux/gridPageStore';
import {userAccountActions, userAccountStore} from '../../reflux/userAccountStore';
import {liveChannelsActions} from '../../reflux/liveChannelsStore';
import {iTrackingActions} from '../../reflux/iTrackingStore';
import {videoAnalyticsStore as videoViewTrackingStore} from '../../reflux/videoViewTrackingStore';
import ListPanel from '../../components/list-panel/list-panel';
import HomeContextualModule from '../../components/home/home-contextual-module';
import HomeFeaturedCarouselModule from '../../components/home/home-featured-carousel-module';
import GridPage from '../../components/grid-page/grid-page';
import join from '../../utils/join.js';
import {localizationStore} from '../../reflux/localizationStore';
import {analyticsVideoView, analyticsScreenView} from '../../reflux/appkitAnalyticsStore';
import {recentlyWatchedStore} from '../../reflux/recentlyWatchedStore';
import {paginationStore} from '../../reflux/paginationStore';
import _ from 'lodash';
import Switcher from '../../utils/menu-switch.js';
import mix from 'react-mixin';
import { scheduleActions } from '../../reflux/scheduleStore';
import { searchActions } from '../../reflux/searchStore';
import conf from '../../conf';

const LandingPage = React.lazy(() => import('../../components/landing-page/landing-page'));
const Search = React.lazy(() => import('../../components/search/search'));
const Settings = React.lazy(() => import('../../components/settings/settings'));

const isServus = conf.appNamespace === 'servustv';

const HAS_10FT_HEADER = conf.enable10ftHeader === true;
const ALWAYS_RESUME_LINEAR_ON_HOME = !HAS_10FT_HEADER && isServus;

/* global __hbb_tracking_tgt: true */

@mix.decorate(Switcher)
export default class Home extends Reflux.Component {
  constructor (p) {
    super(p);
    this.stores = [userAccountStore, homeStore];

    this.mapStoreToState(consumptionStore, function (fromStore) {
      var obj = {
        currentAssetObject: fromStore.currentAssetObject
      };
      return obj;
    });

    this.mapStoreToState(dialogStore, function (fromStore) {
      var obj = {
        signInPrompt: fromStore.signInPrompt,
        sunsetMessage: fromStore.sunsetMessage,
        privacyConsentPrompt: fromStore.privacyConsentPrompt,
        backExitScreen: fromStore.backExitScreen
      };
      return obj;
    });

    this.mapStoreToState(videoPlayerStore, function (fromStore) {
      var obj = {
        isPlaying: fromStore.isPlaying,
        PAUSED_BY_APP: fromStore.PAUSED_BY_APP
      };
      return obj;
    });

    this.mapStoreToState(startStore, function (fromStore) {
      var obj = {
        isFirstPlayEventFinished: fromStore.isFirstPlayEventFinished
      };
      return obj;
    });

    this.mapStoreToState(epgStore, function (fromStore) {
      var obj = {currentLinearChannel: fromStore.currentLinearChannel};
      return obj;
    });

    this.state = {
      resetList: false,
      focused: this.getFocusState(true),
      liveChannels: epgStore.state.linearChannels,
      previousVolume: null,
      viewMoreIndex: null,
      autoPlay: false
    };

    this.isPage = this.isPage.bind(this);
    this.onBack = this.onBack.bind(this);
    this.setIndexViewMore = this.setIndexViewMore.bind(this);
    this.getContentFromList = this.getContentFromList.bind(this);
    this.loadConsumption = this.loadConsumption.bind(this);
    this.getFocusState = this.getFocusState.bind(this);
    this.setFocusOnContentSection = this.setFocusOnContentSection.bind(this);
    this.handlePlayButton = this.handlePlayButton.bind(this);
    this.loadVideoFromCollection = this.loadVideoFromCollection.bind(this);
    this.handleLinearPlayback = this.handleLinearPlayback.bind(this);
    this.handleLivePlayback = this.handleLivePlayback.bind(this);
    this.changeSection = this.changeSection.bind(this);
    this.navigateToNewPage = this.navigateToNewPage.bind(this);
    this.loadPageData = this.loadPageData.bind(this);
    this.onListPanelChange = this.onListPanelChange.bind(this);
    this.onFeaturedCardFocus = this.onFeaturedCardFocus.bind(this);
    this.setFocusOnMainMenu = this.setFocusOnMainMenu.bind(this);
    this.setFocusOnHomeContextualModule = this.setFocusOnHomeContextualModule.bind(this);
    this.downFromMainMenu = this.downFromMainMenu.bind(this);
    this.fadeOutHome = this.fadeOutHome.bind(this);
    this.setupFadeOutHomeTimer = this.setupFadeOutHomeTimer.bind(this);
    this.registerUserKeyPress = this.registerUserKeyPress.bind(this);
    this.autoPauseCurrentVideo = this.autoPauseCurrentVideo.bind(this);

    this.state.liveChannels.selectedId = 0;

    dialogActions.showBufferingScreen();
  }

  isPage (props) {
    if (!props) {
      props = this.props;
    }
    let {params} = props;
    return (params.view && params.view === 'page');
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)) || (this.props.FORCE_UPDATE === true);
  }

  componentDidMount () {
    let {params} = this.props;
    if (this.isPage()) {
      this.loadPageData(params.id);
    } else {
      this.trackView();
      dialogActions.hideBufferingScreen();
    }

    if (!(params && params.view && params.view === 'account')) {
      // Account has its own back that it subscribes on mount
      historyStore.state.homeBackBinding = Nav.subscribeToKey('Back', this.onBack);
    }

    document.addEventListener('keydown', this.registerUserKeyPress);

    consumptionActions.showEmbededHLSCaptions(false);

    // Turn down volume and show overlay if we go back to Home's content section
    if (this.state.focused === 'contentSection' && this.state.currentMenuSection === 'home') {
      const currentVolume = PlatformUtils.sharedPlatform.getVolume();
      this.setState({ previousVolume: currentVolume });
      homeActions.showOverlay(true);
      PlatformUtils.sharedPlatform.setVolume(currentVolume * 0.3, currentVolume);
    } else {
      homeActions.showOverlay(false);
    }

    // Pause video in all sections but home
    if (((this.state.currentMenuSection !== 'home' || this.props.params.view === 'account') || this.isPage() || this.props.params.view === 'viewMore')) {
      this.setState({
        focused: 'contentSection'
      });

      this.autoPauseCurrentVideo();
    } else if (ALWAYS_RESUME_LINEAR_ON_HOME) {
      console.log('home.jsx - ALWAYS_RESUME_LINEAR_ON_HOME');
      playlistActions.playLinearChannel();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.isFirstPlayEventFinished && this.state.isFirstPlayEventFinished) {
      if (deeplinkService.getDeeplinkContent() === null) this.setupFadeOutHomeTimer();
    }

    if (!prevState.forceHomeFocusOn !== this.state.forceHomeFocusOn &&
      this.state.forceHomeFocusOn &&
      (
        this.state.forceHomeFocusOn === 'contentSection' ||
        this.state.forceHomeFocusOn === 'homeContextualModule' ||
        this.state.forceHomeFocusOn === 'mainMenu'
      )
    ) {
      this.setState({
        focused: this.state.forceHomeFocusOn
      });
      homeActions.forceHomeFocusOn(false);
    }

    if (this.isPage(this.props) && this.props.params.id !== prevProps.params.id) {
      this.loadPageData(this.props.params.id);
      if (!this.isPage(this.props)) this.setState({lastSection: prevProps.params.view});
    }

    if (!prevState.FORCE_LOAD_CONSUMPTION && this.state.FORCE_LOAD_CONSUMPTION) {
      this.loadConsumption();
    }

    if (!prevState.resetList && this.state.resetList) {
      this.setState({ resetList: false });
    }
    // Track a view that is not a page. We can do this on change becauase we're not waiting for data
    if (this.props.params.view !== prevProps.params.view && !this.isPage()) {
      this.trackView();
    }

    if ((prevProps.params.view === 'account' && this.props.params.view !== 'account')) {
      historyStore.state.homeBackBinding = Nav.subscribeToKey('Back', this.onBack);
      if (historyStore.state.forceNextGoBack) {
        historyStore.state.forceNextGoBack = false;
      }
    }

    if (prevState.backExitScreen && !this.state.backExitScreen && historyStore.state.homeBackBinding.unsubscribe) {
      historyStore.state.homeBackBinding = Nav.subscribeToKey('Back', this.onBack);
    }

    if (this.state.loadingPage && !prevState.loadingPage) {
      dialogActions.showBufferingScreen();
    } else if (!this.state.loadingPage && prevState.loadingPage) {
      dialogActions.hideBufferingScreen();
    }

    if ((!prevState.currentSection && this.state.currentSection) || (this.props.params.view !== prevProps.params.view && (this.props.params.view === 'channels' || this.props.params.view === 'viewMore'))) {
      this.setState({
        focused: 'contentSection'
      });
    }

    if (!_.isEqual(prevProps.locationQuery, this.props.locationQuery) || prevState.page !== this.state.page) {
      if (_.isEmpty(this.state.page) && this.props.params.view !== 'viewMore' && this.props.params.view !== 'account') {
        this.setState({
          focused: this.getFocusState()
        });
      }
    }

    if (_.isEmpty(prevProps.locationQuery) && !_.isEmpty(this.props.locationQuery)) {
      const focusState = this.getFocusState();
      if (this.state.focused === 'homeContextualModule' && focusState !== 'homeContextualModule') {
        this.setState({
          focused: focusState
        });
      }
    }

    if (isServus) {
      if (this.state.isOverlayVisible && !videoPlayerStore.state.PAUSED_BY_APP) {
        this.autoPauseCurrentVideo();
      }

      if (this.state.PAUSED_BY_APP && !this.state.isOverlayVisible && this.state.autoPlay) {
        this.autoPlayCurrentVideo();
      }
    } else {
      if (this.state.focused === 'contentSection') {
        this.autoPauseCurrentVideo();
      }

      if (this.state.PAUSED_BY_APP && this.state.focused === 'homeContextualModule' && this.state.autoPlay) {
        this.autoPlayCurrentVideo();
      }
    }

    // Pause video in all sections but home - this function only checks when navigating from and to pages
    // Pause/Play between sections occurs in changeSection method
    if (prevProps.params.view !== this.props.params.view) {
      if (this.isPage()) { // Pause if we navigate to a page
        this.autoPauseCurrentVideo();
      } else if (!this.props.params.view && this.state.PAUSED_BY_APP && !HAS_10FT_HEADER) { // Back at home when coming from a page, since componentDidMount won't run again
        this.autoPlayCurrentVideo();
      }
    }
  }

  componentWillUnmount () {
    super.componentWillUnmount();
    if (historyStore.state && historyStore.state.homeBackBinding) {
      historyStore.state.homeBackBinding.unsubscribe();
    }

    document.removeEventListener('keydown', this.registerUserKeyPress);

    // Only restore the volume if it was changed by Home
    if (this.state.previousVolume) {
      PlatformUtils.sharedPlatform.setVolume(this.state.previousVolume);
    }

    this.loadVideoFromCollectionTimeout && clearTimeout(this.loadVideoFromCollectionTimeout);
    this.autoPlayCurrentVideoTimeout && clearTimeout(this.autoPlayCurrentVideoTimeout);
  }

  autoPlayCurrentVideo () {
    console.log('home.jsx - autoPlayCurrentVideo');
    clearTimeout(this.autoPlayCurrentVideoTimeout);
    videoViewTrackingStore.setPlayType('auto');
    if (playlistStore.isLinearPlaylist()) {
      videoPlayerActions.forceRefreshLinear();
    } else {
      this.autoPlayCurrentVideoTimeout = setTimeout(() => { videoPlayerActions.playVideo(); }, 100);
    }
    analyticsVideoView('playVideo');
    videoPlayerActions.setPausedByApp(false);
  }

  autoPauseCurrentVideo () {
    if (this.state.PAUSED_BY_APP) return;
    console.log('home.jsx - autoPauseCurrentVideo');
    videoPlayerActions.setPausedByApp(true);
    if (videoPlayerStore.state.isPlaying) {
      videoViewTrackingStore.setPlayType('auto');
      playlistStore.isLinearPlaylist() ? analyticsVideoView('stopVideo') : analyticsVideoView('pauseVideo');
      videoPlayerActions.pauseVideo();
    }
  }

  setIndexViewMore (index) {
    this.setState({viewMoreIndex: index});
  }

  onBack () {
    console.log('home.jsx - onBack');
    if (historyStore.state.forceNextGoBack) {
      historyStore.state.history.goBack();
      historyStore.state.forceNextGoBack = false;
      return;
    }

    dialogActions.hideInvalidContentScreen();
    if (this.isPage() || this.props.params.view === 'viewMore') {
      if (this.state.focused === 'homeContextualModule') this.setState({focused: 'contentSection'});

      if (this.props.locationQuery && this.props.locationQuery.IsDeeplink && ALWAYS_RESUME_LINEAR_ON_HOME) {
        // If user backs out of deeplink page, play linear
        playlistActions.playLinearChannel();
      }

      if (this.state.viewMoreIndex > 0 && this.state.currentMenuSection !== 'channels') {
        this.setState({resetList: true});
        return;
      }

      this.returnToLastSection();
    } else if (this.props.params.view) {
      if (this.props.params.view === 'channels' || this.props.params.view === 'calendar' || this.props.params.view === 'account' || this.props.params.view === 'search') {
        if (!homeStore.state.collapsed && this.state.focused === 'mainMenu') {
          this.changeSection({id: 'home'});
        } else {
          homeActions.onShouldMenuCollapse(0);
        }
        gridPageActions.reset();
        let {view} = this.getCurrentView();
        listPageActions.reset(view.id);
        this.setState({focused: 'mainMenu', resetList: true});
      }
    } else {
      if (homeStore.state.currentMenuSection === 'home' && this.state.focused !== 'mainMenu') {
        historyStore.replaceHistory(this.props.route, {name: 'home'});
        homeActions.onShouldMenuCollapse(0);
        homeActions.showOverlay(false);
        if (this.state.previousVolume) { // Only restore the volume if it was changed by Home
          PlatformUtils.sharedPlatform.setVolume(this.state.previousVolume);
        }
        if (ALWAYS_RESUME_LINEAR_ON_HOME) {
          console.log('home.jsx - onBack - playLinearChannel');
          videoPlayerActions.setPausedByApp(false);
          playlistActions.playLinearChannel();
        }
        this.setState({ focused: 'mainMenu', previousVolume: null, resetList: true });
      } else {
        if (PlatformUtils.isPS5 || PlatformUtils.isPS4 || PlatformUtils.isPS3) {
          // Exit dialog should never show on Playstation
        } else {
          if (this.state.privacyConsentPrompt) {
            // do nothing
          } else if (this.state.sunsetMessage) {
            // do nothing
          } else if (this.state.signInPrompt) {
            dialogActions.hideSignInPrompt();
          } else {
            dialogActions.showBackExitDialog(() => {
              this.setState({focused: 'contentSection'});
              this.setState({focused: 'homeContextualModule'});
            });
          }
        }
      }
    }
  }

  setupFadeOutHomeTimer () {
    if (this.state.disableFadeOut || PlatformUtils.isHBBTV || dialogStore.overlayShowing()) {
      return;
    }
    const homeTimeoutConstant = configDataStore.getConstant('system_menu_fade_timeout') || 7000;
    if (this.goToConsumptionTimer) clearTimeout(this.goToConsumptionTimer);
    this.goToConsumptionTimer = setTimeout(this.fadeOutHome, homeTimeoutConstant);
  }

  fadeOutHome () {
    if (this.state.disableFadeOut) return;

    if (homeStore.state.goingToFadeOutHome) {
      homeStore.state.goingToFadeOutHome = false;
      this.loadConsumption();
    }
  }

  registerUserKeyPress () {
    homeStore.state.goingToFadeOutHome = false;
  }

  loadConsumption (onEnter = false) {
    if (onEnter) {
      // if there was a video error previously, tell the player
      // to force the url through (try playing it again)
      window.FORCE_REFRESH = consumptionStore.state.videoError;
    } else {
      hintsActions.showContextualHint();
    }

    consumptionStore.hideVideoErrorOverlay();

    historyStore.pushHistory('/Consumption', {name: 'consumption'});
    consumptionStore.trigger(consumptionStore.state);
    if (isServus && playlistStore.isLinearPlaylist()) {
      try {
        __hbb_tracking_tgt.start(function () {
          console.log('RedTech start event successful');
        });
      } catch (e) {
        console.error('RedTech start event error', e);
      }
    }
  }

  loadPageData (id, isReload) {
    if (!id) {
      id = this.props.params.id;
    }
    homeActions.setPageLoading();
    if (this.state.page) this.setState({page: null});
    homeActions.loadPage(id, isReload);
  }

  /**
     * Tracks views that are _NOT_ page views
     * page views we need to wait for the data to populate not just the change in the view
     */
  trackView () {
    let {view} = this.props.params;
    if (!view) {
      if (this.props.data && this.props.data.system_views && this.props.data.system_views.length) {
        view = _.find(this.props.data.system_views, (view) => {
          return view.id === 'home';
        }).id;
      }
    }
    let id = _.find(this.props.data.system_views, {label: view.toLowerCase()});

    if (id) id = id.id;
    else id = view.toLowerCase();

    if (view) {
      analyticsScreenView(id, {
        title: view
      });
    } else {
      console.error('tried to track a home page with new view, no system views?');
    }
  }

  handleLinearPlayback (card) {
    console.log('home.jsx - handleLinearPlayback');
    consumptionActions.setAndPlayLinearChannel(card.id);
  }

  handleLivePlayback (card, collection) {
    if (card.content_type === 'live_program') {
      playlistStore.setNewPlaylist({ card, collection });
      consumptionActions.setCurrentAsset(card);
      videoPlayerActions.playNewStream(card, 0);
    } else if (card.content_type === 'stop') {
      const playLink = _.find(card.links || [], (item) => item.action === 'play');
      if (playLink) {
        videoPlayerActions.playNewStream({ id: playLink.id }, 0);
      } else {
        consumptionActions.setCurrentAsset({});
      }
    }
  }

  handlePlayButton () {
    historyActions.setLastComponent(this.props.location.state, {
      isPlayAll: true
    });
    this.loadConsumption();
    liveChannelsActions.updatePositionIndex(epgStore.state.currentLinearChannelIndex);
  }

  onFeaturedCardFocus (card, collection) {
    clearTimeout(this.autoPlayCurrentVideoTimeout);
    if (!card) {
      return;
    }

    if (card.isLinear || (card.isLive && (card.content_type === 'live_program' || card.content_type === 'stop'))) {
      videoPlayerActions.setPausedByApp(false);
      videoViewTrackingStore.setPlayType('auto');
      if (card.isLinear) {
        this.handleLinearPlayback(card);
      } else {
        this.handleLivePlayback(card, collection);
      }
      this.setState({ autoPlay: true });
    } else {
      startActions.markFirstPlayEventAsFinished(); // For the case where first featured item isn't playable when app starts
      this.state.autoPlay = false;
      this.autoPauseCurrentVideo();
    }
  }

  changeSection (sectionName) {
    console.log('home.jsx - changeSection');
    iTrackingActions.sendAllBatches();
    homeStore.state.currentMenuSection = sectionName.id;
    let {view} = this.getCurrentView();
    listPageActions.reset(view.id);
    gridPageActions.reset();
    scheduleActions.reset();
    liveChannelsActions.reset();

    if (sectionName.id === 'home') {
      historyStore.replaceHistory(this.props.route, {name: 'home'});
      this.setFocusOnHomeContextualModule();
      homeActions.showOverlay(false);

      // Play video again in home
      if (this.state.PAUSED_BY_APP && !HAS_10FT_HEADER) {
        this.autoPlayCurrentVideo();
      } else if (ALWAYS_RESUME_LINEAR_ON_HOME) {
        playlistActions.playLinearChannel();
      }
    } else {
      const nextSectionUrl = join(this.props.route, sectionName.id);
      historyStore.replaceHistory(nextSectionUrl, {name: sectionName.id});
      this.setState({
        focused: sectionName.id === 'account' && !userAccountStore.state.isUserLoggedIn ? 'mainMenu' : 'contentSection' // So menu doesn't scroll automatically to the second page
      });
      homeActions.showOverlay(true);

      // Pause video when navigating away from home
      this.autoPauseCurrentVideo();

      // Reset search results
      if (sectionName.id === 'search') {
        searchActions.clearSearch();
      }
    }
  }

  loadViewPage (id) {
    hintsActions.clearHints();
    listPageActions.reset(id);
    historyStore.pushHistory(join(this.props.route, 'page', id), {name: 'page', id: id});
  }

  loadViewMorePage (collectionId) {
    gridPageActions.reset();
    historyStore.pushHistory(join(this.props.route, 'viewMore', collectionId), {name: 'viewMore', collectionId});
  }

  returnToLastSection () {
    let lastComponent = historyStore.getLastComponent();
    if (!lastComponent.backState) historyStore.pushHistory('/Home');
    /* from when we linked to a page from Contextual */
    if (lastComponent.backState && lastComponent.backState.name === '/Consumption') {
      const collection = paginationStore.getCollectionWithAllRemainingItems(lastComponent.backState.collectionId);

      if (lastComponent.backState.linearId) {
        this.handleLinearPlayback({id: lastComponent.backState.linearId});
      } else if (!collection) { // For items with homeRailId
        playlistStore.playDeeplinkPlaylistFromProduct(lastComponent.backState.id, lastComponent.backState.resumePoint);
      } else {
        this.loadVideoFromCollection({
          ...collection,
          selectedId: lastComponent.backState.id
        }, lastComponent.backState.resumePoint);
      }

      this.loadConsumption();
      return;
    }
    if (isServus && lastComponent.backState && lastComponent.backState.name === 'home') {
      this.handleLinearPlayback({id: PlatformUtils.getDefaultLinearID()});
    }

    if (lastComponent.backState && lastComponent.backState.name !== 'page' && lastComponent.backState.name !== 'viewMore') {
      historyActions.clearHistory();
    }
    historyStore.pushHistory(lastComponent.backPathname, lastComponent.backState, lastComponent.backParams);
    scheduleActions.reset();
  }

  getContentFromList (card, collection, isPlayAll = false) {
    if (!card || !collection) {
      return;
    }

    homeStore.state.lastSelectedCollectionId = collection.id;
    if (collection && isPlayAll) {
      historyActions.setLastComponent(this.props.location.state, {
        isPlayAll: true
      });
    } else {
      historyActions.setLastComponent(this.props.location.state, {
        isListPanel: true,
        savedOffset: card.positionIndex,
        savedIndex: collection.positionIndex
      });
    }

    iTrackingActions.sendAllBatches();

    const { type, playable, isLinear, rowType } = card;

    if ((isLinear && rowType === 'FeaturedCardSmall') ||
      type === 'HARDCODED_LINEAR_CARD' ||
      collection?.homeRailAction === 'linear') {
      this.handleLinearPlayback(card);
      this.loadConsumption();
    } else if (type === 'view-more') {
      this.loadViewMorePage(collection.id);
    } else if (playable) {
      recentlyWatchedStore.checkShowResumeDialog(card, this.loadVideoFromCollection, collection);
    } else {
      this.loadViewPage(card.id);
    }
  }

  loadVideoFromCollection (collection, resumePoint) {
    if (!collection?.items) {
      console.warn('Not a collection', collection, resumePoint);
      return;
    }

    this.loadVideoFromCollectionTimeout && clearTimeout(this.loadVideoFromCollectionTimeout);
    videoPlayerActions.setPausedByApp(false);

    let selectedCard = _.find(collection.items, (item) => item.id === collection.selectedId);
    let collectionPlaylist = collection.id;

    const shouldStopPlayer = !(
      consumptionStore.state.currentAssetObject?.content_type === 'live_program' &&
      selectedCard.content_type === 'live_program' &&
      selectedCard?.id &&
      consumptionStore.state.currentAssetObject?.id === selectedCard?.id
    );

    if (shouldStopPlayer) {
      videoPlayerActions.setIsStopped();
      analyticsVideoView('stopVideo');
      videoViewTrackingStore.setPlayType('manual');
    }

    console.log(`home.jsx - loadVideoFromCollection - resumePoint = ${resumePoint}`, selectedCard, collection);
    if (collectionPlaylist.includes('servusEPG:manuallyAdded') ||
      collectionPlaylist.includes('search:') ||
      collectionPlaylist.includes('ACCOUNT_FAVORITES') ||
      collection.homeRailId === 'favorites' ||
      collection.homeRailId === 'continue_watching' ||
      collection.homeRailId === 'interests') {
      consumptionActions.setCurrentAsset(selectedCard);
      playlistStore.playDeeplinkPlaylistFromProduct(selectedCard.id, resumePoint);
    } else {
      playlistStore.setNewPlaylist({ selectedCard, collection });
      consumptionActions.setCurrentAsset(selectedCard);
      videoPlayerActions.playNewStream(selectedCard, resumePoint);
    }

    this.loadVideoFromCollectionTimeout = setTimeout(() => this.loadConsumption(true), 100);
  }

  navigateToNewPage (id) {
    historyActions.setLastComponent(this.props.location.state, {isPlayAll: true});
    this.loadViewPage(id);
  }

  getSystemViewById (id) {
    return _.find(this.props.data.system_views, (view) => { return view.id === id; });
  }

  getPage () {
    if (dialogStore.overlayShowing()) {
      return null;
    }
    if (this.isPage() && homeStore.state.serverStatus && homeStore.state.page) {
      if (homeStore.state.serverStatus === 200) {
        if (!homeStore.state.loadingPage) {
          return (
            <Suspense fallback={<div />}>
              <LandingPage
                page={homeStore.state.page}
                focused={true}
                navigateToNewPage={this.navigateToNewPage}
                location={this.props.location}
                locationQuery={this.props.locationQuery}
                onReload={this.loadPageData}
                onEnter={this.getContentFromList}
                onLongPress={userAccountActions.toggleIsFavorite}
                onBack={() => this.setIndexViewMore(1)}
              />
            </Suspense>
          );
        } else {
          return null;
        }
      } else if (this.state.serverStatus) {
        dialogActions.showInvalidContentScreen();
        return null;
      }
    }
  }

  setFocusOnMainMenu () {
    this.setState({
      focused: 'mainMenu'
    });
    homeActions.onShouldMenuCollapse(0);
  }

  setFocusOnHomeContextualModule (collapseMenu = 0) {
    homeActions.onShouldMenuCollapse(collapseMenu);
    homeActions.showOverlay(false);
    if (this.state.previousVolume) {
      PlatformUtils.sharedPlatform.setVolume(this.state.previousVolume);
    }

    if (HAS_10FT_HEADER) {
      this.setState({ focused: 'homeContextualModule', previousVolume: null });
    } else {
      this.setState({ focused: 'homeContextualModule', previousVolume: null, autoPlay: true });
    }
  }

  downFromMainMenu () {
    if (_.has(this.props, 'params.view') &&
     (this.props.params.view === 'channels' ||
      this.props.params.view === 'calendar' ||
      this.props.params.view === 'account' ||
      this.props.params.view === 'search' ||
      this.props.params.view === 'viewMore')) {
      this.setState({
        focused: 'contentSection'
      });
    } else {
      this.setFocusOnHomeContextualModule(1);
    }
  }

  /**
     * gets the current view and component.  A bit more complex
     * because we want to save state between navigation and pages
     * @returns {{view: {Object}, component: {String}}
     */
  getCurrentView () {
    let view = this.props.params.view;
    let data = this.props.data;
    let viewObj;
    if (view && view !== 'page' && view !== 'linearChannels' && view !== 'viewMore') {
      viewObj = _(data.system_views).find((systemView) => {
        return systemView.id.toLowerCase() === view;
      });
    } else if (view === 'page') {
      viewObj = this.props.match.params;
    } else if ((!view) && data.system_views && data.system_views.length) {
      // Default to home if we can't establish which view is requested
      viewObj = _.find(data.system_views, (systemView) => {
        return systemView.id === 'home';
      });
    } else if (view === 'viewMore') {
      const collection = paginationStore.getCollectionWithAllRemainingItems(this.props.params.id) || [];
      viewObj = { id: 'view-more', label: 'View More', collections: [collection], numberOfColumns: 4 };
      if (collection.item_type === 'video' || collection.item_type === 'videos') {
        viewObj.numberOfColumns = 3;
      }
    }

    if (!viewObj) {
      dialogActions.showErrorHomeScreen();
    }

    let component;

    if (view === 'page') {
      component = 'Page';
    } else if (view === 'search') component = 'Search';
    else if (view === 'account') component = 'Settings';
    else if (view === 'channels' || view === 'viewMore') component = 'GridPage';
    else component = 'ListPanel';
    return { view: viewObj, component };
  }

  getMenuItems () {
    // TODO: Remove and move to menu component. For some reason I can't import the localizationStore there atm
    let mainMenuItems = Object.assign([], this.props.data.system_views);
    mainMenuItems.forEach((item) => {
      item.label = localizationStore._GET(item.localization_key || item.id) || item.id;
    });
    return mainMenuItems;
  }

  setFocusOnContentSection () {
    const currentVolume = PlatformUtils.sharedPlatform.getVolume();
    this.setState({
      focused: 'contentSection',
      previousVolume: currentVolume
    });
    PlatformUtils.sharedPlatform.setVolume(currentVolume * 0.3, currentVolume);
    homeActions.onShouldMenuCollapse(1);
  }

  onListPanelChange (index) {
    // Make overlay visible after Live TV row
    if (index > 0 && !this.state.isOverlayVisible) {
      homeActions.showOverlay(true);
    }

    // Hide overlay when LIVE Tv row is reached
    if (index === 0 && this.state.isOverlayVisible) {
      homeActions.showOverlay(false);
    }
  }

  getFocusState (init) {
    if (init && !homeStore.state.collapsed) return 'mainMenu';
    let focusState = (!_.isEmpty(this.props.locationQuery) && !this.props.locationQuery.isPlayAll) ||
      (this.props.params.view === 'search' || this.props.params.view === 'viewMore' || this.props.params.view === 'account') ? 'contentSection' : 'homeContextualModule';
    return focusState;
  }

  render () {
    const show = !dialogStore.overlayShowing() || this.isFocused('homeContextualModule');
    const { view, component } = this.getCurrentView();
    const showContextualModule = view.id === 'home' && !this.isPage();
    const coverBackgroundVideo = _.has(this.props, 'params.view');
    let listCollections = [...(view?.collections || [])];

    if (isServus) {
      if (view.id === 'home') {
        const epgCardsAmount = epgStore.getCards() && epgStore.getCards().length;
        const epgCollection = {
          label: localizationStore._GET('up_next'),
          items: epgStore.getCards(),
          type: 'schedule-item',
          id: 'servusEPG:manuallyAdded',
          meta: {total: epgCardsAmount}
        };
        listCollections = [epgCollection, ...listCollections];
      } else if (view.id === 'calendar') {
        // HACK: Should probably not be modifying collections like this in render
        if (listCollections && listCollections.length && epgStore.state.EPG.items && epgStore.state.EPG.items.length) {
          let currentEPGCard = _.cloneDeep(epgStore.state.EPG.items[0]);
          currentEPGCard.status = {code: 'live'};
          currentEPGCard.type = 'HARDCODED_LINEAR_CARD';
          let originaTopCollectionForCalendar = listCollections[0];
          let newTopCollectionForCalendar = {
            ...originaTopCollectionForCalendar
          };

          newTopCollectionForCalendar.items = [currentEPGCard, ...originaTopCollectionForCalendar.items];
          listCollections[0] = newTopCollectionForCalendar;
        }
      }
    }

    if (view.id === 'home' && this.props.data.fullHeroCards) {
      listCollections = _.filter(listCollections, (coll) => {
        // Don't show whatever is showing in the Hero Cards again further down in Home
        return coll && coll.id !== this.props.data.fullHeroCards.id;
      });
    }

    const featuredRail = view.id === 'home' && HAS_10FT_HEADER && listCollections.find(coll => coll && coll.type === 'featured');
    if (featuredRail) {
      listCollections = _.filter(listCollections, (coll) => {
        return coll && coll.id !== featuredRail.id;
      });

      if (!this.state.disableFadeOut && !featuredRail.items[0].isLinear) {
        homeActions.setDisableFadeOut(true);
      }
    }

    const hasVideoOverlay = ((this.isFocused('contentSection') && (isServus ? true : featuredRail)) || this.state.isOverlayVisible || coverBackgroundVideo);

    const className = cx({
      hasVideoOverlay: hasVideoOverlay,
      has10ftHeader: showContextualModule && featuredRail && HAS_10FT_HEADER,
      hasHomeContextualModule: showContextualModule && !featuredRail && !HAS_10FT_HEADER,
      isHomeContextualModuleFocused: this.isFocused('homeContextualModule'),
      isContentSectionFocused: this.isFocused('contentSection'),
      isContextualModule: showContextualModule,
      mainMenuCollapsed: homeStore.state.collapsed,
      pinned: this.props.params.view === 'page',
      hide: !show,
      whiteTheme: view.id !== 'home' && view.view !== 'page' && isServus
    });

    return (
      <div id="home" className={className}>
        {/* TODO: figure out why we have two video overlays and fix this confusion */}
        <div className={ view.id === 'home' && !featuredRail ? 'video_overlay' : 'video_overlay video_overlay--solid' } />
        <MainMenu
          menuItems={this.getMenuItems()}
          menuid="mainMenu"
          onEnter={this.changeSection}
          focused={this.isFocused('mainMenu')}
          collapse={homeStore.state.collapsed}
          onDown={this.downFromMainMenu}
          disableMouseEvents={true}
          currentComponent={view}
          currentMenuSection={homeStore.state.currentMenuSection}
          show={show}
          whiteTheme={view.id === 'home' && isServus} />

        <div id="homeContent">
          {showContextualModule && (featuredRail
            ? <HomeFeaturedCarouselModule
              blured={this.isFocused('contentSection')}
              focused={this.isFocused('homeContextualModule')}
              collection={featuredRail}
              onUp={this.setFocusOnMainMenu}
              onDown={this.setFocusOnContentSection}
              onEnter={this.getContentFromList}
              onChange={this.onFeaturedCardFocus} />
            : <HomeContextualModule
              blured={this.isFocused('contentSection')}
              focused={this.isFocused('homeContextualModule')}
              collection={this.props.data.fullHeroCards}
              onUp={this.setFocusOnMainMenu}
              onDown={this.setFocusOnContentSection}
              onEnter={this.getContentFromList}
              onPlayButtonEnter={this.handlePlayButton} />
          )}

          <ListPanel
            id={view.id}
            resetList={this.state.resetList}
            collections={(component === 'ListPanel') ? filterCollections(listCollections) : []}
            onUp={view.id === 'home' ? this.setFocusOnHomeContextualModule : this.setFocusOnMainMenu}
            onEnter={this.getContentFromList}
            onLongPress={userAccountActions.toggleIsFavorite}
            FORCE_UPDATE={this.props.FORCE_UPDATE}
            focused={this.isFocused('contentSection') && component === 'ListPanel'}
            backParams={this.props.locationQuery}
            onShouldMenuCollapse={homeActions.onShouldMenuCollapse}
            onChange={this.onListPanelChange}
            hide={!(show && component === 'ListPanel')}
            iTrackingRow={view.id === 'home' ? 2 : 1} />

          <GridPage
            id={view.id}
            onUp={this.setFocusOnMainMenu}
            resetList={this.state.resetList}
            collections={view.collections || []}
            numberOfColumns={view.numberOfColumns || 4}
            onEnter={this.getContentFromList}
            setIndexViewMore={this.setIndexViewMore}
            focused={this.isFocused('contentSection') && component === 'GridPage'}
            backParams={this.props.locationQuery}
            onShouldMenuCollapse={homeActions.onShouldMenuCollapse}
            iTrackingRow={1}
            show={show && (component === 'GridPage')} />

          { (show && component === 'Search')
            ? <Suspense fallback={<div />}>
              <Search onUp={this.setFocusOnMainMenu}
                onEnter={this.getContentFromList}
                focused={this.isFocused('contentSection') && component === 'Search'}
                backParams={this.props.locationQuery}
                onShouldMenuCollapse={homeActions.onShouldMenuCollapse}
                resetList={this.state.resetList}
                show={true} />
            </Suspense>
            : null }

          {(component === 'Settings')
            ? <Suspense fallback={<div />}>
              <Settings onUp={this.setFocusOnMainMenu}
                focused={this.isFocused('contentSection') && component === 'Settings'}
                show={true}
                onBack={this.onBack}
                backParams={this.props.locationQuery}
                route={this.props.route}
                onEnter={this.getContentFromList}
                onShouldMenuCollapse={homeActions.onShouldMenuCollapse}
                params={this.props.params} />
            </Suspense>
            : null }
        </div>
        {this.getPage()}
        { (this.state.isOverlayVisible || coverBackgroundVideo) && <div className={ view.id === 'home' ? 'video_overlay' : 'video_overlay video_overlay--solid' } /> }
      </div>
    );
  }
}
