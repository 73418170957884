import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import leadingZeros from '../../utils/leadZeros';
import dateFormatter, { Duration } from '../../utils/date-formatter';
import { localizationStore } from '../../reflux/localizationStore';

const ONE_SECOND_IN_MILLISECOND = 1000;

export default class Countdown extends Reflux.Component {
  constructor (p) {
    super(p);

    this.state = {
      event: {}
    };

    this.clear = this.clear.bind(this);
    this.setup = this.setup.bind(this);
    this.update = this.update.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount () {
    this.setup();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.start_time !== this.props.start_time ||
      prevProps.end_time !== this.props.end_time) {
      this.setup();
    }
  }

  componentWillUnmount () {
    this.clear();
  }

  clear () {
    clearInterval(this.interval);
  }

  setup () {
    this.clear();
    const { start_time, end_time } = this.props;
    if (!start_time || !end_time) {
      return;
    }

    const event = {};
    const now = new Date();
    const startTime = new Date(start_time);
    const endTime = new Date(end_time);

    // has started
    if (startTime <= now) {
      // has ended
      if (endTime <= now) {
        // Aired on %date%
        event.after = localizationStore._GET('aired_on_date',
          dateFormatter.getDateString(start_time)
        );
      } else {
        // 00 Hr 00 Min 00 Sec
        event.during = localizationStore._GET('about_to_start');
      }
      // 48 before start time
    } else if (dateFormatter.isInLessThan48Hours(new Date(startTime))) {
      // initial state
      this.update();
      // update every second
      this.interval = setInterval(this.update, ONE_SECOND_IN_MILLISECOND);
      // more than 48 before start time
    } else if (this.props.renderUpcomingDateRange) {
      // %start_time% - %end_time%
      event.before = dateFormatter.getDateRangeString(start_time, end_time, true);
    } else {
      // %date% at %time%
      event.before = localizationStore._GET('date_at_time',
        dateFormatter.getDateString(start_time),
        dateFormatter.getTimestring24Hrs(start_time)
      );
    }

    this.setState({ event });
  }

  update () {
    const d = new Duration(new Date(), new Date(this.props.start_time));
    if (d.isBefore) {
      this.setState({ event: { counting: d } });
    } else {
      this.clear();
    }
  }

  print (event) {
    const { before, counting, during, after } = event;
    const t = before || after || during;
    const d = counting;

    if (d) {
      return (
        <React.Fragment>
          <span><i>{leadingZeros(d.hours)}</i> Hr</span>
          <span><i>{leadingZeros(d.minutes)}</i> Min</span>
          <span><i>{leadingZeros(d.seconds)}</i> Sec</span>
        </React.Fragment>
      );
    }

    if (t) {
      return <span>{t}</span>;
    }

    return null;
  }

  render () {
    const { event } = this.state;
    const classNames = cx('countdown', {
      'countdown--before': event.before,
      'countdown--counting': event.counting,
      'countdown--during': event.during,
      'countdown--after': event.after
    });

    return (
      <span className={classNames}>
        {this.print(event)}
      </span>
    );
  }
}
