import Reflux from 'reflux';
import _ from 'lodash';
import {videoPlayerActions, videoPlayerStore} from './videoPlayerStore';
import {consumptionActions, consumptionStore} from './consumptionStore';
import {paginationStore} from './paginationStore';
import {epgStore, epgActions} from './epgStore';
import PlaylistServiceDelegate from '../services/playlistServiceDelegate.js';
import ProductServiceDelegate from '../services/productServiceDelegate.js';
import PlatformUtils from '../utils/platform.js';
import {configDataStore} from './configDataStore';
import {adsStore} from './adsStore';
import ErrorReporter from '../utils/error-reporter';
import isLive from '../utils/isLive';
import API from '../services/api.js';
import {dialogActions} from './dialogStore';
import conf from '../conf.js';
import {manifestStore} from './manifestStore';

export const playlistActions = Reflux.createActions({
  'playlistNext': {},
  'getInitialState': {},
  'loadItems': {asyncResult: true},
  'onLoadItemsCompleted': {},
  'getCachedPlaylist': {},
  'cachePlaylist': {},
  'resumeCachedPlaylist': {},
  'willResumeCachedPlaylist': {},
  'setCurrentlyWatchingIndex': {},
  'playLinearChannel': {},
  'isOrderedPlaylist': {},
  'isLinearPlaylist': {},
  'isLive': {},
  'splicePage': {}
});

// I can't import these into consumption store and vice versa without
// getting into a dependency loop so this is a bit of a hack to attach
// this function to the consumptionStore.....
consumptionStore.playNextItemInPlaylist = function () {
  playlistActions.playlistNext();
};

consumptionStore.playDeeplinkPlaylistFromProduct = function (productID, resumePoint, callback) {
  playlistStore.playDeeplinkPlaylistFromProduct(productID, resumePoint, callback);
};

consumptionStore.playOneItemPlaylist = function (result) {
  let oneItemPlaylist = {items: [result], positionIndex: 0};
  console.log('onLoadItemsCompleted - resumePoint - 1');
  playlistStore.onLoadItemsCompleted(oneItemPlaylist);
};
consumptionStore.playLinearChannel = playlistActions.playLinearChannel;

var localStorage = PlatformUtils.sharedPlatform.localStorage();

const LINEAR_PLAYLIST = 'playlist:linear';
const ORDERED_PLAYLIST = 'playlist:ordered';

playlistActions.loadItems.listen(function (playlistId) {
  playlistStore.state.id = playlistId;

  PlaylistServiceDelegate.getPlaylistData(playlistId)
    .then(this.completed)
    .catch(this.failed);
});

export const playlistStore = Reflux.createStore({
  listenables: playlistActions,
  state: {
    playlistData: {},
    currentlyWatchingIndex: 0,
    lastPlayedTime: new Date().getTime(),
    relaunchResumeTimeout: 60480000, // default of 7 days
    playlistType: LINEAR_PLAYLIST
  },

  init: function () {
    this.listenTo(videoPlayerActions.videoEnd, this.playlistNext);
    this.listenTo(epgActions.handleEPGChange, this.epgVideoChange);
  },

  isCurrentlyPlayingVideo: function (video) {
    let currId = consumptionStore.state.currentAssetID;

    return video.id === currId;
  },

  getCurrentPlayingChannel: function () {
    if (epgStore && epgStore.state) {
      return epgStore.state.currentLinearChannel;
    }
  },

  setNewPlaylist: function (playlistStatus, callback) {
    const { collection } = playlistStatus;
    if (collection && _.has(collection, 'id')) {
      this.setOrderedPlaylist();
    }
    PlaylistServiceDelegate.getPlaylistData(collection.id, collection.selectedId).then(
      (result) => {
        const { resumePoint, selectedCard } = playlistStatus;
        const playlist = Object.assign({}, result);
        // Set positionIndex in the playlist - the collection positionIndex is not guaranteed to be the same
        playlist.positionIndex = _.findIndex(playlist.items, (collItem) => { return collItem.id === collection.selectedId; });
        if (playlist.positionIndex === -1 && selectedCard) {
          // "If that playlist does not contain the item the user is playing then the playlist is appended to
          // the currently playing item and will be played sequentially from the beginning."
          playlist.items.unshift(selectedCard);
          playlist.meta.total = playlist.meta.total + 1;
          playlist.positionIndex = 0;
        }
        if (callback) {
          callback(result);
        }
        console.log('onLoadItemsCompleted - resumePoint - 2');
        this.onLoadItemsCompleted(playlist, resumePoint, true);
      });
  },

  onLoadItemsCompleted: function (result, resumePoint, isPlaying) {
    console.log('onLoadItemsCompleted');
    if (!result || !_.has(result, 'id')) {
      playlistActions.playLinearChannel();
      return console.warn('Empty playlist result');
    }
    // We need to clone this object, or we end up getting weird side effects due to pass by ref.
    let playlistData = _.cloneDeep(result);

    console.log('setting new playlist data', playlistData);

    this.state.playlistData = playlistData;

    this.state.currentlyWatchingIndex = (result.positionIndex >= 0) ? result.positionIndex : 0;
    // Hack to handle replacing playlist if method fires from loop
    if (this.state.fromLoop) {
      this.state.fromLoop = false;
    } else {
      this.checkRetrieveNextPage();
    }

    this.state.id = playlistData.id;

    let itemToPlay = playlistData.items[this.state.currentlyWatchingIndex];
    itemToPlay.positionIndex = this.state.currentlyWatchingIndex;
    if (!itemToPlay) {
      consumptionActions.showVideoErrorOverlay('playlistStore');
    } else {
      this.setOrderedPlaylist();
      consumptionActions.setIsChannelTitleVisible(true);
      this.setCurrentlyWatchingIndex(itemToPlay);
      console.log('videoMetaData - onLoadItemsComplete itemToPlay-', itemToPlay);
      if (!isPlaying) videoPlayerActions.playNewStream(itemToPlay, resumePoint);
    }
  },

  onLoadItemsFailed: function (err) {
    ErrorReporter.captureException(err, 'playlistStore onLoadItemsFailed');
    this.state.error = err.errorCode;
    this.trigger(this.state);
  },

  getInitialState: function () {
    return this.state;
  },

  getCachedPlaylistState: function () {
    let playlistString = localStorage.getItem('rbtv:savedPlaylistInfo');
    return JSON.parse(playlistString);
  },

  cachePlaylist: function () {
    this.state.lastPlayedTime = new Date().getTime();
    this.state.relaunchResumeTimeout = configDataStore.getConstant('relaunch_resume_timeout');

    if (this.state.playlistData) {
      const playlistInfoToCache = {
        currentAssetID: consumptionStore.state.currentAssetID,
        lastPlayedTime: this.state.lastPlayedTime,
        relaunchResumeTimeout: this.state.relaunchResumeTimeout,
        playlistId: this.state.playlistData.id
      };
      localStorage.setItem('rbtv:savedPlaylistInfo', JSON.stringify(playlistInfoToCache));
    }
  },

  deleteCachedPlaylist: function () {
    localStorage.setItem('rbtv:savedPlaylistInfo', JSON.stringify({}));
  },

  checkRetrieveNextPage: function () {
    if (!_.has(this.state.playlistData, 'items')) {
      return;
    }
    let nextItem = this.state.playlistData.items[this.state.currentlyWatchingIndex + 1];
    if (nextItem && !nextItem.id) {
      // Item with a blank ID key implies an as yet unpaginated page
      let pageSize = this.state.playlistData.meta.limit;
      let currentPage = Math.ceil((this.state.currentlyWatchingIndex) / pageSize);
      paginationStore.loadPlaylistPage(this.state.playlistData.id, currentPage + 1, pageSize);
    }
  },

  resumeCachedPlaylist: function (callback) {
    console.log('resumeCachedPlaylist');
    const lastLinearChannelID = PlatformUtils.sharedPlatform.localStorage().getItem('rbtv:last-linear-id');
    if (lastLinearChannelID) {
      this.playLinearChannel(lastLinearChannelID);
      if (callback) callback();
      return;
    }

    const cachedPlaylistInfo = this.getCachedPlaylistState();

    this.setOrderedPlaylist();
    let {playlistId, currentAssetID} = cachedPlaylistInfo;

    console.log('cachedPlaylistInfo=', cachedPlaylistInfo);

    let failedToLoad = (message, error) => {
      console.error('problem resuming cached playlist, defaulting to linear', message, error);
      playlistStore.setLinearPlaylist();
      playlistActions.playLinearChannel();
      if (callback) callback();
    };

    if (cachedPlaylistInfo) {
      /* we need to refresh the cached playlist data as images etc may have expired since last launch. */
      if (!playlistId) {
        failedToLoad('missing playlist ID for resuming playlist');
        return;
      }
      PlaylistServiceDelegate.getPlaylistData(playlistId, currentAssetID).then(newPlaylistData => {
        newPlaylistData.positionIndex = _.findIndex(newPlaylistData.items, {id: currentAssetID});
        console.log('onLoadItemsCompleted - resumePoint - 3');
        this.onLoadItemsCompleted(newPlaylistData);
        if (callback) callback();
        this.trigger(this.state);
      }).catch(error => {
        if (callback) callback();
        failedToLoad('failed to refresh cached playlist. (2)', error);
      });
    } else {
      if (callback) callback();
      this.trigger(this.state);
    }
  },

  willResumeCachedPlaylist: function () {
    const lastLinearChannelID = PlatformUtils.sharedPlatform.localStorage().getItem('rbtv:last-linear-id');
    if (lastLinearChannelID) {
      return true;
    }

    var cachedState = this.getCachedPlaylistState();
    var cachedTime;
    if (cachedState) {
      if (cachedState.lastPlayedTime) {
        cachedTime = cachedState.lastPlayedTime;
      } else {
        cachedTime = new Date().getTime();
      }
      return (cachedState && (this.state.lastPlayedTime - cachedTime < (cachedState.relaunchResumeTimeout)));
    } else {
      return false;
    }
  },

  splicePage: function (pageNumber, pageItems, pageSize) {
    let prependDiff = this.state.playlistData.hasPrependedItem ? 1 : 0;
    let startPoint = ((pageNumber - 1) * pageSize) + prependDiff;
    var args = [startPoint, pageSize].concat(pageItems);
    let items = this.state.playlistData.items.slice();
    Array.prototype.splice.apply(items, args);
    this.state.playlistData.items = items;

    this.trigger(this.state);
  },

  setCurrentlyWatchingIndex: function (item) {
    console.log('setCurrentlyWatchingIndex', item);
    consumptionActions.setCurrentAsset(item);
    this.state.currentlyWatchingIndex = item.positionIndex;
    this.retrieveNextPlaylist();
    this.checkRetrieveNextPage();
    console.info('Caching currently playing playlist...');
    this.cachePlaylist();
    this.trigger(this.state);
  },

  playlistNext: function () {
    console.log('playlistStore - playlistNext');
    if ((this.state.playlistData && this.state.playlistData.items) && (this.state.currentlyWatchingIndex < this.state.playlistData.items.length - 1)) {
      const nextItem = this.state.playlistData.items[this.state.currentlyWatchingIndex + 1];
      this.setCurrentlyWatchingIndex(nextItem);
      consumptionActions.setCurrentAsset(nextItem);
      videoPlayerActions.playNewStream(nextItem);
    } else {
      if (this.state.nextPlaylist && this.state.nextPlaylist.items && this.state.nextPlaylist.items.length) {
        let firstVideoOfNextPlaylist = this.state.nextPlaylist.items[0];
        if (firstVideoOfNextPlaylist.id === consumptionStore.state.currentAssetID) {
          // At least philips tv need the setCurrentAsset before the window.FORCE_REFRESH
          if (PlatformUtils.isPhilips) { consumptionActions.setCurrentAsset(firstVideoOfNextPlaylist); }
          // Next playlist is just a loop - so we must force Consumption to play the VOD again
          window.FORCE_REFRESH = true;
          this.state.fromLoop = true;
        }
        if (!PlatformUtils.isPhilips) { consumptionActions.setCurrentAsset(firstVideoOfNextPlaylist); }
        videoPlayerActions.playNewStream(firstVideoOfNextPlaylist);
        this.onLoadItemsCompleted(this.state.nextPlaylist, this.state.fromLoop, true);
      } else {
        this.playLinearChannel();
      }
    }
    this.trigger(this.state);
  },

  retrieveNextPlaylist: function () {
    if (_.has(consumptionStore.state.currentAssetObject, 'next_playlist')) {
      PlaylistServiceDelegate.getPlaylistData(consumptionStore.state.currentAssetObject.next_playlist)
        .then((res) => {
          this.state.nextPlaylist = res;
        })
        .catch((res) => {
          ErrorReporter.captureException(res, 'Error retrieving playlist');
          this.state.nextPlaylist = 'LINEAR';
        });
    } else {
      console.warn('Tried to retrieve next playlist and found nothing', this.state);
      if (!this.state.playlistData.id) {
        this.state.nextPlaylist = 'LINEAR';
      } else {
        PlaylistServiceDelegate.getPlaylistData(this.state.playlistData.id)
          .then((res) => {
            this.state.nextPlaylist = res;
          })
          .catch((res) => {
            ErrorReporter.captureException(res, 'Error retrieving playlist');
            this.state.nextPlaylist = 'LINEAR';
          });
      }
    }
  },

  playLinearChannel: function (id, callback) {
    if (adsStore.state.adsPlaying) {
      console.log('[ads] playLinearChannel prevent running linear stream along with ad stream');
      return;
    }
    if (!id || id === false || id === 'false' || _.isUndefined(id) || id === 'undefined') {
      console.warn('Invalid or missing linear ID', id);
      id = PlatformUtils.getDefaultLinearID();
    }
    if (playlistStore.state.servusUserIsGeoBlocked) {
      videoPlayerActions.playNewStream({id: 'servus-fallback', src: configDataStore.getConstant('linear_stream_fallback')}, null, true);
      consumptionActions.setCurrentAsset({id: 'servus-fallback'}, null, true);
      this.setLinearPlaylist();
      return;
    }
    console.log('Playing linear', id);
    epgStore.updateCurrentLinearChannel(id);

    this.setLinearPlaylist();
    epgStore.state.initialLoadComplete = false;
    epgStore.state.pagesRequested = {};

    let url = API.getVideoUrl(id);
    if (url === videoPlayerStore.state.currentSrc && !window.FORCE_REFRESH) {
      console.log('playlistStore playLinearChannel -- that url is already playing');
      videoPlayerActions.playVideo();
      return;
    } else {
      window.FORCE_REFRESH = false;
    }

    epgActions.loadEPG.triggerAsync(id, 0)
      .catch((e) => {
        console.log('ERROR LOADING EPG', e);
        if (conf.appNamespace === 'servustv' && e.errorCode === 403) {
          playlistStore.state.servusUserIsGeoBlocked = true;
          epgStore.state.servusUserIsGeoBlocked = true;
          videoPlayerActions.playNewStream({id: 'servus-fallback', src: configDataStore.getConstant('linear_stream_fallback')}, null, true);
          consumptionActions.setCurrentAsset({id: 'servus-fallback'}, null, true);
          if (callback) callback();
          return { servusFallbackTriggered: true };
        }
      })
      .then((response) => {
        if (!response || !(response && response.servusFallbackTriggered)) {
          const currentProgram = epgStore.getCurrentVideo();
          if (!currentProgram) {
            ErrorReporter.captureMessage('EPG ' + id + 'seems to be empty');
          } else {
            consumptionActions.setCurrentAsset(currentProgram, null, true);
          }
          videoPlayerActions.playNewStream({id: id}, null, true);
          if (callback) callback();
        }
      });
  },

  getEPGPlaylist: function () {
    return {label: 'Today', items: epgStore.getCards()};
  },

  epgVideoChange: function () {
    console.log('epgVideoChange');
    if (this.isLinearPlaylist()) {
      if (!epgStore.getCards().length) {
        return;
      }
      let linearStream = epgStore.getLinearStream();
      let currentProgram = epgStore.getCurrentVideo();

      consumptionActions.setCurrentAsset(currentProgram, null, true);
      videoPlayerActions.playNewStream(linearStream, null);
    }
  },

  setOrderedPlaylist: function () {
    consumptionStore.state.isLinear = false;
    epgStore.clearEPGTimeouts();
    this.state.playlistType = ORDERED_PLAYLIST;
  },

  setLinearPlaylist: function () {
    console.warn('SET LINEAR PLAYLIST');
    consumptionStore.state.isLinear = true;
    this.state.playlistType = LINEAR_PLAYLIST;
  },

  isOrderedPlaylist: function () {
    return this.state.playlistType === ORDERED_PLAYLIST;
  },

  isLinearPlaylist: function () {
    return this.state.playlistType === LINEAR_PLAYLIST;
  },

  isVOD () {
    const isDVR = playlistStore.isCurrentVideoDVR();
    const isLive = playlistStore.isLive() && !isDVR;
    const isVOD = playlistStore.isOrderedPlaylist();
    return (isVOD && (!isDVR && !isLive));
  },

  isLive: function () {
    return isLive(this.state.playlistData?.items?.[this.state.currentlyWatchingIndex]);
  },

  playDeeplinkPlaylistFromProduct: function (productID, resumePoint, callback, shouldNotPrepend) {
    this.setOrderedPlaylist();
    ProductServiceDelegate.getProductData(productID).then(
      (product) => {
        let playlistId = _.has(product, 'deeplink_playlist') ? product.deeplink_playlist : product.id;
        PlaylistServiceDelegate.getPlaylistData(playlistId, product.id).then(
          (playlist) => {
            playlist.positionIndex = _.findIndex(playlist.items, (item) => { return item.id === productID; });
            if (playlist.positionIndex === -1 && !shouldNotPrepend) {
              // "If that playlist does not contain the item the user is playing then the playlist is appended to
              // the currently playing item and will be played sequentially from the beginning."
              playlist.items.unshift(product);
              playlist.meta.total = playlist.meta.total + 1;
              playlist.positionIndex = 0;
              playlist.hasPrependedItem = true;
            }
            if (callback) {
              callback(playlist, productID);
            }
            console.log('HTML5 - playDeeplinkPlaylistFromProduct - resumePoint=', resumePoint);
            this.onLoadItemsCompleted(playlist, resumePoint);
          }).catch(function (e) {
          dialogActions.showInvalidContentScreen();
        });
      }).catch(function (e) {
      dialogActions.showInvalidContentScreen();
    });
  },

  voiceCommand_trackNext: function () {
    if (!this.isOrderedPlaylist()) {
      return false;
    } else {
      playlistActions.playlistNext();
      return true;
    }
  },

  voiceCommand_trackPrevious: function () {
    if (!this.isOrderedPlaylist()) {
      return false;
    } else {
      if (this.state.currentlyWatchingIndex > 0) {
        const prevItem = this.state.playlistData.items[this.state.currentlyWatchingIndex - 1];
        this.setCurrentlyWatchingIndex(prevItem);
        consumptionActions.setCurrentAsset(prevItem);
        videoPlayerActions.playNewStream(prevItem);
        return true;
      } else {
        return false;
      }
    }
  },

  isCurrentVideoDVR: function () {
    // All DASH live manifests are DVR-able, not all HLS manifests are
    return (playlistStore.isLive() && (manifestStore.state.currentManifestType === 'HLS' ? manifestStore.state.hlsManifestIsDVR : true));
  }
});
