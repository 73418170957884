import { localizationStore } from '../reflux/localizationStore';
import dateFormatter from './date-formatter';
import timeFormatter from './time-formatter';

const ONE_HOUR_IN_MS = 60 * 60 * 1000;
const NONE_EVENTS_TYPES = ['film', 'clip', 'show', 'episode', 'video'];

export default function normalizeLabel (props) {
  const data = {};

  if (props.isWatching) {
    data.type = 'watching';
    data.label = localizationStore._GET('watching');
  } else {
    // is event
    if (props.status && !NONE_EVENTS_TYPES.includes(props.content_type)) {
      data.type = 'event';
      if (!props.isOldEvent && props.status) {
        let mode;
        let isNow = false;
        let isPast = false;

        const { code, start_time, end_time } = props.status;
        const startDate = new Date(start_time);
        const endDate = new Date(end_time);
        const now = new Date();

        data.eventStatus = mode = code;

        if (!isNaN(startDate) && !isNaN(endDate) && ['pre', 'unconfirmed'].includes(code)) {
          isNow = startDate <= now && endDate >= now;
          isPast = endDate < now;

          // -> pre & isNow -> live
          if (isNow) {
            // mode = 'live';
            data.retry = Math.min(Math.max(endDate - now, 1000), ONE_HOUR_IN_MS);
            // -> pre & isPast -> post
          } else if (isPast) {
            // mode = 'post';
          } else {
            // -> pre & isFuture -> try again
            data.retry = Math.min(Math.max(startDate - now, 1000), ONE_HOUR_IN_MS);
          }
        }

        switch (mode) {
          case 'unconfirmed':
          case 'pre':
            data.label = dateFormatter.getDateRangeString(start_time, end_time);
            break;
          case 'live':
          case 'event_window':
            data.eventStatus = 'live';
            data.label = localizationStore._GET('live');
            break;
          case 'post':
            data.label = props.renderPostEventMessage
              ? localizationStore._GET_LABEL(props.status.label || props.status.message)
              : dateFormatter.getDateString(end_time);
            break;
          case 'trim':
          default:
            data.label = localizationStore._GET_LABEL(props.status.label || props.status.message);
            break;
        }
      }

      // event cannot play
      if (!props.playable && !data.label) {
        data.eventStatus = props.isScheduleChangedToVideoList ? 'upcoming' : 'unavailable';
        data.label = localizationStore._GET(data.eventStatus);
      }

      // is clip | episode | film...
    } else {
      if (props.status) {
        data.label = localizationStore._GET_LABEL(props.status?.label || props.status?.message);
      } else if (props.duration) {
        data.label = timeFormatter().getHumanReadableDuration(props.duration);
      }

      if (data.label) {
        data.type = props.content_type;
      }
    }
  }

  return data;
}
