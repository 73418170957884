import Reflux from 'reflux';
import api from '../services/api.js';
import PlatformUtils from '../utils/platform.js';
import conf from '../conf';
import _ from 'lodash';
import xboxCaptionsConfig from '../config/xboxCaptionsConfig.js';
import {loadTizenCaptionsConfig, setTizenCaptionsChangedCallback} from '../config/tizenCaptionsConfig.js';
import {configDataStore} from '../reflux/configDataStore';
import ErrorReporter from '../utils/error-reporter';
import {appStore} from '../reflux/appStore';
import reload from '../utils/reload.js';

const defaultCaptionOptions = {
  textFont: 'default',
  textSize: 1.0,
  textColor: 'white',
  textOpacity: 1.0,
  textEdgeStyle: 'none', // does not have override
  backgroundColor: 'black',
  backgroundOpacity: 1.0,
  windowColor: 'black',
  windowOpacity: 1.0
};

const informationTTSUrls = {
  imprint: 'https://policies.redbull.com/RedBullTVImprint',
  terms: 'https://policies.redbull.com/RedBullTVTerms',
  privacy: 'https://policies.redbull.com/RedBullTVPrivacy'
};

function makeDefaultCaptions () {
  var options = xboxCaptionsConfig.getCaptionValue();
  if (options === null) {
    options = defaultCaptionOptions;
  }
  let result = {};
  _.each(options, (value, k) => {
    result[k] = {value, override: false};
  });
  return result;
}

export const settingsActions = Reflux.createActions({
  'toggleHints': {},
  'toggleClosedCaptions': {},
  'setClosedCaptions': {},
  'setCaptionOption': {},
  'setUseCustomCaptionsFormatting': {},
  'togglePlaybackInterruption': {},
  'toggleTizenEnableContinueWatching': {},
  'toggleAlwaysShowAds': {},
  'toggleSpoofCountry': {},
  'loadSettingsStatusInfo': {},
  'toggleVideoPreview': {},
  'loadInformationData': {asyncResult: true}
});

settingsActions.loadInformationData.listen(function (arg) {
  const links = configDataStore.getConstant('links');
  const useWebLinks = links.use_web_links;

  api.getSettingsInformation(links[useWebLinks ? arg + '_web' : arg], useWebLinks)
    .then(this.completed)
    .catch(this.failed);
});

var localStorage = PlatformUtils.sharedPlatform.localStorage();

const tizenCaptionSupport = (window.tizen && window.tizen.tvinfo && typeof window.tizen.tvinfo.getCaptionValue === 'function');

export const settingsStore = Reflux.createStore({

  listenables: settingsActions,
  state: {
    // default settings. will be overridden by localStorage version if it exists,
    arrowSrc: null,
    settings: {
      isInitialStartupOfApp: true,
      shouldShowHints: true,
      shouldShowCC: false,
      shouldInterruptPlayback: true,
      shouldShowVideoPreview: true,
      enableTizenContinueWatching: true,
      spoofCountry: false,
      spoofNameCountry: '',
      captions: makeDefaultCaptions(),
      userPreferredCaptionLanguage: conf.appNamespace === 'servustv' ? 'ger' : 'eng',
      userPreferredAudioLanguage: conf.appNamespace === 'servustv' ? 'ger' : 'eng'
    },
    sessionCaptionLanguage: '',
    sessionAudioLanguage: '',
    pageData: null,
    view: null
  },

  init: function () {
    if (PlatformUtils.isTizen && tizenCaptionSupport) {
      /* global tizen */
      const key = 'CAPTION_ONOFF_KEY';
      this.tizenSetCaptionsEnabled = this.tizenSetCaptionsEnabled.bind(this);
      this.tizenSetCaptionsEnabled(null, tizen.tvinfo.getCaptionValue(key));
      tizen.tvinfo.addCaptionValueChangeListener(key, this.tizenSetCaptionsEnabled);
    }

    this.state.settings = this.loadSettings();

    if (PlatformUtils.isTizen && tizenCaptionSupport) {
      setTizenCaptionsChangedCallback(this.tizenUpdatedCaptions.bind(this));
      this.state.settings.shouldUseCustomCaptionsFormatting = true;
    }

    if (this.state.settings.isInitialStartupOfApp) {
      this.state.settings.isInitialStartupOfApp = false;
      this.saveSettingsToLocalStorage(this.state.settings);
    }
  },

  loadSettings: function () {
    let settings = JSON.parse(localStorage.getItem('rbtv:settings')) || this.state.settings;

    if (typeof settings.shouldUseCustomCaptionsFormatting === 'undefined') { settings.shouldUseCustomCaptionsFormatting = false; }

    if (PlatformUtils.isTizen && tizenCaptionSupport) {
      settings.captions = loadTizenCaptionsConfig();
    } else if (!settings.captions) {
      settings.captions = makeDefaultCaptions();
    }

    return settings;
  },

  tizenUpdatedCaptions: function () {
    console.log('tizen updated captions.');
    this.state.settings.captions = loadTizenCaptionsConfig();
    console.log('triggering state');
    this.trigger(this.state);
  },

  tizenSetCaptionsEnabled: function (key, value) {
    const enabled = value === 'CAPTION_ON';
    console.log('tizen set captions enabled. value = ' + value);
    this.setClosedCaptions(enabled);
  },

  getTTSUrl: function (pageTitle) {
    return informationTTSUrls[pageTitle];
  },

  saveSettingsToLocalStorage: function () {
    localStorage.setItem('rbtv:settings', JSON.stringify(this.state.settings));
  },

  toggleHints: function () {
    this.state.settings.shouldShowHints = !this.state.settings.shouldShowHints;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
  },

  setClosedCaptions: function (bool) {
    if (this.state.settings.shouldShowCC === bool) return;
    this.state.settings.shouldShowCC = bool;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
  },

  setUseCustomCaptionsFormatting: function (bool) {
    if (this.state.settings.shouldUseCustomCaptionsFormatting === bool) return;

    this.state.settings.shouldUseCustomCaptionsFormatting = bool;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
  },

  setCaptionOption (key, value) {
    let {captions} = this.state.settings;
    if (captions[key] === value) return;
    captions[key] = value;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
  },

  defaultCaptionOption (key) {
    return defaultCaptionOptions[key];
  },

  getCaptionValues () {
    if (this.state.settings.shouldUseCustomCaptionsFormatting) { return this.state.settings.captions; } else { return makeDefaultCaptions(); }
  },

  toggleClosedCaptions: function () {
    this.setClosedCaptions(!this.state.settings.shouldShowCC);
  },

  togglePlaybackInterruption: function () {
    this.state.settings.shouldInterruptPlayback = !this.state.settings.shouldInterruptPlayback;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
  },
  toggleVideoPreview: function () {
    this.state.settings.shouldShowVideoPreview = !this.state.settings.shouldShowVideoPreview;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
  },
  loadSettingsStatusInfo: function () {
    this.state.pageData = {title: 'System Info',
      html: 'Version: ' + conf.version + '<br/>------<br/>Platform: ' + PlatformUtils.getPlatformID() +
    '<br/>------<br/>User Agent: ' + navigator.userAgent + '<br/>------<br/>navigator.language: ' + navigator.language +
    '<br/>------<br/>App getDeviceLanguage(): ' + PlatformUtils.getDeviceLanguage() +
    '<br/>------<br/>/session Country Code: ' + appStore.getCountryCode() + '<br/>------<br/>/session Locale: ' + appStore.getSessionLocale() +
    '<br/>------<br/>/device ID: ' + PlatformUtils.sharedPlatform.deviceId() + '<br/>------<br/>/uid: ' + PlatformUtils.sharedPlatform.uid()
    };
    this.state.loading = false;
    this.trigger(this.state);
    this.state.pageData = null;
  },
  toggleTizenEnableContinueWatching: function () {
    this.state.settings.enableTizenContinueWatching = !this.state.settings.enableTizenContinueWatching;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
  },

  toggleAlwaysShowAds: function () {
    this.state.settings.enableAlwaysShowAds = !this.state.settings.enableAlwaysShowAds;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
  },

  toggleSpoofCountry: function (spoofCountry, spoof) {
    this.state.settings.spoofCountry = spoofCountry;
    this.state.settings.spoofNameCountry = spoof;
    this.saveSettingsToLocalStorage(this.state.settings);
    this.trigger(this.state);
    reload();
  },

  loadInformationDataCompleted: function (data) {
    const links = configDataStore.getConstant('links');
    const useWebLinks = links.use_web_links;

    this.state.pageData = useWebLinks ? {text: data} : data;
    this.state.pageData.useWebLinks = useWebLinks;
    this.state.loading = false;
    this.trigger(this.state);
    this.state.pageData = null;
  },

  loadInformationDataFailed: function (data) {
    ErrorReporter.captureException(data, 'loadInformationDataFailed');
    this.state.loadError = true;
    this.state.loadData = data;
    this.state.loading = false;
    this.trigger(this.state);
  },

  setUserPreferredCaptionLanguage: function (languageCode) {
    console.info('setUserPreferredCaptionLanguage', languageCode);
    if (!languageCode) {
      return;
    }
    if (languageCode && languageCode.includes('-')) {
      languageCode = languageCode.split('-')[0];
    }
    this.state.settings.userPreferredCaptionLanguage = languageCode;
    this.saveSettingsToLocalStorage(this.state.settings);
  },

  setUserPreferredAudioLanguage: function (languageCode) {
    console.info('setUserPreferredAudioLanguage', languageCode);
    if (!languageCode) {
      return;
    }

    if (languageCode && languageCode.includes('-')) {
      languageCode = languageCode.split('-')[0];
    }
    this.state.settings.userPreferredAudioLanguage = languageCode;
    this.saveSettingsToLocalStorage(this.state.settings);
  },

  setSessionCaptionLanguage: function (languageCode) {
    if (languageCode && languageCode.includes('-')) {
      languageCode = languageCode.split('-')[0];
    }
    this.state.sessionCaptionLanguage = languageCode;
  },

  setSessionAudioLanguage: function (languageCode) {
    if (languageCode && languageCode.includes('-')) {
      languageCode = languageCode.split('-')[0];
    }
    this.state.sessionAudioLanguage = languageCode;
  }
});
