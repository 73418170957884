/* globals webapis */
import Reflux from 'reflux';
import {playlistStore} from './playlistStore';
import {videoPlayerStore} from './videoPlayerStore';
import {settingsActions} from './settingsStore';
import PlatformUtils from '../utils/platform';
import ErrorReporter from '../utils/error-reporter';
import { historyStore } from './historyStore';
import { searchActions } from './searchStore';
import { homeActions } from './homeStore';
import _ from 'lodash';

export const voiceCommandActions = Reflux.createActions({
  'doVoiceCmd': {},
  'skipForward': {},
  'skipBackward': {},
  'setPlayPosition': {},
  'setSubtitle': {},
  'trackPrevious': {},
  'trackNext': {},
  'restart': {}
});

export const voiceCommandStore = Reflux.createStore({
  listenables: voiceCommandActions,

  init: function () {
    if (PlatformUtils.isTizen && webapis.bixby) {
      console.log('VOICE COMMAND STORE INIT');
      try {
        // register action execution callback for a deeplink type of control action
        // we can use on handle with a conditional or switch or just create unique handlers for each action type
        webapis.bixby.setActionExecutionListener('tvMediaControl.SkipBackward', this.onVoiceActionReceive);
        webapis.bixby.setActionExecutionListener('tvMediaControl.SkipForward', this.onVoiceActionReceive);
        webapis.bixby.setActionExecutionListener('tvMediaControl.SetPlayPosition', this.onVoiceActionReceive);
        webapis.bixby.setActionExecutionListener('tvMediaControl.SetSubtitle', this.onVoiceActionReceive);
        webapis.bixby.setActionExecutionListener('tvMediaControl.TrackPrevious', this.onVoiceActionReceive);
        webapis.bixby.setActionExecutionListener('tvMediaControl.TrackNext', this.onVoiceActionReceive);
        webapis.bixby.setActionExecutionListener('tvMediaControl.Restart', this.onVoiceActionReceive);
        webapis.bixby.setActionExecutionListener('TVSearchAll.CPSearchDeeplink', this.onVoiceActionReceive);
      } catch (e) {
        ErrorReporter.captureException(e, 'Bixby present but failed to set action listeners');
      }
    }
  },

  onVoiceActionReceive: function (actionNumber, bundleMessage) {
    console.info('Got voice action', actionNumber, bundleMessage);
    if (!bundleMessage) {
      this.completeActionExecution(false);
    }
    let actionResult, exceptionId;
    if (bundleMessage.actionId === 'tvMediaControl.TrackNext') {
      actionResult = playlistStore.voiceCommand_trackNext();
    } else if (bundleMessage.actionId === 'tvMediaControl.TrackPrevious') {
      actionResult = playlistStore.voiceCommand_trackPrevious();
    } else if (bundleMessage.actionId === 'tvMediaControl.Restart') {
      actionResult = videoPlayerStore.voiceCommand_jumpToTime(0);
    } else if (bundleMessage.actionId === 'tvMediaControl.SetSubtitle') {
      const captionToggle = bundleMessage['toggle'] === 'on';
      settingsActions.setClosedCaptions(captionToggle);
      actionResult = true;
    } else if (bundleMessage.actionId === 'tvMediaControl.SetPlayPosition') {
      const time = bundleMessage.position;
      actionResult = videoPlayerStore.voiceCommand_jumpToTime(parseInt(time));
    } else if (bundleMessage.actionId === 'tvMediaControl.SkipForward') {
      const time = parseInt(bundleMessage.offset);
      actionResult = videoPlayerStore.voiceCommand_skipForward(time);
      if (actionResult === false) {
        exceptionId = 'Exception_SkipForward_offset_Invalid';
      }
    } else if (bundleMessage.actionId === 'tvMediaControl.SkipBackward') {
      const time = bundleMessage.offset;
      actionResult = videoPlayerStore.voiceCommand_skipBackward(time);
      if (actionResult === false) {
        exceptionId = 'Exception_SkipBackward_offset_Invalid';
      }
    } else if (bundleMessage.actionId === 'TVSearchAll.CPSearchDeeplink') {
      try {
        const InAppSearchParam = JSON.parse(decodeURIComponent(bundleMessage.param));

        if (Array.isArray(InAppSearchParam.search_terms)) {
          const Terms = InAppSearchParam.search_terms;
          const titleField = _.find(Terms, (term) => (term.field === 'title'));

          if (titleField && titleField.keyword && titleField.keyword.length > 0) {
            searchActions.updateCurrentValue(titleField.keyword);
            searchActions.search(titleField.keyword);
            historyStore.pushHistory('/Home/search', {name: 'search'});
            homeActions.forceHomeFocusOn('contentSection');
            actionResult = true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    this.completeActionExecution(actionNumber, actionResult, exceptionId);
  },

  completeActionExecution: function (actionNumber, wasSuccess, exceptionId) {
    console.info('completeActionExecution', actionNumber, wasSuccess, exceptionId);
    const resultArray = [];
    if (PlatformUtils.isTizen && webapis.bixby) {
      if (!exceptionId) {
        resultArray.push({
          result_code: wasSuccess ? 'SUCCESS' : 'FAILURE'
        });
      } else {
        resultArray.push(this.createExceptionWithID(exceptionId));
      }
      try {
        webapis.bixby.completeActionExecution(actionNumber, JSON.stringify(resultArray));
      } catch (e) {
        ErrorReporter.captureException(e, 'Bixby: Failed to complete action execution');
      }
    }
  },

  createExceptionWithID: function (exceptionId) {
    return {
      result_code: 'EXCEPTION',
      description: 'Exception',
      user_response: {
        responseSID: exceptionId
      }
    };
  }

});
