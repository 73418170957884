import React from 'react';
import { Switch } from 'react-router-dom';

const SwitchLow = (props) => (
  <Switch location={props.location}>
    {props.children}
  </Switch>
);

export default SwitchLow;
