import API from '../services/api.js';
import {paginationStore} from '../reflux/paginationStore';
import {searchStore} from '../reflux/searchStore';
import _ from 'lodash';

export default class SearchServiceDelegate {
  static search (term) {
    return API.search(term)
      .then(SearchServiceDelegate.onSearchSuccess)
      .catch(SearchServiceDelegate.onError);
  }

  static onSearchSuccess (result) {
    return SearchServiceDelegate.mapSearchResults(result);
  }

  static onSuccess (result) {
    return result;
  }

  static onError (err) {
    return new Promise(
      function (resolve, reject) {
        let error = {errorCode: err};
        reject(error);
      });
  }

  static searchAutosuggest (term) {
    return API.searchAutosuggest(term)
      .then(SearchServiceDelegate.onSuccess)
      .catch(SearchServiceDelegate.onError);
  }

  static mapSearchResults (searchResultsObject) {
    let items = [], total = 0;
    searchResultsObject.collections.forEach(searchCollection => {
      // These come with no collection IDs
      let {label} = searchCollection;
      const id = `search:${searchStore.state.currentValue}:${searchCollection.collection_type}`;
      searchCollection.id = id;
      let isTopResults = searchCollection.collection_type === 'top_results';

      total += searchCollection.meta.total;

      paginationStore.removeAllPagesByCollectionId(id);

      let pageNumber = Math.ceil((searchCollection.meta.offset + 1) / searchCollection.meta.limit);
      paginationStore.storePageByCollectionId(id, _.cloneDeep(searchCollection), pageNumber);
      items.push({label, id, isTopResults, ...searchCollection});
    });
    items.total = total;
    return items;
  }
}
