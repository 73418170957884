import Reflux from 'reflux';
import join from '../utils/join.js';

export const historyActions = Reflux.createActions({
  'getInitialState': {},
  'clearHistory': {},
  'setLastComponent': {},
  'getLastComponent': {},
  'popHistory': {}
});

export const historyStore = Reflux.createStore({
  state: {
    lastPlayed: [],
    homeBackBinding: null,
    currentView: 'video-player',
    forceNextGoBack: false
  },

  init: function () {
    this.listenToMany(historyActions);
  },

  getInitialState: function () {
    return this.state;
  },

  setHistory: function (history) {
    this.state.history = history;
  },

  clearHistory: function () {
    this.state.lastPlayed = [];
  },

  setLastComponent: function (backState, backParams) {
    let secondFromEndItem = this.state.lastPlayed[this.state.lastPlayed.length - 2];
    if (secondFromEndItem && secondFromEndItem.backState.id === backState.id && backState.name !== 'viewMore') {
      return;
    }
    this.state.lastPlayed.push({backState: backState, backParams: backParams});
  },

  serialize: function (obj) {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  },

  getLastComponent: function () {
    let lastComponent = this.state.lastPlayed.pop();
    if (!lastComponent || !lastComponent.backState || (!lastComponent.backState.id && !lastComponent.backState.name)) {
      return {backState: null};
    }

    if (lastComponent.backState.name === '/Consumption') {
      // do nothing, just pass it through (for when we linked in from contextual)
    } else if (lastComponent.backState.name === 'home') {
      lastComponent.backPathname = '/Home';
    } else if (lastComponent.backState.name === 'page') {
      lastComponent.backPathname = join('Home', 'page', lastComponent.backState.id);
    } else if (lastComponent.backState.name === 'viewMore') {
      lastComponent.backPathname = join('Home', 'viewMore', lastComponent.backState.collectionId);
    } else if (lastComponent.backState.name === 'consumption') {
      lastComponent.backPathname = '/Consumption';
    } else {
      lastComponent.backPathname = join('Home', lastComponent.backState.name);
    }
    return lastComponent;
  },

  popHistory: function () {
    this.state.lastPlayed.pop();
    this.trigger(this.state);
  },

  pushHistory: function (location, state, params) {
    if (!location) {
      console.warn('No location', location, state, params);
      return;
    }
    location = location.charAt(0) === '/' ? location : '/' + location;
    historyStore.state.history.push({pathname: location, state, search: '?' + this.serialize(params)});
  },

  replaceHistory: function (location, state, params) {
    historyStore.state.history.replace(location, state);
  }

});
