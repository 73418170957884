import _ from 'lodash';

var hasCreatedFonts = false;

var Fonts = {
  setFonts: function () {
    if (hasCreatedFonts) return;
    hasCreatedFonts = true;

    var that = this;
    var newStyle = document.createElement('style');
    var fontStyles = [];

    _.forEach(storedFonts,
      function (value, key) {
        fontStyles.push(that.createFontNameDefinition(key, value));
      }
    );

    var joinedStyle = fontStyles.join('\n');

    newStyle.appendChild(document.createTextNode(joinedStyle));
    document.head.appendChild(newStyle);
  },
  createFontNameDefinition: function (fontName, fontObject) {
    // TODO: Remove conditional after font scrub Andre 02-16-2015

    let weight;

    if (fontName === 'bold') {
      weight = 600;
    }

    if (fontName === 'heavy1') {
      weight = 700;
    }

    if (fontName === 'heavy2') {
      weight = 900;
    }

    if (fontName === 'medium') {
      weight = 100;
    }

    if (weight) {
      return '\
        @font-face {\
            font-family: RedBull;\
            font-weight:' + weight + ';\
            font-style: normal;\
            src: url("' + fontObject.eot + '");\
            src: url("' + fontObject.eot + '") format("embedded-opentype"),\
                   url("' + fontObject.ttf + '") format("truetype");\
        }\
        \
        @font-face {\
            font-family: rb-' + fontName + ';\
            font-style: normal;\
            src: url("' + fontObject.eot + '");\
            src: url("' + fontObject.eot + '") format("embedded-opentype"),\
                   url("' + fontObject.ttf + '") format("truetype");\
        }\
        ';
    } else {
      return '\
        @font-face {\
            font-family: rb-' + fontName + ';\
            font-style: normal;\
            src: url("' + fontObject.eot + '");\
            src: url("' + fontObject.eot + '") format("embedded-opentype"),\
                   url("' + fontObject.ttf + '") format("truetype");\
        }\
        ';
    }
  }
};

var storedFonts = {
  thin2: {
    otf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin2.otf',
    eot: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin2.eot',
    svg: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin2.svg',
    ttf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin2.ttf',
    woff: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin2.woff'
  },
  medium: {
    otf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Medium.otf',
    eot: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Medium.eot',
    svg: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Medium.svg',
    ttf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Medium.ttf',
    woff: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Medium.woff'
  },
  thin1: {
    otf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin1.otf',
    eot: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin1Bull-Thin1.eot',
    svg: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin1Bull-Thin1.svg',
    ttf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin1Bull-Thin1.ttf',
    woff: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Thin1Bull-Thin1.woff'
  },
  bold: {
    otf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Bold.otf',
    eot: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Bold.eot',
    svg: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Bold.svg',
    ttf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Bold.ttf',
    woff: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Bold.woff'
  },
  regular: {
    otf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Regular.otf',
    eot: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Regular.eot',
    svg: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Regular.svg',
    ttf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Regular.ttf',
    woff: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Regular.woff'
  },
  heavy2: {
    otf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy2.otf',
    eot: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy2.eot',
    svg: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy2.svg',
    ttf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy2.ttf',
    woff: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy2.woff'
  },
  heavy1: {
    otf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy1.otf',
    eot: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy1.eot',
    svg: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy1.svg',
    ttf: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy1.ttf',
    woff: 'http://cdn.redbull.tv/bundles/shared/fonts/Bull/Bull-Heavy1.woff'
  }
};

export default Fonts;
