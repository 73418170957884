import Reflux from 'reflux';
import {videoTimeProxyStore} from './videoTimeProxyStore';
import {videoPlayerStore, videoPlayerActions} from './videoPlayerStore';
import {userAccountStore, userAccountActions} from './userAccountStore';
import {dialogStore} from './dialogStore';
import {exitActions} from './exitStore';
import {playlistStore} from './playlistStore';
import API from '../services/api.js';
import {consumptionStore} from './consumptionStore';
import {configDataStore} from './configDataStore';
import {settingsStore, settingsActions} from './settingsStore';
import _ from 'lodash';
import PlatformUtils from '../utils/platform.js';
import conf from '../conf';

import apiImageUrl from '../utils/api-image-url.js';

const WEBSOCKET_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3
};

const SAMSUNG_APP_ID = '3201602007756';

const isServus = conf.appNamespace === 'servustv';

export const recentlyWatchedActions = Reflux.createActions({
  'getInitialState': {},
  'appendVideo': {},
  'playCurrentlySelectedVideo': {},
  'clearHistory': {},
  'getRecentlyWatchedVideos': {},
  'removeSamsungCWContent': {}
});

export const recentlyWatchedStore = Reflux.createStore({
  listenables: recentlyWatchedActions,
  state: {
    recentlyWatched: [],
    localBookmarkMap: {}
  },

  init: function () {
    this.listenTo(userAccountActions.login, this.getRecentUserBookmarks);
    this.listenTo(userAccountActions.logout, this.clearSamsungCWContent);
    this.listenTo(videoTimeProxyStore, this.captureVideoInfo);
    this.listenTo(videoPlayerActions.pauseVideo, this.closeWebSocket);
    this.listenTo(videoPlayerActions.videoEnd, this.closeWebSocket);
    this.listenTo(exitActions.exitApp, this.handleAppExit);
    this.listenTo(settingsActions.toggleTizenEnableContinueWatching, this.handleTizenCWSettingsToggle);
    this.state.recentlyWatched = this.loadRecentlyWatchedVideoList();
  },

  captureVideoInfo: function (state) {
    if (state.originator === 'lazyCurrentTime') {
      if (playlistStore.isLinearPlaylist() || playlistStore.isLive() || this._isInvalidVideo(consumptionStore.state.currentAssetObject)) {
        // RW not involved for linear/live
        return;
      }
      let currentVideo = consumptionStore.state.currentAssetObject;
      let watch_min_pct = configDataStore.getConstant('watched_minimum_percent');
      let watch_min_time_left = configDataStore.getConstant('watched_minimum_time_left');
      if (state.data.isPlaying && state.data.currentTime) {
        // There are 3 types of video bookmarking:
        if (PlatformUtils.isTizen && conf.enableTizenCW && (!this.state.lastSamsungTimeStamp || ((state.data.currentTime - this.state.lastSamsungTimeStamp) > 100) || (state.data.currentTime < this.state.lastSamsungTimeStamp))) {
          // 1) Tizen CW API - Starts instantly, every 100 seconds
          this.state.lastSamsungTimeStamp = state.data.currentTime;
          this.addSamsungCWContent(currentVideo, state);
        }

        // 2) Red Bull CW API - After 5 minutes, every 15 seconds, API handles deletion
        if ((state.data.currentTime > 300) && !this.state.lastRBTimeStamp || ((state.data.currentTime - this.state.lastRBTimeStamp) > 15) || (state.data.currentTime < this.state.lastRBTimeStamp)) {
          this.state.lastRBTimeStamp = state.data.currentTime;
          if (userAccountStore.state.isUserLoggedIn) {
            this.sendBookmarkInfo(state);
            this.addSamsungCWContent(currentVideo, state);
          } else {
          // 3) Logged out user localStorage - After 5 minutes, every 15 seconds - delete if past max percentage
            let percent = ((state.data.currentTime * 1000) / state.data.duration) * 100;
            this.appendVideo(currentVideo, state.data.currentTime, state.data.duration);
            if ((percent > watch_min_pct || (((state.data.duration - state.data.currentTime) * 1000) < watch_min_time_left)) && !_.isUndefined(this.hasUserWatched_noAPICall(currentVideo.id))) {
              this.removeResumePointFromVideo(currentVideo);
            }
          }
        }
      }
    }
  },

  sendBookmarkInfo: function (state) {
    const currentVideo = consumptionStore.state.currentAssetObject;
    const currentPlayhead = parseInt(state.data.currentTime * 1000);
    const bookmarkData = {
      'productId': currentVideo.id,
      'playhead': currentPlayhead,
      'duration': state.data.duration
    };

    if (PlatformUtils.supportsWebSockets() && (!this.state.webSocket || (currentVideo.id !== this.state.currentVideoId) || (this.state.webSocket.readyState >= WEBSOCKET_STATES.CLOSING))) {
      this.closeWebSocket();
      userAccountStore.checkAccessTokenExp().then(() => {
        this.state.webSocket = API.openBookmarkWebSocket();
        this.state.currentVideoId = currentVideo.id;
        // Socket won't open immediately, so add callback
        this.state.webSocket.onopen = () => {
          console.info('onopen, sending 1st bookmark');
          API.sendBookmark(this.state.webSocket, bookmarkData);
        };
      }).catch(error => {
        console.log('Failed to open socket', error);
      });
    } else {
      if (!PlatformUtils.supportsWebSockets()) {
        // Only send bookmarks via POST once every 60 seconds
        let currentTime = new Date().getTime();
        let diff = currentTime - (this.state.lastPOSTTime || 0);
        if (diff < 60000) {
          return;
        } else {
          this.state.lastPOSTTime = currentTime;
        }
        userAccountStore.checkAccessTokenExp().then(() => {
          API.sendBookmark(this.state.webSocket, bookmarkData);
        });
      } else if (this.state.webSocket.readyState === WEBSOCKET_STATES.OPEN) {
        API.sendBookmark(this.state.webSocket, bookmarkData);
      }
    }
    if (!this.state.localBookmarkMap[currentVideo.id]) {
      this.state.localBookmarkMap[currentVideo.id] = {
        productId: bookmarkData.productId,
        playhead: bookmarkData.playhead,
        duration: bookmarkData.duration
      };
    }
    this.state.localBookmarkMap[currentVideo.id].resumePoint = currentPlayhead / 1000;
  },

  _isInvalidVideo: function (videoObject) {
    if (!videoObject || !videoObject.type || !videoObject.title || !videoObject.playable) {
      return true;
    }
    return false;
  },

  getRecentUserBookmarks: function (isLoggedIn) {
    if (isLoggedIn && !this.state.retrievedRecentBookmarks) {
      API.getUserBookmarks().then(bookmarkData => {
        _.forEach(bookmarkData.bookmarks, (bookmark) => {
          this.state.localBookmarkMap[bookmark.id] = bookmark;
          const playhead = this.state.localBookmarkMap[bookmark.id].playhead;
          this.state.localBookmarkMap[bookmark.id].resumePoint = playhead && playhead / 1000;
        });
        this.state.retrievedRecentBookmarks = true;
      });
    } else {
      this.state.retrievedRecentBookmarks = false;
    }
  },

  appendVideo: function (videoToSave, resumePoint, videoDuration) {
    let video = _.find(this.state.recentlyWatched || [], {id: videoToSave.id}) || _.cloneDeep(videoToSave);
    if (video && !_.isEmpty(video)) {
      video.duration = videoDuration;
      video.resumePoint = resumePoint;
      let filtered = _.reject(this.state.recentlyWatched, (vid) => { return vid.id === video.id; }); // remove if it exists
      filtered.unshift(video);
      filtered = _.uniq(filtered);
      if (filtered.length > 20) {
        filtered = filtered.slice(0, 20);
      }
      this.saveRecentlyWatchedVideoList(filtered);
    } else {
      console.log('\n\n no video to append... \n\n', video);
    }
  },

  removeResumePointFromVideo: function (video) {
    let videoToReset = _.find(this.state.recentlyWatched, (vid) => { return vid.id === video.id; });
    let originalResumePoint = videoToReset.resumePoint;
    videoToReset.resumePoint = 0;
    if (originalResumePoint !== 0) {
      this.saveRecentlyWatchedVideoList(this.state.recentlyWatched);
    }
  },

  hasUserWatched_noAPICall: function (id) {
    if (userAccountStore.state.isUserLoggedIn) {
      if (this.state.localBookmarkMap[id]) {
        return this.state.localBookmarkMap[id];
      }
    } else {
      return _.find(this.state.recentlyWatched, {id: id});
    }
  },

  hasUserWatched: function (id) {
    if (userAccountStore.state.isUserLoggedIn) {
      if (this.state.localBookmarkMap[id] && !this.state.localBookmarkMap[id].isComplete) {
        return this.state.localBookmarkMap[id];
      } else {
        return API.getUserBookmarks(id).then((bookmarkData) => {
          if (bookmarkData && !_.isEmpty(bookmarkData)) {
            this.state.localBookmarkMap[id] = bookmarkData;
            bookmarkData.resumePoint = bookmarkData.playhead && bookmarkData.playhead / 1000;
            return bookmarkData;
          } else {
            return false;
          }
        })
          .catch(e => {
            console.error('Bookmark API call failed', e);
            return false;
          });
      }
    } else {
      return _.find(this.state.recentlyWatched, {id: id});
    }
  },

  getPercentWatched: function (id) {
    let video = this.hasUserWatched_noAPICall(id);
    if (!video) { return 0; }
    if (video.isComplete) { return !!undefined; }
    return ((video.resumePoint * 1000) / video.duration) * 100;
  },

  loadRecentlyWatchedVideoList: function () {
    let history = PlatformUtils.sharedPlatform.localStorage().getItem('rbtv:recentlyWatchedHistory') || '[]';
    return JSON.parse(history);
  },

  saveRecentlyWatchedVideoList: function (recentlyWatched) {
    const filteredRW = recentlyWatched.map((item) => {
      return _.pickBy(item, (key, val) => {
        return ['id', 'title', 'type', 'subheading', 'resumePoint', 'deeplink_playlist', 'duration'].includes(val);
      }
      );
    });
    this.state.recentlyWatched = filteredRW;
    PlatformUtils.sharedPlatform.localStorage().setItem('rbtv:recentlyWatchedHistory', JSON.stringify(filteredRW));
  },

  clearHistory: function () {
    this.saveRecentlyWatchedVideoList([]);
    playlistStore.deleteCachedPlaylist();
  },

  checkShowResumeDialog: async function (card, callback, callbackArg) {
    const hasWatched = await this.hasUserWatched(card.id);
    if (hasWatched && (!hasWatched.isComplete) && (hasWatched.resumePoint > 0) && !videoPlayerStore.isWatching(card)) {
      dialogStore.showResumeDialog(card, hasWatched.resumePoint, callback, callbackArg);
    } else {
      callback(callbackArg || card, 0);
    }
  },

  closeWebSocket: function () {
    this.state.webSocket && (this.state.webSocket.readyState < WEBSOCKET_STATES.CLOSING) && this.state.webSocket.close();
  },

  addSamsungCWContent: function (contentObj, playerState) {
    if (!settingsStore.state.settings.enableTizenContinueWatching || !PlatformUtils.isTizen || isServus || !conf.enableTizenCW) return;
    const nowEpochTime = Math.floor(Date.now() / 1000);
    const currentPlaybackTime = Math.floor(playerState.data.currentTime);
    const params = {
      field: '0',
      app_id: SAMSUNG_APP_ID,
      app_name: 'Red Bull TV',
      app_icon: isServus ? `${window.resourcePath}/icon/icon_tizen_stv_512x423.png` : `${window.resourcePath}/icon/icon_tizen_512x423.png`,
      payload: 'deeplink=media-playback&contentId=' + contentObj.id + '&resumePoint=' + currentPlaybackTime,
      image_url: apiImageUrl.getImageByType('video', contentObj.resources, contentObj.id),
      content_id: contentObj.id,
      content_title: encodeURIComponent(contentObj.title),
      sub_title: encodeURIComponent(contentObj.subheading),
      description: encodeURIComponent(contentObj.short_description),
      rate: contentObj.maturity,
      // genre: 'N/A', // Multiple genre should be sliced by comma (,)
      // release: 'N/A', // Release date (DD-MM-YY)
      release: '11/11/2022',
      duration: Math.floor(playerState.data.duration / 1000), // Total play time (in seconds)
      playback: currentPlaybackTime, // Played time (in seconds)
      // expiry: nowEpochTime + (24 * 60 * 60 * 30), // Expiry time (epoch time, DB Manager removes data after the time)
      timestamp: nowEpochTime // Last watched time (in seconds), required for Continue Watching content ordering
    };
    console.log('addSamsungCWContent', params);
    API.addSamsungCWContent(params);
    API.getSamsungCWContent().then((cwItems) => {
      console.log('got cwItems', cwItems);
      if (cwItems && cwItems.length > 6) {
        console.log('removing item because we have more than 6');
        this.removeOldestSamsungCWItem(cwItems);
      }
    });
  },

  removeSamsungCWContent: function (contentId) {
    if (!settingsStore.state.settings.enableTizenContinueWatching || !PlatformUtils.isTizen || isServus || !conf.enableTizenCW) return;
    const params = {
      field: '0',
      app_id: SAMSUNG_APP_ID,
      content_id: contentId // If set to “ALL”, all Continue Watching content will be deleted.
    };
    API.removeSamsungCWContent(params);
  },

  clearSamsungCWContent: function () {
    // console.warn('Clearing all Samsung CW content');
    this.removeSamsungCWContent('ALL');
  },

  getCurrentSamsungCWItems: function () {
    API.getSamsungCWContent();
  },

  removeOldestSamsungCWItem: function (cwItems) {
    const oldestItem = cwItems[6];
    console.log('Removing', oldestItem);
    this.removeSamsungCWContent(oldestItem.content_id);
  },

  handleAppExit: function () {
    this.closeWebSocket();
    if (PlatformUtils.isTizen) {
      // TODO: Test if we need to do any final CW stuff here
    }
  },

  handleTizenCWSettingsToggle: function () {
    if (!settingsStore.state.settings.enableTizenContinueWatching) {
      console.log('Samsung CW Disabled, deleting data');
      this.clearSamsungCWContent();
    }
  }

});
