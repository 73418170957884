import fetchVideoBlob from '../utils/fetch-video-blob';

window.rbtv_preloadedImages = {};
window.rbtv_preloadedVideos = {};

function imagePreload () {
  var that = this;
  that.imageQueue = [];
  that.completed = [];

  that.options = {
    pipeline: true,
    auto: true,
    onComplete: null
  };

  that.preloadUsingQueue = function (images, onCompleteCallback) {
    var item;
    if (that.preloadTimeout) {
      clearTimeout(that.preloadTimeout);
    }
    that.preloadTimeout = window.setTimeout(() => {
      console.info('Image preloading took too long, calling onComplete');
      onCompleteCallback();
    }, 5000);

    for (item in images) {
      that.addToQueue(images[item]);
    }
    that.options.onComplete = () => {
      clearTimeout(that.preloadTimeout);
      onCompleteCallback();
    };

    that.processQueue();
  };

  that.addToQueue = function (item) {
    that.imageQueue.push(item);
  };

  that.processQueue = function () {
    that.processNextQueueItem(that.options.pipeline);
  };

  that.processNextQueueItem = function () {
    let imageSource = that.imageQueue.shift();
    if (!imageSource) {
      if (that.options.onComplete) {
        that.options.onComplete();
      }
    } else {
      that.preloadSource(imageSource, that.options.pipeline);
    }
  };

  that.preloadVideo = async function (url, id) {
    if (!window?.rbtv_preloadedVideos[id]) {
      try {
        const blob = await fetchVideoBlob(url);
        window.rbtv_preloadedVideos[id] = URL.createObjectURL(blob);
      } catch (e) {
        console.log('Cannot preload video', e, url);
      }
    }
  };

  that.preloadSource = function (url, pipeline) {
    // the urls come from multiple sources so
    // check if falsy here and return if it is.
    if (!url) return;

    if (window.rbtv_preloadedImages[url]) {
      that.completed.push(url);
      that.processNextQueueItem();
      return;
    }
    window.rbtv_preloadedImages[url] = true;

    let load = () => {
      return new Promise((resolve, reject) => {
        let image = new Image();

        if (pipeline) {
          image.onload = function () {
            that.completed.push(url);
            resolve();
            that.processNextQueueItem();
          };
          image.onerror = function (err) {
            reject(err);
            that.processNextQueueItem();
          };
        } else {
          image.onload = function () {
            resolve();
          };
          image.onerror = function (err) {
            reject(err);
          };
        }

        image.src = url;
      });
    };

    load();
  };

  that.setOptions = function (obj) {
    that.options = obj;
  };

  return that;
}

if (typeof module !== 'undefined') {
  module.exports.imagePreload = imagePreload;
}
