import { toNumber } from 'lodash';

/**
 * @param {number} value
 * @return {string}
 */
function padToFive (value) {
  return toNumber(value).toFixed().toLocaleLowerCase('en-US').padStart(5, '0');
}

export { padToFive };
