import _ from 'lodash';
import {s} from './screen-size.js';
import {configDataStore} from '../reflux/configDataStore';
import {lookupCardSize} from '../config/cardSizeLookup.js';
import PlatformUtils from './platform.js';
import enqueue from '../services/queue.js';
import conf from '../conf';

const ENQUEUE_ICON_LOADING = !!conf.enqueueImagePreloads;

const apiImageUrl = {
  iconsBASE64Data: {},

  getImageByType: function (cardType, resources, id, grayedOut) {
    let cardSize = lookupCardSize(cardType);
    if (!cardSize) {
      return console.warn('No card type found for: ', cardType);
    }
    let scale = PlatformUtils.isPS3 ? 1 : 1.0;
    let params = cardSize.additionalParams ? cardSize.additionalParams.slice() : [];
    if (grayedOut) {
      if (params.length) {
        params.push('e_grayscale');
      } else {
        params = ['e_grayscale'];
      }
    }
    return this._getImage(cardSize.orientation, cardSize.width * scale, cardSize.height * scale, resources, id, params);
  },

  _getImage: function (names, width, height, resources, id, params = []) {
    resources = resources || [];
    names = _.isArray(names) ? names : [names];

    if (!resources) {
      return console.warn('No resources in props or passed in for this', this);
    }

    let name = _.find(names, (name) => { return _.includes(resources, name); });

    if (!name) {
      return console.warn('Expected resource name not listed for ' + id, resources, names);
    }

    var URLParams = params.slice();

    if (width) URLParams.push(`w_${s(width)}`);
    if (height) URLParams.push(`h_${s(height)}`);
    URLParams.push('q_70');

    let imageId = id;
    let imageUrl = configDataStore.getConstant('resources_base_url') + '/' + imageId + '/' + name + '/im:i:' + URLParams.join(',');
    imageUrl = imageUrl + '?namespace=' + configDataStore.getConstant('api_namespace');
    return imageUrl;
  },

  getVideoPreviewURL: function (id, resources, resourceType) {
    resourceType = resourceType || 'short_preview_mp4_low';
    if (!resources) {
      return console.warn('No resources in props or passed in for this', this);
    }

    if (_.includes(resources, resourceType)) {
      let videoUrl = configDataStore.getConstant('resources_base_url') + '/' + id + '/' + resourceType + '/video.mp4';
      videoUrl = videoUrl + '?namespace=' + configDataStore.getConstant('api_namespace');
      return videoUrl;
    } else {
      return null;
    }
  },

  _getV2Image: function (names, width, height, images, params = []) {
    images = images || [];
    names = _.isArray(names) ? names : [names];
    if (!images) {
      return console.warn('No images in props or passed in for this', this);
    }
    let name = _.find(names, (name) => { return !!images[name]; });
    let image = images[name];

    if (!image) {
      return null;// console.warn('No image for this - ' +  names + '. ' + width + 'x',  height);
    }

    var URLParams = params.slice();

    if (width) URLParams.push(`w_${s(width)}`);
    if (height) URLParams.push(`h_${s(height)}`);

    var imageUrl = image.template
      .replace('{transformations}', `:i:${URLParams.join(',')}`, image.template)
      .replace('{c_transform}', `${URLParams.join(',')}`, image.filename)
      .replace('{filename}', image.filename ? image.filename.trim() : null)
      .replace('{extension}', 'png');
    return imageUrl;
  },

  preloadIcons: function (icons) {
    var promiseArray = [];
    _.forEach(icons, (icon, iconName) => {
      _.forEach(icon.states, (state) => {
        let width = (icon.dims && icon.dims.width) ? icon.dims.width : 64;
        let height = (icon.dims && icon.dims.height) ? icon.dims.height : 64;
        var iconURL = this.getIconsForMapping(iconName, state.active, state.focused, width, height);
        var iconBase64promise = new Promise(function (resolve, reject) {
          apiImageUrl.imageToBASE64(iconURL, function (base64Img) {
            apiImageUrl.iconsBASE64Data[iconURL] = base64Img;
            resolve(base64Img);
          }, reject);
        });
        promiseArray.push(iconBase64promise);
      });
    });

    return this.settleImagePromises(promiseArray);
  },

  imageToBASE64: function (url, callback, onError, outputFormat) {
    let load = () => {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
          try {
            callback(url);
          } finally {
            resolve();
          }
        };

        img.onerror = function (e) {
          try {
            onError();
          } finally {
            reject(e);
          }
        };
        img.src = url;
      });
    };

    if (ENQUEUE_ICON_LOADING) {
      enqueue(load);
    } else {
      load();
    }
  },

  settleImagePromises: function (promises) {
    var allResults = [];
    var that = this;
    return new Promise(function (resolve, reject) {
      promises.forEach(function (promise) {
        promise.then(function (result) {
          allResults.push({status: 'fulfilled', result: result});
          that.preloadIcon(result);
          if (allResults.length === promises.length) {
            resolve(allResults);
          }
        }, function (reason) {
          allResults.push({status: 'rejected', reason: reason});
          if (allResults.length === promises.length) {
            resolve(allResults);
          }
        })
          .catch(function (e) {
            reject(e);
          });
      });
    });
  },

  getIcon: function (icon, active, focused, width = '64', height = '64') {
    var iconURL = this.getIconsForMapping(icon, active, focused, width, height);
    if (this.iconsBASE64Data[iconURL]) {
      return this.iconsBASE64Data[iconURL];
    } else {
      if (conf.fullPreload) {
        console.warn('MISSING PRELOADED ICON: ' + iconURL + ' - ' + active + ' ' + focused);
      }
      return iconURL;
    }
  },

  getIconsForMapping: function (iconName, active, focused, width = '64', height = '64') {
    if (!iconName) {
      return console.warn('No icon passed in for this', iconName);
    }
    var colors = configDataStore.getColors();
    var color;
    if (!colors.rb_white || !colors.rb_grey_350 || !colors.rb_red) {
      console.warn('Missing color info from API, defaulting to white.');
      color = 'br_79';
    } else {
      color = active ? colors.rb_white.transform : colors.rb_grey_350.transform;
      color = focused ? colors.rb_red.transform : color;
    }

    var type = 'f_png';
    height = 'h_' + height;
    width = 'w_' + width;

    let params = [type, height, width, color];

    if (iconName === 'rbtv_logo_white') {
      params = [type, height, width, color, 'o_68'];
    }

    let iconUrl = window.resourcePath + '/images/RBIcons/' + iconName + '_im-i-' + params.join(',') + '.png';

    // TODO: remove this once we are sure we have all the icons locally.  This is handy if we need one.
    // let iconUrl = configDataStore.getConstant('resources_base_url') + '/icon/' + iconName + '/im:i:' + params.join(',');

    return iconUrl;
  },

  preloadIcon: function (url) {
    let image = new Image();
    image.src = url;
  }
};

export const getTitleTreatment = (resources, id, titleSize = 'pageTitle', logoSize = 'sponsorBanner') => {
  if (resources && id) {
    if (_.includes(resources, 'rbtv_logo_landscape')) {
      return apiImageUrl.getImageByType(logoSize, resources, id);
    } else if (_.includes(resources, 'rbtv_title_treatment_landscape')) {
      return apiImageUrl.getImageByType(titleSize, resources, id);
    }
  }

  return null;
};

export const getThumbnail = (resources, id, type = 'page') => {
  if (resources && id) {
    return apiImageUrl.getImageByType(type, resources, id);
  }

  return null;
};

export const getPreview = (resources, id, type = 'short_preview_mp4_high') => {
  if (resources && id) {
    let url = apiImageUrl.getVideoPreviewURL(id, resources, type);
    let cached = window?.rbtv_preloadedVideos?.[id];
    return {
      url,
      cached,
      src: cached || url
    };
  }

  return {};
};

export default apiImageUrl;
