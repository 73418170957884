import Reflux from 'reflux';

export const listPageActions = Reflux.createActions({
  'reset': {},
  'updateMenuIndex': {}
});

export const listPageStore = Reflux.createStore({
  listenables: listPageActions,
  state: {
    focusPositions: {}
  },

  getInitialState: function () {
    return this.state;
  },

  reset: function (pageId) {
    this.state = {
      focusPositions: {
        ...this.state.focusPositions,
        [pageId]: undefined
      }
    };
    this.trigger(this.state);
  },

  updateMenuIndex: function (pageId, id, index) {
    this.state.focusPositions[pageId] = this.state.focusPositions[pageId] ? {...this.state.focusPositions[pageId], [id]: index} : { [id]: index };
    this.trigger(this.state);
  },

  getIndexForMenu: function (pageId, id) {
    return this.state.focusPositions[pageId]?.[id] || 0;
  }
});
