import React from 'react';
import cx from 'classnames';
import { s } from '../../utils/screen-size';

export default class FeaturedCardSmallTitle extends React.Component {
  constructor (p) {
    super(p);

    this.state = {
      marquee: false,
      scrollWidth: s(344 * 4)
    };

    this.ref = React.createRef();
    this.test = this.test.bind(this);
  }

  componentDidMount () {
    this.test();
  }

  test () {
    if (!this.ref?.current) return;

    const { offsetWidth, scrollWidth } = this.ref.current;
    if (offsetWidth < scrollWidth) {
      this.setState({ marquee: true, scrollWidth: scrollWidth * 4 });
    }
  }

  render () {
    const classNames = cx('title-marquee', {
      'is-marqueed': this.state.marquee
    });

    return (
      <div className={classNames}>
        <h3 ref={this.ref}>
          {this.state.marquee ? (
            <span className="marquee" style={{ width: this.state.scrollWidth }}>
              <span>{this.props.title}</span>
              <span>{this.props.title}</span>
              <span>{this.props.title}</span>
              <span>{this.props.title}</span>
            </span>
          ) : this.props.title}
        </h3>
      </div>
    );
  }
}
