
function tokenize (m3uString) {
  return Object.freeze(String(m3uString).split(/\n/));
}

function parseStateFunc (m3uTokenStream) {
  if (!(m3uTokenStream instanceof Array)) {
    throw new TypeError("Argument 'm3uTokenStream' must be an array.");
  }

  function newParseState (position, properties) {
    function withProperties (newProperties) {
      return newParseState(position, Object.freeze({
        extm3u: newProperties.extm3u == null ? properties.extm3u : newProperties.extm3u,
        xVersion: newProperties.xVersion == null ? properties.xVersion : newProperties.xVersion
      }));
    }

    return Object.freeze({
      read: function () {
        return m3uTokenStream[position];
      },
      next: function () {
        return newParseState(position + 1, properties);
      },
      isEnd: function () {
        return position >= m3uTokenStream.length;
      },
      isExtm3u: function () {
        return properties.extm3u;
      },
      withExtm3u: function () {
        return withProperties({extm3u: true});
      },
      getXVersion: function () {
        return properties.xVersion;
      },
      withXVersion: function (version) {
        return withProperties({xVersion: version});
      }
    });
  }

  return newParseState(0, Object.freeze({
    extm3u: false,
    xVersion: 0
  }));
}

function augmentListener (listener) {
  var augmentedListener = {};

  ['version', 'comment', 'extInf', 'xStreamInf', 'xMedia', 'url', 'playlistType', 'end']
    .forEach(function (name) {
      if (typeof listener[name] === 'function') {
        augmentedListener[name] = listener[name];
      } else {
        augmentedListener[name] = function () {
        };
      }
    });

  return Object.seal(augmentedListener);
}

function skipEmpty (parseState) {
  while (!parseState.isEnd() && /^\s*$/.test(parseState.read())) {
    parseState = parseState.next();
  }

  return parseState;
}

function parse (m3uTokenStream, listener) {
  if (!(m3uTokenStream instanceof Array)) {
    throw new TypeError("Argument 'm3uTokenStream' must be an Array.");
  }

  let parseState = parseStateFunc(m3uTokenStream);
  listener = augmentListener(listener);

  parseState = skipEmpty(parseState);

  if (/^#EXTM3U\s*$/.test(parseState.read())) {
    parseState = parseState
      .withExtm3u()
      .withXVersion(1)
      .next();
  }

  if (parseState.isExtm3u() && /^#EXT-X-VERSION:/.test(parseState.read())) {
    parseState = parseXVersion(parseState, listener);
  }

  listener.version(Object.freeze({
    extm3u: parseState.isExtm3u(),
    xVersion: parseState.getXVersion()
  }));

  while (!parseState.isEnd()) {
    if (parseState.isExtm3u() && /^#EXTINF:/.test(parseState.read())) {
      parseState = parseExtInf(parseState, listener);
    } else if (parseState.isExtm3u() && /^#EXT-X-STREAM-INF:/.test(parseState.read())) {
      parseState = parseXStreamInf(parseState, listener);
    } else if (parseState.isExtm3u() && /^#EXT-X-MEDIA:/.test(parseState.read())) {
      parseState = parseXMedia(parseState, listener);
    } else if (/^#EXT-X-PLAYLIST-TYPE:/.test(parseState.read())) {
      // This only applies to child playlists, not the master playlist
      parseState = parseXPlaylistType(parseState, listener);
    } else {
      if (/^#/.test(parseState.read())) {
        listener.comment(parseState.read().substr(1));
      } else if (!/^\s*$/.test(parseState.read())) {
        listener.url(parseState.read().replace(/^\s*|\s*$/, ''));
      }

      parseState = parseState.next();
    }
  }

  listener.end();
}

function parseExtInf (parseState, listener) {
  var matches = /^#EXTINF:(-?(?:\d*\.?\d*)),(.*)?$/.exec(parseState.read());

  if (matches) {
    var duration = parseFloat(matches[1], 10);

    // Negative duration represents indeterminate or infinite duration.
    if (duration < 0) {
      duration = null;
    }

    var title = matches[2] || '';

    listener.extInf(duration, title);
  } else {
    listener.comment(parseState.read().substr(1));
  }

  return parseState.next();
}

function parseXVersion (parseState, listener) {
  var matches = /^EXT-X-VERSION:\s*(\d+)\s*$/.exec(parseState.read());

  if (matches) {
    var version = parseInt(matches[1], 10);
    return parseState.withXVersion(version).next();
  } else {
    listener.comment(parseState.read().substr(1));
    return parseState.next();
  }
}

function parseXPlaylistType (parseState, listener) {
  var matches = /^#EXT-X-PLAYLIST-TYPE:(.*)$/.exec(parseState.read());
  if (matches) {
    const playlistType = matches[1];
    listener.playlistType(playlistType);
  } else {
    listener.comment(parseState.read().substr(1));
  }
  return parseState.next();
}

function parseXMedia (parseState, listener) {
  var matches = /^#EXT-X-MEDIA:(.*)$/.exec(parseState.read());

  if (!matches) {
    listener.comment(parseState.read().substr(1));
    return parseState.next();
  }

  var attributePairs = matches[1]
    .split(',')
    .map(function (pair) {
      var matches = /^([^=]*)(?:=(.*))?$/.exec(pair);
      return {
        name: matches[1],
        value: matches[2]
      };
    });

  let info = {};
  attributePairs.forEach(({name, value}) => {
    info[name] = value;
  });
  listener.xMedia(info);
  return parseState.next();
}

function parseXStreamInf (parseState, listener) {
  var matches = /^#EXT-X-STREAM-INF:(.*)$/.exec(parseState.read());

  if (!matches) {
    listener.comment(parseState.read().substr(1));
    return parseState.next();
  }

  var attributePairs = matches[1]
    .split(',')
    .map(function (pair) {
      var matches = /^([^=]*)(?:=(.*))?$/.exec(pair);
      return {
        name: matches[1],
        value: matches[2]
      };
    });

  var streamInf = Object.seal({
    bandwidth: null,
    videoRange: null
  });

  var attributeParsers = {
    BANDWIDTH: function (value) {
      streamInf.bandwidth = parseInt(value, 10);
    },
    'VIDEO-RANGE': function (value) {
      streamInf.videoRange = value;
    }
  };

  for (var i = 0; i < attributePairs.length; ++i) {
    var pair = attributePairs[i];
    if (attributeParsers[pair.name]) {
      attributeParsers[pair.name](pair.value);
    }
  }

  listener.xStreamInf(Object.freeze(streamInf));

  return parseState.next();
}

function findPlaylistType (playlistText) {
  const tokens = tokenize(playlistText).slice(0, 10);
  let playlistType;

  parse(tokens, {
    playlistType: function (type) {
      playlistType = type;
    }});

  return Object.freeze({playlistType});
}

function parsePlaylistText (playlistText, isMedia = null) {
  var tokens = tokenize(playlistText);

  var states = Object.freeze({
    expectingStreamInf: 'expectingStreamInf',
    expectingUrl: 'expectingUrl',
    expectingVTTUrl: 'expectingVTTUrl'
  });

  var state = states.expectingStreamInf;

  var currentStreamInfo = null;

  var variants = [];
  var subtitles = [];
  var audioTracks = [];

  parse(tokens, {
    version: function (versionInfo) {
      if (!versionInfo.extm3u) {
        throw new Error('Playlist is not an HLS Master Playlist (missing EXTM3U header).');
      } else if (versionInfo.xVersion > 7) {
        throw new Error('Unsupported HLS Playlist version.');
      }
    },
    extInf: function () {
      if (isMedia) state = states.expectingVTTUrl;
      else throw new Error('Playlist is not an HLS Master Playlist.');
    },
    xStreamInf: function (nextStreamInfo) {
      if (state === states.expectingStreamInf) {
        currentStreamInfo = nextStreamInfo;
        state = states.expectingUrl;
      } else {
        throw new Error('Unexpected X-STREAM-INFO tag (unsupported or invalid HLS Master Playlist?).');
      }
    },
    xMedia: function (attrs) {
      if (attrs.TYPE === 'SUBTITLES') {
        subtitles.push({
          name: JSON.parse(attrs.NAME),
          languageCode: JSON.parse(attrs.LANGUAGE),
          url: JSON.parse(attrs.URI),
          originalIndex: subtitles.length,
          forced: attrs.FORCED === 'YES'
        });
      }
      if (attrs.TYPE === 'AUDIO') {
        audioTracks.push({
          name: JSON.parse(attrs.NAME),
          languageCode: JSON.parse(attrs.LANGUAGE),
          url: attrs.URI ? JSON.parse(attrs.URI) : null,
          originalIndex: audioTracks.length
        });
      }
    },
    url: function (url) {
      if (state === states.expectingUrl) {
        variants.push(Object.freeze({
          bandwidth: currentStreamInfo.bandwidth,
          videoRange: currentStreamInfo.videoRange,
          url: url
        }));

        currentStreamInfo = null;
        state = states.expectingStreamInf;
      } else if (state === states.expectingVTTUrl) {
        variants.push({url: url});
      } else {
        throw new Error('Unexpected URL (unsupported or invalid HLS Master Playlist?).');
      }
    },
    end: function () {
      if (!isMedia && state !== states.expectingStreamInf) {
        throw new Error('Unexpected end of playlist (unsupported or invalid HLS Master Playlist?).');
      } else if (isMedia && state !== states.expectingVTTUrl) {
        throw new Error('Unexpected end of playlist (unsupported or invalid  Playlist?).');
      }
    }
  });

  Object.freeze(variants);
  Object.freeze(subtitles);
  Object.freeze(audioTracks);
  return Object.freeze({variants, subtitles, audioTracks});
}

function findVariants (text, isMedia) { return parsePlaylistText(text, isMedia).variants; }
function findSubtitlesAndAudioTracks (text) { return parsePlaylistText(text); }

// once you have fetched a subtitles m3u8, resolve subtitles will parse out the vtt urls
function findSubtitles (text) { return findVariants(text, true); }

function findBestVariant (playlistText, maxBandwidth) {
  return findVariants(playlistText)
    .reduce(function (bestVariant, nextVariant) {
      if (bestVariant == null) {
        return nextVariant;
      } else if (bestVariant.bandwidth > nextVariant.bandwidth) {
        if (bestVariant.bandwidth > maxBandwidth) {
          return nextVariant;
        } else {
          return bestVariant;
        }
      } else if (nextVariant.bandwidth < maxBandwidth) {
        return nextVariant;
      } else {
        return bestVariant;
      }
    }, null);
}

var hls = Object.freeze({
  findVariants: Object.freeze(findVariants),
  findBestVariant: Object.freeze(findBestVariant),
  findSubtitlesAndAudioTracks: Object.freeze(findSubtitlesAndAudioTracks),
  findSubtitles: Object.freeze(findSubtitles),
  findPlaylistType: Object.freeze(findPlaylistType)
});

var exportsTarget;

if (window.hls == null) {
  window.hls = {};
}

exportsTarget = window.hls;

Object.keys(hls)
  .forEach(function (key) {
    exportsTarget[key] = hls[key];
  });

export default exportsTarget;
