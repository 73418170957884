import PlatformUtils from './platform.js';

let initialLocation = document.location.href;

export default function reload () {
  console.log('reloading app.');

  if (PlatformUtils.isTizen) {
    initialLocation = location.origin + '/index.html';
    /* eslint-disable */
    webapis.avplay.stop();
    webapis.avplay.close();
  }

  document.location.href = initialLocation;
}
