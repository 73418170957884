import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import isEqual from 'lodash/isEqual';
import apiImageUrl from '../../utils/api-image-url';
import BaseCard from '../card/base-card';
import CardThumbnail from '../card-thumbnail/card-thumbnail';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';

export default class ChannelCard extends BaseCard {
  constructor (p) {
    super(p);

    this.updateImage = true;
    this.ref = React.createRef();
  }

  componentDidUpdate (prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    this.updateImage = (this.props.id !== prevProps.id) || (!this.props.isPlaceholder && prevProps.isPlaceholder) || !isEqual(this.props.resources, prevProps.resources);
  }

  setCardImage () {
    if (this.updateImage) {
      if (_.includes(this.props.resources, 'rbtv_logo_landscape')) {
        this.titleTreatment = apiImageUrl.getImageByType('sponsorTitle', this.props.resources, this.props.id);
      }

      this.image = apiImageUrl.getImageByType(this.props.content_type, this.props.resources, this.props.id);
      this.updateImage = false;
    }
  }

  getThumbnail () {
    this.setCardImage();
    return <CardThumbnail src={this.image}/>;
  }

  getTitle () {
    return this.titleTreatment
      ? <img src={this.titleTreatment}/>
      : <h3>{this.props.headline || this.props.title}</h3>;
  }

  readAria () {
    ariaTTSServiceStore.readText(this.readText + ' channel');
  }

  render () {
    const classNames = cx('card card--channel', {
      'card--channel--linear': this.props.isLinearChannelCard,
      'is-placeholder': this.props.isPlaceholder,
      'is-focused': this.props.focused,
      'offleft': this.props.offleft
    });

    if (this.props.isPlaceholder) {
      return (
        <div
          ref={this.ref}
          className={classNames}
        >
          <div className="card__inner">
            <div className="card__inner__thumb">
              <div className="image-wrapper image-wrapper--loading" />
            </div>
          </div>
        </div>
      );
    }

    this.readText = this.props.title;

    return (
      <div
        ref={this.ref}
        className={classNames}
        role="button"
      >
        <div className="card__inner" >
          <div className="card__inner__thumb" >
            {this.getThumbnail()}
          </div>
          <div className="card__inner__details">
            <div className="card__inner__details__cell">
              {this.getTitle()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
