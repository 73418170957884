import React from 'react';
import Reflux from 'reflux';
import cx from 'classnames';
import apiImageUrl from '../../utils/api-image-url';
import BaseCard from '../card/base-card';
import CardLabel from '../card-label/card-label';
import CardThumbnail from '../card-thumbnail/card-thumbnail';
import CardProgressBar from '../card-progress-bar/card-progress-bar';
import { Icon } from '../page-buttons/page-buttons';

export default class UniversalCard extends Reflux.Component.extend(BaseCard) {
  constructor (p) {
    super(p);

    this.ref = React.createRef();
  }

  getCardImage () {
    return apiImageUrl.getImageByType('universal', this.props.resources, this.props.id);
  }

  getThumbnail () {
    let src = this.getCardImage();
    return <CardThumbnail src={src} />;
  }

  getDetails () {
    const heading = <h3 className="ellipsis">{this.props.title || this.props.headline}</h3>;
    const sub =
      this.props.subheading || this.props.subline ? (
        <h4 className="ellipsis">{this.props.subheading || this.props.subline}</h4>
      ) : null;

    return (
      <React.Fragment>
        {heading}
        {sub}
      </React.Fragment>
    );
  }

  getLabel () {
    return (
      <CardLabel
        {...this.props}
        readAria={`${this.props.title} : list item`}
      />
    );
  }

  getCardProgressBar () {
    if (!this.props.progress) {
      return;
    }

    return <CardProgressBar {...this.props}/>;
  }

  canShowTitles () {
    return this.props.content_type !== 'year';
  }

  canShowLabels () {
    return ['page', 'video'].includes(this.props.type);
  }

  render () {
    const hasTitles = this.canShowTitles();
    const hasLabels = this.canShowLabels();
    const classNames = cx(
      `card card--universal content-type-${this.props.type}`,
      {
        'is-placeholder': this.props.isPlaceholder,
        'is-focused': this.props.focused,
        'has-title': hasTitles,
        'has-no-title': !hasTitles,
        'offleft': this.props.offleft
      }
    );

    if (this.props.isPlaceholder) {
      return (
        <div
          ref={this.ref}
          className={classNames}
        >
          <div className="card__inner">
            <div className="card__inner__thumb">
              <div className="image-wrapper image-wrapper--loading" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        ref={this.ref}
        className={classNames}
        role="button"
      >
        <div className="card__inner">
          <div className="card__inner__thumb">
            {this.getThumbnail()}
          </div>
          <div className="card__inner__details">
            {hasTitles && this.getDetails()}
            {this.getCardProgressBar()}
          </div>
        </div>
        <div className="fav-icon">
          <Icon
            iconName={
              this.props.isFavorite ? 'star-dark' : 'star-dark-empty'
            }
            focused={this.props.focused}
            size={48}
          />
        </div>
        {hasLabels && this.getLabel()}
      </div>
    );
  }
}
