import React from 'react';
import Reflux from 'reflux';
import Ellipsis from 'react-lines-ellipsis';
import cx from 'classnames';
import getParentId from '../../../utils/get-parent-id';
import calculateAspectRatioFit from '../../../utils/calculate-aspect-ratio-fit';
import timeFormatter from '../../../utils/time-formatter';
import dateFormatter from '../../../utils/date-formatter';
import { getTitleTreatment, getThumbnail } from '../../../utils/api-image-url';
import { s } from '../../../utils/screen-size';
import { consumptionStore } from '../../../reflux/consumptionStore';
import { localizationStore } from '../../../reflux/localizationStore';
import Countdown from '../../countdown/countdown';

const IN_VIEW_DELAY_IN_MILLISECOND = 100;
const TITLE_TREATMENT_MAX_WIDTH = s(700);
const TITLE_TREATMENT_MAX_HEIGHT = s(180);
const MAX_NEGATIVE_INDENT = s(80);

export default class Featured extends Reflux.Component {
  constructor (p) {
    super(p);

    this.stores = [consumptionStore];

    this.state = {
      data: {},
      artworkLoaded: false,
      indend: 0,
      inView: false
    };

    this.getThumbnail = this.getThumbnail.bind(this);
    this.getLiveTime = this.getLiveTime.bind(this);
    this.getAiredDate = this.getAiredDate.bind(this);
    this.getDateTime = this.getDateTime.bind(this);
    this.getDateRange = this.getDateRange.bind(this);
    this.updateData = this.updateData.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount () {
    this.updateData();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.item.id !== this.props.item.id ||
      (this.props.item.isLinear && prevState?.currentAssetObject?.id !== this.state?.currentAssetObject?.id)) {
      this.updateData();
    }
  }

  componentWillUnmount () {
    this.clear();
  }

  clear () {
    if (this.artworkLoader) {
      this.artworkLoader.onload = null;
    }

    clearTimeout(this.inViewTimeout);
  }

  getThumbnail () {
    const { item } = this.props;
    const parentId = getParentId(item);

    return getThumbnail(
      parentId ? ['rbtv_cover_art_portrait', ...item.resources] : item.resources,
      parentId || item.id
    );
  }

  getLiveTime () {
    // Live at %time%
    return localizationStore._GET('live_at_time',
      dateFormatter.getTimestring24Hrs(this.props?.item?.status?.start_time)
    );
  }

  getAiredDate () {
    // Aired on %date%
    return localizationStore._GET('aired_on_date',
      dateFormatter.getDateString(this.props?.item?.status?.start_time)
    );
  }

  getDateTime () {
    // %date% at %time%
    return localizationStore._GET('date_at_time',
      dateFormatter.getDateString(this.props?.item?.status?.start_time),
      dateFormatter.getTimestring24Hrs(this.props?.item?.status?.start_time)
    );
  }

  getDateRange () {
    // %start_time% - %end_time%
    return dateFormatter.getDateRangeString(
      this.props?.item?.status?.start_time,
      this.props?.item?.status?.end_time,
      true
    );
  }

  updateData (inView = false, data = {}) {
    this.clear();

    const { item } = this.props;
    const { currentAssetObject } = this.state;

    data.title = item.title;
    data.desc = item.short_description || item.long_description;
    data.supportingLineOne = localizationStore._GET_LABEL(item.content_type);
    data.supportingLineTwo = item?.tags?.[0] || '';
    data.supportingLineThree = timeFormatter().getHumanReadableDuration(item.duration) || localizationStore._GET_LABEL(item?.status?.label);
    data.titleTreatment = getTitleTreatment(item.resources, item.id);

    let src = data.titleTreatment;
    if (item.status?.code) {
      data.supportingLineOne = localizationStore._GET('event');
      data.supportingLineTwo = this.getDateTime();
      // The subtext from event (typically the location of the event).
      data.supportingLineThree = item.subheading || item.catchphrase || item.subline || item.status.label || data.supportingLineThree;

      switch (item.status.code) {
        case 'unconfirmed':
        case 'pre':
          data.supportingLineOne = localizationStore._GET('upcoming_live_event');
          if (item.status.end_time) {
            const now = new Date();
            const endTime = new Date(this.props.item.status.end_time);
            if (endTime <= now) {
              data.supportingLineOne = localizationStore._GET('event');
            }
          }
          data.supportingLineTwo = <Countdown {...item.status} />;
          break;
        case 'live':
          data.supportingLineTwo = localizationStore._GET('live_now');
          data.supportingLineThree = item.subheading || this.getLiveTime();
          break;
        case 'event_window':
          data.supportingLineTwo = this.getDateRange();
          break;
        case 'delayed':
          data.supportingLineOne = localizationStore._GET('delayed');
          break;
        case 'trim':
          data.supportingLineOne = localizationStore._GET('replay_coming_soon');
          break;
        case 'post':
          data.supportingLineOne = localizationStore._GET('event_replay');
          data.supportingLineTwo = this.getAiredDate();
          break;
        case 'canceled':
          data.supportingLineOne = localizationStore._GET('canceled');
          if (item.status.label) {
            data.supportingLineOne = localizationStore._GET_LABEL(item.status.label);
          }
          break;
        default:
          break;
      }
    } else {
      switch (item.content_type) {
        case 'episode':
          data.titleTreatment = null;
          data.thumbnail = this.getThumbnail();
          // TODO: Localize season and episode number
          data.subheading = `S${item.season_number || 0} E${item.episode_number || 0}`;
          data.supportingLineTwo = item.show_name;
          src = data.thumbnail;
          break;
        case 'clip':
          // hide supporting lines https://jira.redbullmediahouse.com/browse/RBMN-32591
          data.supportingLineOne = data.supportingLineTwo = data.supportingLineThree = '';
          break;
        default:
          if (item.isLinear) {
            data.desc = item.long_description || item.short_description;
            if (currentAssetObject?.id) {
              data.supportingLineOne = localizationStore._GET('now_playing');
              data.supportingLineTwo = currentAssetObject?.title || localizationStore._GET('red_bull_tv');
              data.supportingLineThree = currentAssetObject?.subheading;
            } else {
              data.supportingLineOne = data.supportingLineTwo = data.supportingLineThree = '';
            }
          }
          break;
      }
    }

    // Start of debugging
    data.debugging = [];

    item?.content_type && data.debugging.push(`Content type: ${item.content_type}`);
    item.status?.code && data.debugging.push(`Status code: ${item.status.code}`);
    item?.type && data.debugging.push(`Type: ${item.type}`);
    item?.isLive && data.debugging.push('Live');
    item?.isLinear && data.debugging.push('Linear');
    item?.playable && data.debugging.push('Playable');

    data.debugging = data.debugging.join(' | ');
    // End of debugging

    data.hasSidebar = !!(
      !!data.supportingLineOne ||
      !!data.supportingLineTwo ||
      !!data.supportingLineThree
    );

    this.setState({ data, inView, artworkLoaded: false, indent: 0 });

    if (!inView) {
      this.inViewTimeout = setTimeout(() => {
        this.setState({ inView: true });
      }, IN_VIEW_DELAY_IN_MILLISECOND);
    }

    if (src) {
      const update = (indent = 0) => {
        this.setState({ artworkLoaded: true, indent });
      };
      this.artworkLoader = new Image();
      this.artworkLoader.src = src;
      this.artworkLoader.onload = function () {
        let indent = 0;
        if (!data.thumbnail) {
          const { height } = calculateAspectRatioFit(this.width, this.height, TITLE_TREATMENT_MAX_WIDTH, TITLE_TREATMENT_MAX_HEIGHT);
          indent = Math.min(Math.max(TITLE_TREATMENT_MAX_HEIGHT - height, 0), MAX_NEGATIVE_INDENT);
        }

        update(indent);
      };
    }
  }

  render () {
    const { data, artworkLoaded, indent, inView } = this.state;
    const classNames = cx(
      'home-featured-carousel-module__detail',
      {
        'has-sidebar': !!data.hasSidebar,
        'has-thumbnail': !!data.thumbnail,
        'has-title-treatment': !!data.titleTreatment,
        'has-indent': !!indent,
        'artwork-loaded': artworkLoaded,
        'in-view': inView
      }
    );

    const style = {};
    // NOTE: Comment the following lines to align the title treatment to the bottom/left of the image wrapper.
    if (indent) {
      style.marginTop = indent * -1;
    }

    return (
      <div className={classNames}>
        <div className="home-featured-carousel-module__detail__main" style={style}>
          {(data.thumbnail || data.titleTreatment) && <div className="home-featured-carousel-module__detail__main__image">
            <img src={data.thumbnail || data.titleTreatment} />
          </div>}
          <div className="home-featured-carousel-module__detail__main__title">
            <Ellipsis
              text={data.title}
              maxLine={data.titleTreatment ? 1 : 2}
              basedOn="words"
              component="h1"
            />
            {data.subheading && <h3>{data.subheading}</h3>}
            <Ellipsis
              text={data.desc}
              maxLine={data.titleTreatment ? 4 : 5}
              basedOn="words"
              component="p"
            />
          </div>
        </div>
        <div className="home-featured-carousel-module__detail__side">
          {data.supportingLineOne && <h6>{data.supportingLineOne}</h6>}
          {data.supportingLineTwo && <h2>{data.supportingLineTwo}</h2>}
          {data.supportingLineThree && (
            <Ellipsis
              text={data.supportingLineThree}
              maxLine={3}
              basedOn="words"
              component="p"
            />
          )}
          {data.debugging && <pre>{data.debugging}</pre>}
        </div>
      </div>
    );
  }
}
