import React from 'react';
import cx from 'classnames';
import BaseCard from '../card/base-card';
import Reflux from 'reflux';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';

export default class FormatCard extends Reflux.Component.extend(BaseCard) {
  constructor (props) {
    super(props);
    this.readText = '';
    this.ref = React.createRef();
  }

  readAria () {
    ariaTTSServiceStore.readText(this.readText + ': button');
  }

  render () {
    if (this.props.hidden) {
      return null;
    }

    const icon = (this.props.focused && this.props.iconFocused)
      ? this.props.iconFocused
      : this.props.icon;

    const cardClass = cx('format--card', {
      'is-focused': this.props.focused
    });

    this.readText = this.props.title;

    return (
      <div ref={this.ref} className={cardClass} role="button">
        <div className="format--card__inner">
          <div className="format--card__inner__copy">
            <span>{icon && <img src={icon}/>}{this.props.title}</span>
          </div>
        </div>
      </div>
    );
  }
}
