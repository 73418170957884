import React from 'react';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import apiImageUrl from '../../utils/api-image-url';
import BaseCard from '../card/base-card';
import ScheduleLabel from '../card-label/schedule-label';
import CardThumbnail from '../card-thumbnail/card-thumbnail';
import { WhitePlayIcon, RedPlayIcon, Icon } from '../../components/page-buttons/page-buttons';
import { ariaTTSServiceStore } from '../../reflux/ariaTTSServiceStore';
import conf from '../../conf';

const isServus = conf.appNamespace === 'servustv';

export default class ScheduleCard extends BaseCard {
  constructor (p) {
    super(p);
    this.updateImage = true;
  }

  componentDidUpdate (prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    this.updateImage = (this.props.id !== prevProps.id) || (!this.props.isPlaceholder && prevProps.isPlaceholder) || !isEqual(this.props.resources, prevProps.resources);
  }

  setCardImage () {
    if (this.updateImage && this.props.resources) {
      this.image = apiImageUrl.getImageByType('schedule-item', this.props.resources, this.props.id, !this.props.playable);
      this.updateImage = false;
    }
  }

  getThumbnail () {
    this.setCardImage();
    return <CardThumbnail src={this.image}/>;
  }

  getOverlayIcon () {
    if (isServus) {
      if (this.props.isWatching) {
        return <RedPlayIcon className="overlay-icon"/>;
      }
    } else {
      if (this.props.isWatching) {
        return <WhitePlayIcon className="overlay-icon"/>;
      } else if (this.props.recentlyWatched) {
        return <Icon iconName="check" className="overlay-icon"/>;
      }
    }
  }

  readAria () {
    const readText = `${this.props.title || this.props.headline} : schedule item`;

    ariaTTSServiceStore.readText(readText);
  }

  render () {
    const classNames = cx('playlist-card playlist-card--schedule', {
      'is-placeholder': this.props.isPlaceholder,
      'is-focused': this.props.focused,
      'offleft': this.props.offleft
    });

    if (this.props.isPlaceholder) {
      return (
        <div
          ref={this.props.propRef}
          className={classNames}
        >
          <div className="playlist-card__inner">
            <div className="playlist-card__inner__details"/>
          </div>
        </div>
      );
    }

    return (
      <div
        ref={this.props.propRef}
        className={classNames}
        role="button"
      >
        <div className="playlist-card__inner">
          <div className="playlist-card__inner__thumb">
            {this.getThumbnail()}
            {this.getOverlayIcon()}
          </div>
          <div className="playlist-card__inner__details">
            <h3 className="ellipsis">
              {this.props.isFavorite && (
                <Icon
                  className="favicon"
                  iconName="star-black"
                  focused={false}
                  size={32}
                />
              )}
              {this.props.title || this.props.headline}
            </h3>
            <ScheduleLabel {...this.props}/>
          </div>
        </div>
      </div>
    );
  }
}
