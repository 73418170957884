import Reflux from 'reflux';
import _ from 'lodash';
import {consumptionStore} from './consumptionStore';
import {homeActions} from './homeStore';
import {playlistStore} from './playlistStore';
import {configDataStore} from './configDataStore';
import {settingsStore} from './settingsStore';
import {epgStore} from './epgStore';
import apiImageUrl from '../utils/api-image-url.js';
import {videoTimeProxyStore} from './videoTimeProxyStore';
import {localizationStore} from './localizationStore';
import PlatformUtils from '../utils/platform.js';

export const hintsActions = Reflux.createActions({
  'getInitialState': {},
  'setHint': {},
  'showDownHint': {},
  'showUpHint': {},
  'showContextualHint': {},
  'clearHints': {}
});

export const hintsStore = Reflux.createStore({
  state: {
    leftIsHidden: true,
    leftLabel: '',
    rightIsHidden: true,
    rightLabel: '',
    topIsHidden: true,
    topLabel: '',
    bottomIsHidden: true,
    bottomLabel: '',
    fadeHint: true,
    duration: 1000,
    nextIsHidden: true,
    backIsHidden: true,
    nextLabel: '',
    nextImage: '',
    nextProgress: 0,
    channelIsHidden: true,
    channelLabel: ''
  },

  init: function () {
    this.listenToMany(hintsActions);
    this.listenTo(videoTimeProxyStore, this.onPlayerChange);
    this.listenTo(consumptionStore, this.onConsumptionChange);
    this.listenTo(homeActions.toggleUpHint, this.onHomeChange);
    this.listenTo(playlistStore, this.onPlaylistChange);
  },

  getInitialState: function () {
    return this.state;
  },

  _hideUpNext: function () {
    this.state.nextIsHidden = true;
    this.state.nextLabel = null;
    this.state.nextImage = '';
    consumptionStore.showWhatsNextHint(!this.state.nextIsHidden, true);
    this.trigger(this.state);
  },

  onPlaylistChange: function () {
    // hide the up next hint once we're on linear
    if (playlistStore.isLinearPlaylist() && !this.state.nextIsHidden) {
      this._hideUpNext();
    }
  },

  onConsumptionChange: function (consumptionState) {
    if (this.state.nextIsHidden === false) {
      this._hideUpNext();
    }

    if (!consumptionState.isContextualControlsVisible &&
            !consumptionState.isRecentlyWatchedPanelVisible &&
            !consumptionState.isPlayerControlsVisible &&
            consumptionState.nothingFocused) {
      if (playlistStore.isLinearPlaylist()) {
        if (!PlatformUtils.isMultiLinearDisabled()) {
          this.showLinearChannelHints(consumptionState);
        } else {
          this.showContextualHint();
        }
      } else {
        this.clearHints();
        this.showContextualHint();
        this.state.channelIsHidden = true;
      }
    } else if (consumptionState.isContextualControlsVisible) { // Used to be here to show "back hint" but now it's to be sure no other hint is visible while in contextual
      this.setHint({
        fadeHint: false,
        topIsHidden: true,
        bottomIsHidden: true,
        bottomLabel: ''
      });
    }
  },

  onHomeChange: function (shouldShow) {
    if (shouldShow) {
      this.showUpHint();
    } else {
      this.clearHints();
    }
  },

  onPlayerChange: function (playerState) {
    if (playerState.originator === 'currentTime') return;
    /* Whats next functionality - show whats next 10 seconds before end of video */
    // currentTime is in seconds, duration is in milliseconds,upNextHintTime is in milliseconds

    if (playlistStore.isLinearPlaylist() || playlistStore.isLive()) {
      if (!this.state.nextIsHidden) {
        this._hideUpNext();
      }
      return;
    }

    var timeRemaining = playerState.data.duration - (playerState.data.currentTime * 1000);
    var playNextWindow = configDataStore.getConstant('up_next_hint_time');
    if (timeRemaining <= playNextWindow &&
                timeRemaining > 0 &&
                (document.location.href.includes('Consumption'))) {
      if (this.state.nextIsHidden) {
        if (playlistStore.isOrderedPlaylist() &&
                        playlistStore.state.playlistData) {
          let items = playlistStore.state.playlistData.items;
          let curr = playlistStore.state.currentlyWatchingIndex;
          let last = items.length - 1;
          let nextVideoIndex = (curr < last) ? curr + 1 : -1;

          let nextLabel, nextVideo;

          if (nextVideoIndex < 0) {
            if (playlistStore.state.nextPlaylist && (playlistStore.state.nextPlaylist !== 'LINEAR')) {
              nextVideo = playlistStore.state.nextPlaylist.items[0];
              nextLabel = nextVideo && nextVideo.title;
            } else {
              // If there's no next_playlist, we go back to the linear stream
              nextVideo = epgStore.getCurrentVideo();
              nextLabel = localizationStore._GET('red_bull_tv');
            }
          } else {
            nextVideo = items[nextVideoIndex];
            nextLabel = items[nextVideoIndex].title;
          }

          this.state.nextLabel = nextLabel;
          if (nextVideo && nextVideo.resources) {
            this.state.nextImage = apiImageUrl.getImageByType('schedule-item', nextVideo.resources, nextVideo.id);
            this.state.nextIsHidden = false;
          }
          consumptionStore.showWhatsNextHint(!this.state.nextIsHidden);
        }
      }
      this.state.nextProgress = Math.min(100 - ((playerState.data.duration / 1000 - (playerState.data.currentTime + 1)) * 10), 100);
      this.trigger(this.state);
    } else {
      this.state.nextIsHidden = true;
      this.state.nextLabel = null;
      this.state.nextImage = '';
      consumptionStore.showWhatsNextHint(!this.state.nextIsHidden);
      this.trigger(this.state);
    }
  },

  clearHints: function () {
    var noHints = {
      leftIsHidden: true,
      rightIsHidden: true,
      topIsHidden: true,
      bottomIsHidden: true,
      backIsHidden: true,
      bottomLabel: null,
      leftLabel: null,
      rightLabel: null,
      topLabel: null,
      fadeHint: null,
      channelIsHidden: true,
      channelLabel: null
    };

    this.state = Object.assign(this.state, noHints);
    consumptionStore.showWhatsNextHint(!this.state.nextIsHidden);
    this.trigger(this.state);
  },

  setHint: function (hintState) {
    if (settingsStore.state.settings.shouldShowHints) {
      var sameAsLastHint = true;
      _.keys(hintState).forEach((key) => {
        if (hintState[key] !== this.state[key]) {
          sameAsLastHint = false;
        }
      });
      if (!sameAsLastHint) {
        this.clearHints();
        setTimeout(() => {
          this.state = Object.assign(this.state, hintState);
          consumptionStore.showWhatsNextHint(!this.state.nextIsHidden);
          this.trigger(this.state);
        }, 0);
      }
    }
  },

  showContextualHint: function () {
    setTimeout(() => {
      if (window.location.href.includes('Consumption')) {
        this.setHint({
          fadeHint: true,
          topIsHidden: false,
          topLabel: localizationStore._GET('info')});
      }
    }, 0);
  },

  showMenuAndBackHints: function () {
    this.setHint({
      fadeHint: false,
      bottomLabel: localizationStore._GET('menu'),
      bottomIsHidden: false,
      backIsHidden: false
    });
  },

  showLinearChannelHints: function () {
    if (!window.location.href.includes('Consumption')) {
      return;
    }
    let linearChannels = epgStore.state.linearChannels.items;
    let leftIdx = (epgStore.state.currentLinearChannelIndex - 1 >= 0) ? epgStore.state.currentLinearChannelIndex - 1 : linearChannels.length - 1;
    let rightIdx = (epgStore.state.currentLinearChannelIndex + 1 < linearChannels.length - 1) ? epgStore.state.currentLinearChannelIndex + 1 : 0;
    let leftChannel = linearChannels[leftIdx];
    let currentChannel = epgStore.state.currentLinearChannel;
    let rightChannel = linearChannels[rightIdx];
    if (epgStore.state.linearChannels) {
      setTimeout(
        this.setHint({
          channelIsHidden: false,
          channelLabel: currentChannel ? currentChannel.title : '',
          fadeHint: true,
          leftIsHidden: _.isUndefined(leftChannel),
          leftLabel: localizationStore._GET('previous_channel'),
          rightIsHidden: _.isUndefined(rightChannel),
          rightLabel: localizationStore._GET('next_channel'),
          bottomIsHidden: false,
          bottomLabel: localizationStore._GET('live_channels'),
          topIsHidden: false,
          topLabel: localizationStore._GET('info')
        })
        , 50);
    }
  },

  showOnlyChannelHint: function () {
    let linearChannels = epgStore.state.linearChannels.items;
    let currentChannel = linearChannels[consumptionStore.state.currentLinearChannel];
    this.setHint({
      fadeHint: false,
      channelIsHidden: false,
      channelLabel: currentChannel ? currentChannel.title : ''
    });
  },

  showDownHint: function () {
    this.setHint({
      fadeHint: false,
      bottomIsHidden: false
    });
  },

  showUpHint: function () {
    this.setHint({
      fadeHint: false,
      topIsHidden: false
    });
  }

});
